import { toast } from "react-toastify";
import * as S from "./styles";
import InputMask from "react-input-mask";
import { useEffect } from "react";

interface IProps {
  autoComplete?: "on" | "off";
  type?:
    | "string"
    | "number"
    | "date"
    | "text"
    | "email"
    | "password"
    | "checkbox";
  placeholder?: string;
  maxLength?: number;
  label?: string;
  step?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  size?: "sm" | "lg";
  as?: any;
  rows?: number;
  name: string;
  mask?: string;
  defaultValue?: any;
  errors?: any;
  register: any;
  watch?: any;
  setValue?: any;
}

export function InputHook({
  label,
  type,
  autoComplete,
  placeholder,
  maxLength,
  step,
  min,
  max,
  disabled,
  as,
  rows,
  name,
  mask,
  defaultValue,
  register,
  errors,
  watch,
  setValue,
}: IProps) {
  const errorToDisplay = errors
    ? Object?.keys(errors[0])?.find((key) => key === name)
    : "";

  return (
    <div>
      <label>
        <div className="label">{label}</div>
        <S.Input
          errorToDisplay={
            errorToDisplay && errors[0][errorToDisplay]?.message.length > 0
          }
        >
          {mask ? (
            <InputMask
              className="inputMask"
              {...register(name)}
              mask={mask}
              defaultValue={defaultValue}
              name={name}
              type="text"
              disabled={disabled}
              autoComplete="off"
              placeholder={placeholder}
              step={step}
              min={min}
              max={max}
            />
          ) : (
            <S.FormInput
              {...register(name)}
              defaultValue={defaultValue}
              name={name}
              as={as}
              type={type}
              autoComplete={autoComplete}
              placeholder={placeholder}
              maxLength={maxLength}
              step={step}
              min={min}
              max={max}
              disabled={disabled}
              rows={rows}
            />
          )}
          {errorToDisplay && (
            <S.Error>{errors[0][errorToDisplay]?.message}</S.Error>
          )}
        </S.Input>
      </label>
    </div>
  );
}
