import { Button, ButtonGroup, Col } from "react-bootstrap";
import * as S from "./styles";
import { Dispatch, SetStateAction } from "react";

interface IProps {
  typePerson: number;
  setTypePerson: Dispatch<SetStateAction<number>>;
}

export function TypePersonGroup({ typePerson, setTypePerson }: IProps) {
  return (
    <S.RowGroup typePerson={typePerson}>
      <Col>
        <ButtonGroup>
          <Button
            onClick={() => setTypePerson(1)}
            variant={typePerson === 1 ? "primary" : "light"}
            active={typePerson === 1 ? true : false}
            size="sm"
          >
            Pessoa Fisíca
          </Button>
          <Button
            onClick={() => setTypePerson(2)}
            variant={typePerson === 2 ? "primary" : "light"}
            active={typePerson === 2 ? true : false}
            size="sm"
          >
            Pessoa Juridica
          </Button>
        </ButtonGroup>
      </Col>
    </S.RowGroup>
  );
}
