import {
  PayloadAction,
  createSlice,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { sortByCreated } from "../../../Services/sortByCreated";
import { IPermissions, IUserProfile } from "./interface";
import { getPermissionsAsync, getUserSystemAsync } from "./services";

interface InitialState {
  value: IUserSystemState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface IUserSystemState {
  loading: boolean;
  fetchError: string | {};
  stateForm: StateForm;
  users: IUserProfile[];
  permissions: IPermissions[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    stateForm: { state: "default" },
    users: [],
    permissions: [],
  } as IUserSystemState,
};

export const userSystemState = createSlice({
  name: "user-system",
  initialState,
  reducers: {
    setStateForm: (state: InitialState, action: PayloadAction<StateForm>) => {
      state.value.stateForm = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialState>) => {
    builder.addCase(getUserSystemAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getUserSystemAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IUserProfile[]>) => {
        state.value.loading = false;
        state.value.users = sortByCreated(action.payload);
      }
    );
    builder.addCase(
      getUserSystemAsync.rejected,
      (state: InitialState, action) => {
        state.value.loading = false;
        state.value.fetchError = action.payload ?? "Error fetching user data";
      }
    );

    builder.addCase(getPermissionsAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getPermissionsAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IPermissions[]>) => {
        state.value.loading = false;
        state.value.permissions = sortByCreated(action.payload);
      }
    );
    builder.addCase(
      getPermissionsAsync.rejected,
      (state: InitialState, action) => {
        state.value.loading = false;
        state.value.fetchError = action.payload ?? "Error fetching permissions";
      }
    );
  },
});

export const { setStateForm } = userSystemState.actions;

export default userSystemState.reducer;
