import { useContext, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Loading from "../../../Loading/Loading";
import { ToastContainer } from "react-toastify";
import { ExtratoContext } from "../context/extrato-bancario-context";
import ListExtratoBancario from "../ListExtratoBancario";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import { useAppSelector } from "../../../../redux/store";
import * as S from "./styled";

export default function ExtratoBancario(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const {
    showExtrato,
    handleCloseExtrato,
    showList,
    listBankaccount,
    banckAccountId,
    setBanckaccountId,
    pdv,
    setPdv,
    listPdv,
    startDate,
    handleDateStart,
    endDate,
    handleDateEnd,
    fillBankAccounts,
    fillPdv,
    handleSearchList,
    loading,
  } = useContext(ExtratoContext);

  let newListBankaccount = listBankaccount.filter(
    (item) => item?.store_id === storeMe?.storeActive?.id
  );

  useEffect(() => {
    fillBankAccounts(storeMe?.storeActive?.id);
    fillPdv();
  }, []);

  return (
    <>
      <Modal
        show={showExtrato}
        onHide={handleCloseExtrato}
        size={showList ? "xl" : "lg"}
      >
        {!showList ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title className="fs-6">Extrato Bancário</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: "12px" }}>
              <S.Wrapper>
                <ReactSelect
                  label="Conta"
                  state={banckAccountId}
                  isClearable={true}
                  placeholder={"Conta"}
                  options={newListBankaccount.map((item) => ({
                    value: item.id,
                    label: item.description,
                  }))}
                  setState={setBanckaccountId}
                />

                <ReactSelect
                  label={"Considerar Registros do PDV?"}
                  state={pdv}
                  isClearable={true}
                  placeholder={"Selecione uma opção"}
                  options={listPdv}
                  setState={setPdv}
                />

                <InputDefault
                  label="De"
                  type="date"
                  state={startDate}
                  onChenge={(e) => handleDateStart(e.target.value)}
                />

                <InputDefault
                  label="Até"
                  type="date"
                  state={endDate}
                  onChenge={(e) => handleDateEnd(e.target.value)}
                />
              </S.Wrapper>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button size="sm" onClick={handleCloseExtrato}>
                Cancelar
              </Button>
              <Button size="sm" onClick={handleSearchList}>
                Proximo
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <ListExtratoBancario />
        )}
      </Modal>

      {loading && <Loading />}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
