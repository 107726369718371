import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { IStockLocation, IStockTransfer } from "./interface";
import { getStockLocationAsync, getStockTransferAsync } from "./services";

interface InitialState {
  value: StockTransferState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface StockTransferState {
  fetchError: string;
  stateForm: StateForm;
  stockTransfer: IStockTransfer[];
  stocklocation: IStockLocation[];
}

const initialState: InitialState = {
  value: {
    stateForm: { state: "default" },
    fetchError: "",
    stockTransfer: [],
    stocklocation: [],
  } as StockTransferState,
};

export const stockTransfer = createSlice({
  name: "stockTransfer",
  initialState,
  reducers: {
    setStateForm: (state: InitialState, action: PayloadAction<StateForm>) => {
      state.value.stateForm = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getStockTransferAsync.pending, (state: InitialState) => {
      state.value.stateForm = { state: "loading" }
    });
    builder.addCase(
      getStockTransferAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IStockTransfer[]>) => {
        state.value.stateForm = { state: "send" }
        state.value.stockTransfer = action.payload;
      }
    );
    builder.addCase(
      getStockTransferAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.stateForm = { state: "error" }
        state.value.fetchError = action.payload;
      }
    );

    builder.addCase(getStockLocationAsync.pending, (state: InitialState) => {
      state.value.stateForm = { state: "loading" }
    });
    builder.addCase(
      getStockLocationAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IStockLocation[]>) => {
        state.value.stateForm = { state: "send" }
        state.value.stocklocation = action.payload;
      }
    );
    builder.addCase(
      getStockLocationAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.stateForm = { state: "error" }
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const {setStateForm} = stockTransfer.actions;

export default stockTransfer.reducer;
