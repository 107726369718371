import { Col, Row, Form } from "react-bootstrap";
import styles from "./styles.module.scss";

import { ReactNode } from "react";
import { SubmitHandler, UseFormHandleSubmit } from "react-hook-form";
import { Btn } from "../../data/Btn";
import { toast } from "react-toastify";

interface ILayout {
  children: ReactNode;
  title?: string;
  onClickCancel?: () => void;
  isEditable: boolean;
  handleSubmit: UseFormHandleSubmit<any, undefined>;
  onSubmit: SubmitHandler<any>;
  stateForm: any;
  formState?: any;
  isDisable?: boolean;
  alternativeBtns?:boolean
}

export default function LayoutHookForm({
  children,
  title,
  onClickCancel,
  isEditable,
  handleSubmit,
  onSubmit,
  stateForm,
  formState,
  isDisable,
  alternativeBtns
}: ILayout) {
  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col className={styles.titleGroup}>{title && <h3>{title}</h3>}</Col>
        {
          alternativeBtns && !isDisable && (
            <div style={{display:"flex", justifyContent:"end"}}>
              <Btn
                text={isEditable ? "Atualizar" : "Salvar"}
                variante="success"
                type="submit"
              />
            </div>
          )
        }
      </Row>
      {stateForm.state === "error" && (
        <p className="text-danger">{stateForm.message}</p>
      )}
      {stateForm.state === "send" && (
        <p className="text-success">{stateForm.message}</p>
      )}
      {children}


      {
        !alternativeBtns && 
      <Row className="mt-4">
        <Col className={styles.btnGourp}>
          <Btn
            text="Cancelar"
            type="button"
            variante="secondary"
            onClick={onClickCancel}
          />
          {!isDisable && (
            <Btn
              text={isEditable ? "Atualizar" : "Salvar"}
              variante="success"
              type="submit"
            />
          )}
        </Col>
      </Row>
      }
    </form>
  );
}
