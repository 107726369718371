import "react-toastify/dist/ReactToastify.css";
import LayoutNotFilter from "../../../data/LayoutNotFilter";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Btn } from "../../../data/Btn";
import Pdv from "./pdv";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getBrPaymentTypesAsync,
  getBrPaymentTypesProvidersAsync,
  getPAymentTypePDVAsync,
  getPaymentMechanismsAsync,
} from "../../../../redux/features/PaymentTypePDV/services";
import RevenueModal from "./revenueModal";
import {
  getChartAccountsync,
  getPAymentTypesync,
} from "../../../../redux/features/paymentType/services";

export default function ConsultaFormaRecebimento(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const dispatch = useDispatch<AppDispatch>();
  const [keyActived, setKeyActived] = useState("");

  const [key, setKey] = useState<string>(keyActived ? keyActived : "revenue");

  useEffect(() => {
    dispatch(getPAymentTypesync(storeMe?.storeActive?.id));
    dispatch(getChartAccountsync(storeMe?.storeActive?.id));
    dispatch(getPAymentTypePDVAsync(storeMe?.storeActive?.id));
    dispatch(getPaymentMechanismsAsync());
    dispatch(getBrPaymentTypesAsync());
    dispatch(getBrPaymentTypesProvidersAsync());
  }, [dispatch]);

  return (
    <LayoutNotFilter title={props.Title + ` de recebimento`}>
      <Tabs
        defaultActiveKey="personData"
        className="mt-2"
        activeKey={key}
        onSelect={(k: any) => setKey(k)}
      >
        <Tab eventKey="revenue" title="Receita ERP" className="mx-3">
          <RevenueModal setKeyActived={setKeyActived} />
        </Tab>
        <Tab eventKey="PDV" title="Receita PDV" className="mx-3">
          <Pdv setKeyActived={setKeyActived} />
        </Tab>
      </Tabs>
    </LayoutNotFilter>
  );
}
