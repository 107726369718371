import { useState, useEffect, Fragment } from "react";
import Tree from "../../../../Components/Tree";
import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";
import "./styles.scss";
import { IListCategories } from "../../../../Pages/Cadastro/cadCategorias";
import Pagination from "../../../data/pagination";
import LayoutNotFilter from "../../../data/LayoutNotFilter";
import { useAppSelector } from "../../../../redux/store";
import { NoData } from "../../../noData";
import CustomLoader from "../../../data/customLoader";
import { sortByCreated } from "../../../../Services/sortByCreated";

export default function ListaCategorias({
  OnAddNewCli,
  title,
  onEditItem,
  OnAddNewCategorie,
}: IListCategories) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [loading, setLoading] = useState<boolean>(false);
  const [treeList, setTreeList] = useState<any>([]);

  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [itensFrom, setItensFrom] = useState<number>(0);
  const [itensTo, setItensTo] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);

  const handleAdd = () => {
    OnAddNewCli(true);
  };

  function handleFetchTree() {
    setLoading(true);
    api
      .get(
        `${Globals.api.treeCategories}?page=${
          page + 1
        }store_id=${
          storeMe?.storeActive?.id
        }`
      )
      .then((response: any) => {
        setTreeList(sortByCreated(response.data.data));
        setLastPage(response.data.meta.last_page);
        setTotalPages(response.data.meta.total);
        setItensFrom(response.data.meta.from);
        setItensTo(response.data.meta.to);
      })
      .catch((error: Error) => {
        console.error(`Ocorreu um erro ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    handleFetchTree();
  }, [page]);

  return (
    <>
      <LayoutNotFilter title={title} onClickAdd={handleAdd}>
        {treeList.length > 0 ? (
          <>
            <div className="mt-4 mx-4 mb-4">
              {treeList.map((tree: any) => (
                <Fragment key={tree.id}>
                  <Tree
                    category={tree}
                    onEditItem={onEditItem}
                    OnAddNewCategorie={OnAddNewCategorie}
                    handleFetchTree={handleFetchTree}
                  />
                </Fragment>
              ))}
            </div>

            <Pagination
              page={page}
              setPage={setPage} 

              
              itensFrom={itensFrom}
              itensTo={itensTo}
              lastPage={lastPage}
              totalPages={totalPages}
            />
          </>
        ) : (
          !loading && <NoData />
        )}
        {loading && <CustomLoader />}
      </LayoutNotFilter>
    </>
  );
}
