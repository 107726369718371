import api from "./api";
import globals from "../Globals.json";
import { notifyError } from "../Components/notify/notify-component";
import { normalizeDescricao } from "../utils/normalizeCaracters";

export default class GetEndPoint {
  static async data(path: string, setList: any) {
    await api
      .get(`${path}?paginate=false`)
      .then((response) => {
        const data = response.data.data;
        setList(data);
      })
      .catch((error: any) => notifyError(error));
  }

  static async store(setList: any,store_id:number) {
    await api.get(`${globals.api.stores}?paginate=false&store_id=${store_id}`).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      let list = data.map((item: any) => ({
        ...item,
        value: item.id,
        label: item.trading_name,
        store_id: item.store_id,
      }));
      setList(list);
    });
  }

  static async budgetCompleted(setList: any, store_id:number) {
    await api
      .get(`${globals.api.stockQuotes}?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
        const completedBudget = data.filter(
          (item: any) => item?.stock_quote_situation_id === 3
        );

        setList(completedBudget);
      });
  }

  static async vendor(setList: any, store_id:number) {
    await api
      .get(`${globals.api.people}/type/vendor?paginete=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: item.name,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async people(setList: any, store_id:number) {
    await api.get(`${globals.api.people}?paginate=false&store_id=${store_id}`).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.id,
        label: item.name,
        store_id: item.store_id,
      }));
      setList(list);
    });
  }

  static async categories(setList?: any, store_id?:number, setListComplet?: any) {
    //inserir paginate=false quando tiver gerenciamento de lojas
    await api
      .get(`${globals.api.categoriesCompleted}?paginate=false&store_id=${store_id}`)
      .then((response) => {

        
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
        setListComplet && setListComplet(data);
        const filterActive = data.filter((active: any) => active.is_active);
        let list = filterActive.map((item: any) => ({
          ...item,
          value: item.id,
          label: item.name,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async productTypes(setList: any, store_id:number) {
    await api
      .get(`${globals.api.productTypes}?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: item.product_type_description,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async productPurpose(setList: any,store_id:number) {
    await api.get(`${globals.api.purposes}?paginate=false&store_id=${store_id}`).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.id,
        label: item.product_purpose_description,
        store_id: item.store_id,
      }));
      setList(list);
    });
  }

  static async productMeasureUnits(setList: any, store_id:number) {
    await api.get(`${globals.api.measure}?paginate=false&store_id=${store_id}`).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.id,
        label: item.name,
        store_id: item.store_id,
      }));
      setList(list);
    });
  }

  static async productCreation(setList: any, store_id:number, setListComplet?: any) {
    await api
      .get(`${globals.api.creations}?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
        
        let list = data.map((item: any) => ({
          value: item.id,
          label: item?.relationships?.product?.sale_name,
          store_id: item.store_id,
        }));
        setList(list);
        setListComplet && setListComplet(data);
      });
  }

  static async productCreationComplet(
    setListComplet?: any,
    store_id?:number,
    setListComplet2?: any
  ) {
    await api
      .get(`${globals.api.creations}?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        setListComplet && setListComplet(data);
        setListComplet2 && setListComplet2(data);
      });
  }

  static async stocks(setList: any, filterBy?: any) {
    await api.get(`${globals.api.stocks}?paginate=false&store_id=${filterBy}`).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      
      let list = data.map((item: any) => ({
        value: item.id,
        label: item.name,
        uuid: item.uuid,
        store_id: item.store_id,
      }));
      setList(list);
    });
  }

  static async movimentStockByUuid(setList: any, uuid: string | undefined) {
    await api.get(`${globals.api.movements}/${uuid}`).then((response) => {
      const data = response.data.data;

      setList(data);
    });
  }

  static async movimentStock(setList: any, store_id: number,) {
    await api
      .get(`${globals.api.movementsStocks}?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        setList(data);
      });
  }

  static async taxProfile(setList: any, store_id: number, setListComplet?: any,) {
    await api
      .get(`${globals.api.taxProfile}?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: item.tax_profile_name,
          store_id: item.store_id,
        }));
        setList(list);
        setListComplet && setListComplet(data);
      });
  }

  static async state(setList: any) {
    await api.get(`${globals.api.states}?paginate=false`).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.id,
        label: item.state_initials,
        store_id: item.store_id,
      }));
      setList(list);
    });
  }

  static async invoicesTypes(setList: any, store_id: number) {
    await api
      .get(`${globals.api.invoicesTypes}?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: item.tax_invoice_type_description,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async invoiceTypes(setList: any, store_id: number) {
    await api
      .get(`${globals.api.taxes}/invoices-types?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: item.tax_invoice_type_description,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async cstOrigin(setList: any, store_id: number) {
    await api
      .get(`${globals.api.taxes}/cst-origins?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: item.cst_origin_description,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async ncms(setList: any, store_id: number) {
    await api
      .get(`${globals.api.taxes}/ncms&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data
          .slice(0, 1500)
          .sort((a: any, b: any) => a.id - b.id);
        
        let list = data.map((item: any) => ({
          value: item.id,
          label: item.codigo + " - " + item.descricao
          ,
          store_id: item.store_id,
        }));

        setList(list);
      });
  }



  static async cests(setList: any, store_id: number) {
    await api
      .get(`${globals.api.taxes}/cests?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
        
        let list = data.map((item: any) => ({
          value: item.id,
          label: item.cest_code,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }
  
  static async cfop(setList: any, store_id: number) {
    await api
    .get(`${globals.api.taxes}/cfop?paginate=false&store_id=${store_id}`)
    .then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: `${item.cfop} - ${normalizeDescricao(item.descricao_cfop)
          }`,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async icmsCustomerTypes(setList: any, store_id: number) {
    await api
      .get(`${globals.api.taxes}/icms-customer-types?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: item.icms_customer_type_description,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async csts(setListICMS: any, setListIPI: any, setPISCOFINS: any, store_id: number) {
    await api
      .get(`${globals.api.taxes}/csts?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
        
        let filterICMS = data.filter((item: any) => item.tax_type === "ICMS");

        let listICMS = filterICMS.map((item: any) => ({
          value: item.id,
          label: `${item.cst_code} - ${item.cst_description}`,
          store_id: item.store_id,
        }));

        let filterIPI = data.filter((item: any) => item.tax_type === "IPI");

        let listIPI = filterIPI.map((item: any) => ({
          value: item.id,
          label: `${item.cst_code} - ${item.cst_description}`,
          store_id: item.store_id,
        }));

        let filterPISCOFINS = data.filter(
          (item: any) => item.tax_type === "PIS/COFINS"
        );

        let listPISCOFINS = filterPISCOFINS.map((item: any) => ({
          value: item.id,
          label: `${item.cst_code} - ${item.cst_description}`,
          store_id: item.store_id,
        }));
        setListICMS(listICMS);
        setListIPI(listIPI);
        setPISCOFINS(listPISCOFINS);
      });
  }

  static async cstReasons(setList: any, store_id: number) {
    await api
      .get(`${globals.api.taxes}/cst-reasons?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: `${item.reason_code} - ${item.reason_description}`,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async productsCode(setList: any, storeId: any ,) {
    await api.get(`${globals.api.products}?paginate=false&store_id=${storeId}`).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.id,
        label: `${item.code}`,
        store_id: item.store_id,
      }));
      setList(list);
    });
  }

  static async products(setList: any,  storeId: any , setListComplet?: any) {
    await api
      .get(`${globals.api.products}?paginate=false&append=stock_qty&store_id=${storeId}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
        
        let list = data.map((item: any) => ({
          value: item.id,
          uuid: item.uuid,
          stock_local_id: item.stock_local_id,
          label: item.purchase_name,
          store_id: item.store_id,
          stock_qty: item.stock_qty,
          unit: item?.relationships.sale_measure_unit.name,
          cost: item?.cost_price,
        }));
        setList(list);

        setListComplet && setListComplet(data);
      });
  }

  static async positions(setList: any, storeId?: any) {
    await api
      .get(`${globals.api.stockPositions}?paginate=false&store_id=${storeId}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          label: item.sale_name,
          value: item.product_id,
        }));
        setList(list);
      });
  }

  static async productsComplet(setListComplet?: any, store_id?:number) {
    await api
      .get(`${globals.api.products}?paginate=false&append=stock_qty&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        setListComplet && setListComplet(data);
      });
  }

  static async productsPage(setList: any, store_id:number, setListComplet?: any) {
    await api.get(`${globals.api.products}?paginate=false&store_id=${store_id}`).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.sale_name,
        label: item.sale_name,
        store_id: item.store_id,
      }));
      setList(list);
      setListComplet && setListComplet(data);
    });
  }

  static async productsPageMovi(setList: any, setListComplet?: any, store_id?:number) {
    await api
      .get(`${globals.api.products}?paginate=false&filter[is_stockable]=true&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: item.sale_name,
          store_id: item.store_id,
        }));
        setList(list);
        setListComplet && setListComplet(data);
      });
  }

  /*
  static async productsStockable(
    setList: any,
    setListComplet?: any,
    tipoProducao?: number
  ) {
    await api
      .get(
        tipoProducao === 1
          ? `${globals.api.products}?paginate=false&filter[is_stockable]=true`
          : `${globals.api.products}?paginate=false&filter[is_stockable]=false`
      )
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        let list = data.map((item: any) => ({
          value: item.id,
          label: item.purchase_name,
          store_id: item.store_id,
        }));
        setList(list);
        setListComplet && setListComplet(data);
      });
  }
  */

  static async productsStockable(
    setList: any,
    setListComplet?: any,
    store_id?: number
  ) {
    await api
      .get(`${globals.api.products}?paginate=false&filter[is_stockable]=true&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        console.log("is_stockable",data);
        
        let list = data.map((item: any) => ({
          value: item.id,
          label: item.purchase_name,
          store_id: item.store_id,
          product_purpose_id: item.product_purpose_id,
        }));
        setList(list);
        setListComplet && setListComplet(data);
      });
  }

  /*   const filterComplement = data.filter(
          (item: any) => item.type === 3 || item.type === 8
        ); */

  static async complements(setList: any,  store_id?: number) {
    await api
      .get(`${globals.api.complements}?paginate=false&store_id=${store_id}`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        const filterComplement = data.filter(
          (item: any) =>
            item.product_type_id === 4 || item.product_type_id === 8
        );

        let list = filterComplement.map((item: any) => ({
          id: item.id,
          value: item.id,
          label: item.sale_name,
          code: item.code,
          store_id: item.store_id,
        }));
        setList(list);
      });
  }

  static async movimentTypes(setList: any, filter?: "entrada" | "saida" ) {
    await api
      .get(`${globals.api.movementsTypes}?paginate=false`)
      .then((response) => {
        const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

        if (filter) {
          const filterData = data.filter((item: any) => item.type === filter);
          let list = filterData.map((item: any) => ({
            value: item.id,
            label: item.description,
          }));

          setList(list);
        } else {
          let list = data.map((item: any) => ({
            value: item.id,
            label: item.description,
            store_id: item.store_id,
          }));

          setList(list);
        }
      });
  }
}
