import { Col, Row } from "react-bootstrap";
import { IFormPerfilFiscal } from "../../../../Pages/Cadastro/CadPerfilFiscal";
import Layout from "../../../data/Layout";
import { ToastContainer } from "react-toastify";
import Loading from "../../../Loading/Loading";
import { useEffect, useState, useContext } from "react";
import ReactSelect from "../../../data/reactSelect";
import GetEndPoint from "../../../../Services/GetEndPoint";
import OptionsReactSelect from "../../../../interfaces/OptionsReactSelect";
import { notify, notifyError } from "../../../notify/notify-component";
import { StoreContext } from "../../../../context/store-context";
import { CreateAndEdit } from "../../../../Services/CreateAndEdit";
import globals from "../../../../Globals.json";
import { InputDefault } from "../../../data/inputDefault";
import { useAppSelector } from "../../../../redux/store";

import * as S from "./styled";
import ReactSelectAsync from "../../../data/reactSelectAsync";
import api from "../../../../Services/api";

export default function FormPerfilFiscal({
  title,
  OnAddNewPerfilFiscal,
  editando,
  perfilFical,
}: IFormPerfilFiscal) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [loading, setLoading] = useState<boolean>(false);

  const [taxName, setTaxName] = useState<string>(
    editando && perfilFical ? perfilFical.tax_profile_name : ""
  );
  const [weight, setWeight] = useState<string>(
    editando && perfilFical ? perfilFical.tax_weight.toString() : ""
  );
  const [types, setTypes] = useState<number | undefined>(
    editando && perfilFical
      ? perfilFical.relationships.tax_invoice_type?.id
      : undefined
  );
  const [origins, setOrigins] = useState<number | undefined>(
    editando && perfilFical
      ? perfilFical.relationships.cst_origin?.id
      : undefined
  );
  const [ncm, setNcm] = useState<number | undefined>(
    editando && perfilFical ? perfilFical.relationships.ncm?.id : undefined
  );
  const [cest, setCest] = useState<number | undefined>(
    editando && perfilFical ? perfilFical.relationships.cest?.id : undefined
  );
  const [cfop, setCfop] = useState<number | undefined>(
    editando && perfilFical ? perfilFical.relationships.cfop?.id : undefined
  );
  const [stateOrigin, setStateOrigin] = useState<number | undefined>(
    editando && perfilFical
      ? perfilFical.relationships.icms_origin_state?.id
      : undefined
  );
  const [stateDestiny, setStateDestiny] = useState<number | undefined>(
    editando && perfilFical
      ? perfilFical.relationships.icms_destiny_state?.id
      : undefined
  );
  const [customer, setCustomer] = useState<number | undefined>(
    editando && perfilFical
      ? perfilFical.relationships.icms_customer_type?.id
      : undefined
  );
  const [icms, setIcms] = useState<number | undefined>(
    editando && perfilFical ? perfilFical.relationships.cst_icms?.id : undefined
  );
  const [ipi, setIpi] = useState<number | undefined>(
    editando && perfilFical ? perfilFical.relationships.cst_ipi?.id : undefined
  );
  const [cstReasons, setCstReasons] = useState<number | undefined>(
    editando && perfilFical
      ? perfilFical.relationships.cst_ipi_reason?.id
      : undefined
  );
  const [pis, setPis] = useState<number | undefined>(
    editando && perfilFical ? perfilFical.relationships.cst_pis?.id : undefined
  );
  const [cofins, setCofins] = useState<number | undefined>(
    editando && perfilFical
      ? perfilFical.relationships.cst_cofins?.id
      : undefined
  );
  const [aliquot, setAliquotIcms] = useState<string>(
    editando && perfilFical ? perfilFical.icms_aliquot.toString() : ""
  );
  const [mva, setMva] = useState<string>(
    editando && perfilFical ? perfilFical.icms_mva.toString() : ""
  );
  const [reduction, setReduction] = useState<string>(
    editando && perfilFical
      ? perfilFical.icms_base_calc_reduction.toString()
      : ""
  );
  const [icmsAliquotDeferral, setIcmsAliquotDeferral] = useState<string>(
    editando && perfilFical ? perfilFical.icms_aliquot_deferral.toString() : ""
  );
  const [icmsCredit, setIcmsCredit] = useState<string>(
    editando && perfilFical ? perfilFical.icms_credit.toString() : ""
  );
  const [aliquotIpi, setAliquotIpi] = useState<string>(
    editando && perfilFical ? perfilFical.ipi_aliquot.toString() : ""
  );
  const [aliquotPis, setAliquotPis] = useState<string>(
    editando && perfilFical ? perfilFical.ipi_aliquot.toString() : ""
  );
  const [aliquotCofins, setAliquotCofins] = useState<string>(
    editando && perfilFical ? perfilFical.cofins_aliquot.toString() : ""
  );
  const [statesList, setStatesList] = useState<OptionsReactSelect[]>([]);
  const [invoiceTypesList, setInvoiceTypesList] = useState<
    OptionsReactSelect[]
  >([]);
  const [cstOriginsList, setCstOriginsList] = useState<OptionsReactSelect[]>(
    []
  );
  const [ncmList, setNcmList] = useState<OptionsReactSelect[]>([]);
  const [cestList, setCestList] = useState<OptionsReactSelect[]>([]);
  const [cfopList, setCfopList] = useState<OptionsReactSelect[]>([]);
  const [icmsCustomerTypesList, setIcmsCustomerTypesList] = useState<
    OptionsReactSelect[]
  >([]);
  const [icmsList, setIcmsList] = useState<OptionsReactSelect[]>([]);
  const [ipiList, setIpiList] = useState<OptionsReactSelect[]>([]);
  const [pisList, setPisList] = useState<OptionsReactSelect[]>([]);
  const [cstReasonsList, setCstReasonsList] = useState<OptionsReactSelect[]>(
    []
  );

  const [validTaxName, setValidTaxName] = useState<boolean>(false);

  const [validNcm, setValidNcm] = useState<boolean>(false);
  const [validCfop, setValidCfop] = useState<boolean>(false);
  const [validIcms, setValidIcms] = useState<boolean>(false);
  const [validAliquot, setValidAliquot] = useState<boolean>(false);
  const [validAliquotIpi, setValidAliquotIpi] = useState<boolean>(false);
  const [validIpi, setValidIpi] = useState<boolean>(false);
  const [validPis, setValidPis] = useState<boolean>(false);
  const [validCofins, setValidCofins] = useState<boolean>(false);
  const [validAliquotPis, setValidAliquotPis] = useState<boolean>(false);
  const [validType, setValidType] = useState<boolean>(false);
  const [validOrigins, setValidOrigins] = useState<boolean>(false);
  const [objetNCM, setObjetNCM] = useState<any[]>([]);

  const handleCancel = () => {
    OnAddNewPerfilFiscal(false);
    cleanInputs();
  };

  const cleanInputs = () => {
    setTaxName("");
    setStateOrigin(undefined);
    setStateDestiny(undefined);
    setTypes(undefined);
    setOrigins(undefined);
    setNcm(undefined);
    setCest(undefined);
    setCfop(undefined);
    setCustomer(undefined);
    setIcms(undefined);
    setIpi(undefined);
    setCstReasons(undefined);
    setPis(undefined);
    setWeight("");
    setAliquotIcms("");
    setMva("");
    setReduction("");
    setIcmsAliquotDeferral("");
    setIcmsCredit("");
    setAliquotIpi("");
    setAliquotPis("");
    setAliquotCofins("");
  };

  const handleValidete = () => {
    if (!taxName) setValidTaxName(true);
    if (taxName) setValidTaxName(false);

    if (!ncm) setValidNcm(true);
    if (ncm) setValidNcm(false);
    if (!cfop) setValidCfop(true);
    if (cfop) setValidCfop(false);
    if (!icms) setValidIcms(true);
    if (icms) setValidIcms(false);
    if (!aliquot) setValidAliquot(true);
    if (aliquot) setValidAliquot(false);
    if (!ipi) setValidIpi(true);
    if (ipi) setValidIpi(false);
    if (!aliquotIpi) setValidAliquotIpi(true);
    if (aliquotIpi) setValidAliquotIpi(false);
    if (!pis) setValidPis(true);
    if (pis) setValidPis(false);
    if (!cofins) setValidCofins(true);
    if (cofins) setValidCofins(false);
    if (!aliquotPis) setValidAliquotPis(true);
    if (aliquotPis) setValidAliquotPis(false);
    if (!origins) setValidOrigins(true);
    if (origins) setValidOrigins(false);
    if (!types) setValidType(true);
    if (types) setValidType(false);

    if (
      ncm &&
      cfop &&
      icms &&
      aliquot &&
      ipi &&
      aliquotIpi &&
      pis &&
      aliquotPis &&
      origins &&
      types
    ) {
      return true;
    }

    return false;
  };

  const handleSubmit = () => {
    const valid = handleValidete();

    if (valid === false) {
      notifyError("Por favor preencha todos os campos obrigatórios.");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    let data = {
      uuid: perfilFical?.uuid ? perfilFical.uuid : null,
      store_id: storeMe?.storeActive?.id,
      tax_profile_name: taxName,
      tax_invoice_type_id: types,
      cst_origin_id: origins,
      ncm_id: ncm,
      cfop_id: cfop,
      cest_id: cest,
      cst_icms_id: icms,
      icms_origin_state_id: stateOrigin,
      icms_destiny_state_id: stateDestiny,
      icms_customer_type_id: customer,
      cst_ipi_id: ipi,
      cst_ipi_reason_id: cstReasons,
      ...(pis && { cst_pis_id: pis }),
      ...(cofins && { cst_cofins_id: cofins }),
      tax_weight: weight ? parseFloat(weight) : 0,
      icms_aliquot: aliquot ? parseFloat(aliquot) : 0,
      icms_credit: aliquot ? parseFloat(icmsCredit) : 0,
      icms_mva: mva ? parseFloat(mva) : 0,
      icms_base_calc_reduction: reduction ? parseFloat(reduction) : 0,
      icms_aliquot_deferral: icmsAliquotDeferral
        ? parseFloat(icmsAliquotDeferral)
        : 0,
      ipi_aliquot: aliquotIpi ? parseFloat(aliquotIpi) : 0,
      pis_aliquot: aliquotPis ? parseFloat(aliquotPis) : 0,
      cofins_aliquot: aliquotCofins ? parseFloat(aliquotCofins) : 0,
    };

    CreateAndEdit.post(globals.api.taxProfile, data).then((res: any) => {
      setLoading(true);
      if (!res) {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      } else {
        setTimeout(() => {
          handleCancel();
          setLoading(false);
        }, 100);
      }
    });
  };

  const getNcmByCodeOrDes = async (path: string) => {
    if (path.length < 3) return;
    const isNumber = !isNaN(Number(path));
    const url = isNumber
      ? `${globals.api.taxes}/ncms?codigo=${path}`
      : `${globals.api.taxes}/ncms?descricao=${path}`;
    const res = await api.get(url);
    return await  res?.data?.data?.map((item: any) => ({
      value: item.id,
      label: item.codigo + " - " + item.descricao,
    }));
  };

  useEffect(() => {
    GetEndPoint.state(setStatesList);
    GetEndPoint.invoiceTypes(setInvoiceTypesList,
      storeMe?.storeActive?.id);
    GetEndPoint.cstOrigin(setCstOriginsList,
      storeMe?.storeActive?.id);
    GetEndPoint.cests(setCestList,
      storeMe?.storeActive?.id);
    GetEndPoint.cfop(setCfopList,
      storeMe?.storeActive?.id);
    GetEndPoint.icmsCustomerTypes(setIcmsCustomerTypesList,
      storeMe?.storeActive?.id);
    GetEndPoint.csts(setIcmsList, setIpiList, setPisList,
      storeMe?.storeActive?.id);
    GetEndPoint.cstReasons(setCstReasonsList,
      storeMe?.storeActive?.id);
      getObjectNCM()
  }, []);

  useEffect(() => {
    if (ncm) setValidNcm(false);
    if (cfop) setValidCfop(false);
    if (icms) setValidIcms(false);
    if (aliquot) setValidAliquot(false);
    if (ipi) setValidIpi(false);
    if (aliquotIpi) setValidAliquotIpi(false);
    if (pis) setValidPis(false);
    if (cofins) setValidCofins(false);
    if (aliquotPis) setValidAliquotPis(false);
    if (origins) setValidOrigins(false);
    if (types) setValidType(false);
  }, [
    ncm,
    cfop,
    icms,
    aliquot,
    aliquotIpi,
    cofins,
    pis,
    aliquotPis,
    origins,
    types,
    ipi,
  ]);

  const getObjectNCM = async () => {
    if(perfilFical) {
      const objet = await getNcmByCodeOrDes(perfilFical?.relationships.ncm?.codigo)
      setObjetNCM(objet)
    }
  }

  console.log("ncm", ncm);
  console.log("objetNCM", objetNCM);
  


  return (
    <>
      <Layout
        title={title}
        isEditable={editando}
        onClickSave={handleSubmit}
        onClickEdit={handleSubmit}
        onClickCancel={handleCancel}
      >
        <S.Wrapper>
          <InputDefault
            label="Nome"
            type="text"
            autoComplete="off"
            state={taxName}
            placeholder="Nome"
            maxLength={50}
            setState={setTaxName}
            isInvalid={validTaxName}
          />

          <InputDefault
            label="Peso"
            placeholder="0,00 Kg"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={weight}
            setState={setWeight}
          />

          <ReactSelect
            label="Tipo"
            placeholder="Tipo"
            isClearable
            options={invoiceTypesList}
            state={types}
            setState={setTypes}
            isInvalid={validType}
            isLoading={invoiceTypesList ? false : true}
          />

          <ReactSelect
            label="Origem"
            placeholder="Origem"
            isClearable
            options={cstOriginsList}
            state={origins}
            setState={setOrigins}
            isInvalid={validOrigins}
            isLoading={cstOriginsList ? false : true}
          />

          <ReactSelectAsync
            options={objetNCM}
            label="NCM"
            placeholder="Pesquise seu NCM"
            isClearable
            state={ncm}
            setState={setNcm}
            isInvalid={validNcm}
            promiseOptions={getNcmByCodeOrDes}
          />

          <ReactSelect
            label="CEST"
            placeholder="CEST"
            isClearable
            options={cestList}
            state={cest}
            setState={setCest}
            isLoading={cestList ? false : true}
          />

          <ReactSelect
            label="CFOP"
            placeholder="CFOP"
            isClearable
            options={cfopList}
            state={cfop}
            setState={setCfop}
            isInvalid={validCfop}
            isLoading={cfopList ? false : true}
          />
        </S.Wrapper>

        <hr style={{ width: "100%" }} />

        <Row className="mb-2">
          <Col className="spaceBetween">
            <h4>ICMS</h4>
          </Col>
        </Row>

        <S.Wrapper>
          <ReactSelect
            label="Origem"
            placeholder="UF"
            isClearable
            options={statesList}
            state={stateOrigin}
            setState={setStateOrigin}
            isLoading={statesList ? false : true}
          />

          <ReactSelect
            label="Destino"
            placeholder="UF"
            isClearable
            options={statesList}
            state={stateDestiny}
            setState={setStateDestiny}
            isLoading={statesList ? false : true}
          />

          <ReactSelect
            label="Tip. cliente"
            placeholder="Tipo de cliente"
            isClearable
            options={icmsCustomerTypesList}
            state={customer}
            setState={setCustomer}
          />

          <ReactSelect
            label="Sit. tributária"
            placeholder="Situação tributária"
            isClearable
            options={icmsList}
            state={icms}
            setState={setIcms}
            isInvalid={validIcms}
            isLoading={icmsList ? false : true}
          />

          <InputDefault
            label="Alíquota"
            placeholder="0,00"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={aliquot}
            setState={setAliquotIcms}
            isInvalid={validAliquot}
          />

          <InputDefault
            label="MVA"
            placeholder="0,00"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={mva}
            setState={setMva}
          />

          <InputDefault
            label="Diferimento"
            placeholder="0,00"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={icmsAliquotDeferral}
            setState={setIcmsAliquotDeferral}
          />

          <InputDefault
            label="Cálc. crédito"
            placeholder="0,00"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={icmsCredit}
            setState={setIcmsCredit}
          />

          <InputDefault
            label="Redu. calculo"
            placeholder="0,00"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={reduction}
            setState={setReduction}
          />
        </S.Wrapper>

        <hr style={{ width: "100%" }} />

        <Row className="mb-2">
          <Col className="spaceBetween">
            <h4>IPI</h4>
          </Col>
        </Row>
        <S.Wrapper>
          <ReactSelect
            label="Sit. tributária"
            placeholder="Situação tributária"
            isClearable
            options={ipiList}
            state={ipi}
            setState={setIpi}
            isInvalid={validIpi}
            isLoading={ipiList ? false : true}
          />

          <InputDefault
            label="Aliq. IPI"
            placeholder="0,00"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={aliquotIpi}
            setState={setAliquotIpi}
            isInvalid={validAliquotIpi}
          />

          <ReactSelect
            label="Enqu.de IPI"
            placeholder="Enquadramento de ipi"
            isClearable
            options={cstReasonsList}
            state={cstReasons}
            setState={setCstReasons}
            isLoading={cstReasonsList ? false : true}
          />
        </S.Wrapper>

        <hr style={{ width: "100%" }} />

        <Row className="mb-2">
          <Col className="spaceBetween">
            <h4>PIS e COFINS</h4>
          </Col>
        </Row>

        <S.Wrapper>
          <ReactSelect
            label="Sit. tributária"
            placeholder="Situação tributária"
            isClearable
            options={pisList}
            state={pis}
            setState={setPis}
            isInvalid={validPis}
            isLoading={pisList ? false : true}
          />

          <InputDefault
            label="Aliq. PIS"
            placeholder="0,00"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={aliquotPis}
            setState={setAliquotPis}
            isInvalid={validAliquotPis}
          />

          <ReactSelect
            label="Sit.tributária"
            placeholder="Situação tributária"
            isClearable
            options={pisList}
            state={cofins}
            setState={setCofins}
            isInvalid={validCofins}
            isLoading={pisList ? false : true}
          />

          <InputDefault
            label="Aliq. COFINS"
            placeholder="0,00"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={aliquotCofins}
            setState={setAliquotCofins}
          />
        </S.Wrapper>
      </Layout>

      {loading && <Loading />}
      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
