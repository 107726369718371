import { Button, Modal } from "react-bootstrap";

interface IProps {
  state: any;
  setState: any;
  text: string;
  handleAction?: any;
}

export default function ModalBootstrap({
  text,
  state,
  setState,
  handleAction,
}: IProps) {
  return (
    <Modal show={state} onHide={() => setState(false)}>
      <Modal.Header closeButton style={{ backgroundColor: "#ced0d4f4" }}>
        <h3 style={{ fontSize: "18px" }}>Confirmação</h3>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#f7f7f8f4" }}>{text}</Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: "#f7f7f8f4",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button variant="secondary" onClick={() => setState(false)} size="sm">
          Cancelar
        </Button>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="success"
            onClick={handleAction}
            size="sm"
            style={{ padding: "4px 20px" }}
          >
            Sim
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
