import { useEffect, useState } from "react";
import { StateForm } from "../../../../interfaces/state-form";
import Loading from "../../../Loading/Loading";
import { peopleSchema } from "../../../../schemas/peopleSchema";
import * as Yup from "yup";
import { InputClone } from "../InputClone";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IFormPeople } from "../../../../Pages/Cadastro/CadClientes";
import LayoutHookForm from "../../../reactHookForm/LayoutForm";
import { Services } from "../../../../Services/newService";
import Format from "../../../../Services/Format";
import { useAppSelector } from "../../../../redux/store";
import { notifyError } from "../../../notify/notify-component";

type FormData = Yup.InferType<typeof peopleSchema>;

export interface ITypeRecord {
  customer: boolean;
  vendor: boolean;
  employee: boolean;
  carrier: boolean;
}

export function FormPessoa({
  Title,
  people,
  editando,
  OnAddNewPeople,
  selectOptions,
  Path,
}: IFormPeople) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [typePerson, setTypePerson] = useState<number>(
    editando ? (people?.cpf_cnpj?.length > 14 ? 2 : 1) : 1
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(peopleSchema),
  });

  const initialTypeRecord = {
    customer: people?.is_customer
      ? people?.is_customer
      : Path === "customer"
      ? true
      : false,
    vendor: people?.is_vendor
      ? people?.is_vendor
      : Path === "vendor"
      ? true
      : false,
    employee: people?.is_employee
      ? people?.is_employee
      : Path === "employee"
      ? true
      : false,
    carrier: people?.is_carrier
      ? people?.is_carrier
      : Path === "carrier"
      ? true
      : false,
  };

  const [gender, setGender] = useState<string>(people.gender || "");
  const [typeRecord, setTypeRecord] = useState<ITypeRecord>(initialTypeRecord);
  const [arrayAdress, setArrayAdress] = useState<any[]>([]);
  const [arrayContacts, setArrayContacts] = useState<any[]>([]);
  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    try {
      if (!editando) {
        setStateForm({ state: "loading" });
        const newData = {
          store_id: storeMe?.storeActive?.id,
          ...(data.cpf_cnpj && {
            cpf_cnpj: Format.stringFormated(data.cpf_cnpj),
          }),
          ...(data.name && { name: data.name }),
          ...(data.state_registration && {
            state_registration: data.state_registration,
          }),
          ...(data.typePerson && { registration_type: data.typePerson }),
          ...(data.trading_name && { trading_name: data.trading_name }),
          ...(data.email && { email: data.email }),
          ...(data.birthdate &&
            data.birthdate.length > 0 && { birthdate: data.birthdate }),
          ...(data.suframa && { suframa: data.suframa }),
          ...(data.additional_data && {
            additional_data: data.additional_data,
          }),
          ...(data.rg && data.rg.length > 0 && { rg: data.rg }),
          ...((data.isento_ie === 1 && { free_state_registration: false }) || {
            free_state_registration: true,
          }),
          ...(gender && { gender }),
          is_customer: typeRecord?.customer,
          is_vendor: typeRecord?.vendor,
          is_employee: typeRecord?.employee,
          is_carrier: typeRecord?.carrier,
          ...(data.foreign_document &&
            data.foreign_document.length > 0 && {
              foreign_document: data.foreign_document,
            }),
          addresses: arrayAdress,
          contacts: arrayContacts,
        };

        const res = await Services.people(newData, setStateForm);

        if (res?.status === 200) {
          setTimeout(() => {
            handleCancel();
          }, 100);
        }
      } else {
        setStateForm({ state: "loading" });

        const newData = {
          uuid: people.uuid,
          ...(data.cpf_cnpj && {
            cpf_cnpj: Format.stringFormated(data.cpf_cnpj),
          }),
          ...(data.name && { name: data.name }),
          ...(data.state_registration && {
            state_registration: data.state_registration,
          }),
          ...(data.typePerson && { registration_type: data.typePerson }),
          ...(data.trading_name && { trading_name: data.trading_name }),
          ...(data.email && { email: data.email }),
          ...(data.birthdate &&
            data.birthdate.length > 0 && { birthdate: data.birthdate }),
          ...(data.suframa && { suframa: data.suframa }),
          ...(data.additional_data && {
            additional_data: data.additional_data,
          }),
          ...(data.rg && data.rg.length > 0 && { rg: data.rg }),
          ...((data.isento_ie === 1 && { free_state_registration: false }) || {
            free_state_registration: true,
          }),
          ...(gender && { gender }),
          is_customer: typeRecord?.customer,
          is_vendor: typeRecord?.vendor,
          is_employee: typeRecord?.employee,
          is_carrier: typeRecord?.carrier,
          ...(data.foreign_document &&
            data.foreign_document.length > 0 && {
              foreign_document: data.foreign_document,
            }),
          addresses: arrayAdress,
          contacts: arrayContacts,
        };

        const res = await Services.peoplePut(newData, setStateForm);

        if (res?.status === 200) {
          setTimeout(() => {
            handleCancel();
          }, 100);
        }
      }
    } catch (err: any) {
      const erroAxios = err?.response?.data?.errors;
      const erroHandler = erroAxios ? Object.values(erroAxios)[0] : err;

      if (typeof erroHandler === "string") {
        const parseError = JSON.parse(erroHandler);

        setStateForm({
          state: "error",
          message:
            parseError.cause[0].message || "api_error_please_contact_support",
        });
        return;
      }
    }
  };

  const handleCancel = () => {
    reset();
    OnAddNewPeople(false);
  };

 

  useEffect(() => {
    const peopleData: any = {};

    for (const key in people) {
      peopleData[key] = people[key] ?? "" ;
    }
    
    const resetData = {
      ...peopleData,
        cpf_cnpj: people?.cpf_cnpj || "",
        name: people?.name || "",
        email: people?.email || "",
        birthdate: people?.birthdate || "",
        rg: people?.rg || "",
        foreign_document: people?.foreign_document || "",
        additional_data: people?.additional_data || "",
        company_name: people?.name || "",
        trading_name: people?.trading_name || "",
        suframa: people?.suframa || "",
        municipal_registration: people?.municipal_registration || "",
        status: people?.status || "",
        isento_ie: people?.isento_ie || "",
    };
    people?.relationships?.addresses && setArrayAdress(people?.relationships?.addresses);
    people?.relationships?.contacts && setArrayContacts(people?.relationships?.contacts);
    reset(resetData);
    
  }, []);

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      notifyError("Por favor preencher todos os campos obrigatórios");
    }
  }, [formState.errors]);

  const inputError = [errors];

  return (
    <>
      <LayoutHookForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={Title}
        isEditable={editando}
        onClickCancel={handleCancel}
        stateForm={stateForm}
      >
        <InputClone
          register={register}
          control={control}
          watch={watch}
          selectOptions={selectOptions}
          setValue={setValue}
          setError={setError}
          errors={inputError}
          setStateForm={setStateForm}
          reset={reset}
          data={people}
          path={Path}
          isEditable={editando}
          typePerson={typePerson}
          setTypePerson={setTypePerson}
          typeRecord={typeRecord}
          setTypeRecord={setTypeRecord}
          gender={gender}
          setGender={setGender}
          arrayAdress={arrayAdress}
          setArrayAdress={setArrayAdress}
          arrayContacts={arrayContacts}
          setArrayContacts={setArrayContacts}
        />
      </LayoutHookForm>

      {stateForm.state === "loading" && <Loading />}
    </>
  );
}
