import { IFormPerfilUsuario } from "../../../../Pages/Cadastro/CadPermissions";
import * as Yup from "yup";
import { perfilUsuarioSchemaForm } from "../../../../redux/features/userProfile/schema";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LayoutHookForm from "../../../reactHookForm/LayoutForm";
import * as S from "./styles";
import { InputHook } from "../../../reactHookForm/InputHook";
import { CheckboxHookLeft } from "../../../reactHookForm/inputCheckboxLeft";
import { setStateForm } from "../../../../redux/features/userProfile/user-profile-slice";
import {
  createUserProfileAsync,
  editeUserProfileAsync,
  getUserProfileAsync,
} from "../../../../redux/features/userProfile/services";
import { notifyError } from "../../../notify/notify-component";
import { SelectHook } from "../../../reactHookForm/SelectHook";
import { useEffect, useState } from "react";
import { getUserAccessAsync } from "../../../../redux/features/userAccess/services";

type FormData = Yup.InferType<typeof perfilUsuarioSchemaForm>;

export function FormPerfilUsuario({
  title,
  editando,
  OnAddNewStore,
  selectOptions,
  perfilUsuario,
}: IFormPerfilUsuario) {
  const dispatch = useDispatch<AppDispatch>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { stateForm, users } = useAppSelector(
    ({ userProfileReducer }) => userProfileReducer.value
  );
  const { loading, role } = useAppSelector(
    ({ userAccessReducer }) => userAccessReducer.value
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(perfilUsuarioSchemaForm) });

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    console.log("chegou");
    
    dispatch(setStateForm({ state: "loading" }));

    if (editando) {
      dispatch(editeUserProfileAsync(data)).then((result: any) => {
        if (editeUserProfileAsync.fulfilled.match(result)) {
          dispatch(getUserProfileAsync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao atualizar item.");
        }
      });
      handleCancel()
      dispatch(setStateForm({ state: "default" }));
    } else {
      const { name, description, ...extratData } = data;
      const truePermissions = Object.keys(extratData).filter(
        (key) => extratData[key] === true
      );
      const newData = {
        storeId: storeMe?.storeActive?.id,
        name,
        description,
        role_id: data?.role_id?.value,
        permission_names: truePermissions,
      };
  console.log("newData", newData);
  
      dispatch(createUserProfileAsync(newData)).then((result: any) => {
        if (createUserProfileAsync.fulfilled.match(result)) {
          dispatch(getUserProfileAsync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao adicionar item.");
        }
      });
      handleCancel()
      dispatch(setStateForm({ state: "default" }));
    }
  };

  const handleCancel = () => {
    reset();
    OnAddNewStore(false);
  };

  useEffect(() => {
    dispatch(getUserProfileAsync(storeMe?.storeActive?.id));
    dispatch(getUserAccessAsync(storeMe?.storeActive?.id));
  }, [dispatch]);

  const inputError = [errors];
  console.log("role", role);
  console.log("users", users);
  

  return (
    <>
      <LayoutHookForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={title}
        isEditable={true}
        onClickCancel={handleCancel}
        stateForm={stateForm}
        alternativeBtns={true}
      >
        <S.Section>
          <div className="top">
            <h3>Selecione o Perfil de Acesso</h3>
            <S.WrapperInput>
             
                <SelectHook
                register={register}
                control={control}
                errors={inputError}
                label="Perfil"
                name="role_id"
                placeholder="perfil de acesso"
                options={role.map((item : any) => ({
                  label: item.name,
                  value: item.id
                }))}
              />
            </S.WrapperInput>
          </div>
          <div className="bottom">
            <h3>Permissões</h3>
            <p>Selecione as permissões para este perfil de usuário</p>
            <div
              className="grid-section"
              style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
            >
              <h4>Clientes</h4>
              <div className="grid">
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Clientes"
                    name="visualizar-clientes"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Clientes"
                    name="cadastrar-clientes"
                    
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Clientes"
                    name="editar-clientes"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Clientes"
                    name="excluir-clientes"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Fornecedores"
                    name="visualizar-fornecedores"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Fornecedores"
                    name="cadastrar-fornecedores"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Fornecedores"
                    name="editar-fornecedores"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Fornecedores"
                    name="excluir-fornecedores"
                  />
                </div>
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Funcionários"
                    name="visualizar-funcionarios"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Funcionários"
                    name="cadastrar-funcionarios"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Funcionários"
                    name="editar-funcionarios"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Funcionários"
                    name="excluir-funcionarios"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Transportadoras"
                    name="visualizar-transportadoras"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Transportadoras"
                    name="cadastrar-transportadoras"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Transportadoras"
                    name="editar-transportadoras"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Transportadoras"
                    name="excluir-transportadoras"
                  />
                </div>
              </div>
            </div>
            <div className="grid-section">
              <h4>Produtos</h4>
              <div className="grid">
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Produtos"
                    name="visualizar-produtos"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Produtos"
                    name="cadastrar-produtos"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Duplicar Produtos"
                    name="duplicar-produtos"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Produtos"
                    name="editar-produtos"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Produtos"
                    name="excluir-produtos"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Ficha técnica"
                    name="visualizar-ficha-tecnica"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Ficha técnica"
                    name="cadastrar-ficha-tecnica"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Ficha técnica"
                    name="editar-ficha-tecnica"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Ficha técnica"
                    name="excluir-ficha-tecnica"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Produção"
                    name="visualizar-producao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Produção"
                    name="cadastrar-producao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Estornar Produção"
                    name="estornar-producao"
                  />
                </div>
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Unidade de Medida"
                    name="visualizar-unidade-medida"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Unidade de Medida"
                    name="cadastrar-unidade-medida"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Unidade de Medida"
                    name="editar-unidade-medida"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Unidade de Medida"
                    name="excluir-unidade-medida"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Categorias"
                    name="visualizar-categorias"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Categorias"
                    name="cadastrar-categorias"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Categorias"
                    name="editar-categorias"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Categorias"
                    name="excluir-categorias"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Perfil Fiscal"
                    name="visualizar-perfil-fiscal"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Perfil Fiscal"
                    name="cadastrar-perfil-fiscal"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Perfil Fiscal"
                    name="editar-perfil-fiscal"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Perfil Fiscal"
                    name="excluir-perfil-fiscal"
                  />
                </div>
              </div>
            </div>
            <div
              className="grid-section"
              style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
            >
              <h4>Sincronicação</h4>
              <div className="grid">
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Tabela de Preços"
                    name="visualizar-tabela-precos"
                  />
                </div>
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Tabela de Preços"
                    name="editar-tabela-precos"
                  />
                </div>
              </div>
            </div>
            <div className="grid-section">
              <h4>Estoque</h4>
              <div className="grid">
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Movimentação"
                    name="visualizar-movimentacao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Movimentação"
                    name="cadastrar-movimentacao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Item Movimentação"
                    name="visualizar-item-movimentacao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Estornar Movimentação"
                    name="estornar-movimentacao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Movimentação"
                    name="excluir-movimentacao"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Transferência"
                    name="visualizar-transferencia"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Item Transferência"
                    name="visualizar-item-transferencia"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Transferência"
                    name="cadastrar-transferencia"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Transferência"
                    name="excluir-transferencia"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Orçamento"
                    name="visualizar-orcamento"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Orçamento"
                    name="cadastrar-orcamento"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Aprovar Orçamento"
                    name="aprovar-orcamento"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Concluir Orçamento"
                    name="concluir-orcamento"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Orçamento"
                    name="editar-orcamento"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Orçamento"
                    name="excluir-orcamento"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Posição de Estoque"
                    name="visualizar-posicao-estoque"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Ajuste de Estoque"
                    name="visualizar-ajuste-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Item Ajuste de Estoque"
                    name="visualizar-item-ajuste-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Ajuste de Estoque"
                    name="cadastrar-ajuste-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Ajuste de Estoque"
                    name="excluir-ajuste-estoque"
                  />
                </div>
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Tipos de Movimentação"
                    name="visualizar-tipos-movimentacao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Tipos de Movimentação"
                    name="cadastrar-tipos-movimentacao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Tipos de Movimentação"
                    name="editar-tipos-movimentacao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Tipos de Movimentação"
                    name="excluir-tipos-movimentacao"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Inventário"
                    name="visualizar-inventario"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Item Inventário"
                    name="visualizar-item-inventario"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Inventário"
                    name="cadastrar-inventario"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Inventário"
                    name="editar-inventario"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Estornar Inventário"
                    name="estornar-inventario"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Fechar Inventário"
                    name="fechar-inventario"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Inventário"
                    name="excluir-inventario"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Contagem de Estoque"
                    name="visualizar-contagem-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Item Contagem de Estoque"
                    name="visualizar-item-contagem-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Contagem de Estoque"
                    name="cadastrar-contagem-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Contagem de Estoque"
                    name="editar-contagem-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Contagem de Estoque"
                    name="excluir-contagem-estoque"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Locais de Estoque"
                    name="visualizar-locais-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Locais de Estoque"
                    name="cadastrar-locais-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Locais de Estoque"
                    name="editar-locais-estoque"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Locais de Estoque"
                    name="excluir-locais-estoque"
                  />
                </div>
              </div>
            </div>
            <div
              className="grid-section"
              style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
            >
              <h4>Vendas</h4>
              <div className="grid">
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Abrir Gaveta de Dinheiro"
                    name="abrir-gaveta-dinheiro"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Acessar a Consulta de Comissão de Vendas"
                    name="acessar-consulta-comissao-vendas"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Arquivar para Balança"
                    name="arquivar-balanca"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Autorizar para Balança"
                    name="autorizar-balanca"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Autorizar Vendas sem Limite de Crédito"
                    name="autorizar-vendas-sem-limite-credito"
                  />
                </div>
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Abrir Venda"
                    name="abrir-venda"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Alterar as Quantidades de um Item da Venda"
                    name="alterar-quantidade-item-venda"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Autorizar Desconto na Venda"
                    name="autorizar-desconto-venda"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Moeda Estrangeira"
                    name="cadastrar-moeda-estrangeira"
                  />
                </div>
              </div>
            </div>

            <div
              className="grid-section"
              style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
            >
              <h4>Financeiro</h4>
              <div className="grid">
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Transações Financeiras"
                    name="visualizar-transacao-financeira"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Transações Financeiras"
                    name="cadastrar-transacao-financeira"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Item Transações Financeiras"
                    name="visualizar-item-transacao-financeira"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Lançar Transações Financeiras"
                    name="lancar-transacao-financeira"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Estornar Transações Financeiras"
                    name="estornar-transacao-financeira"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Transações Financeiras"
                    name="excluir-transacao-financeira"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Extrato Bancário"
                    name="visualizar-extrato-bancario"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Movimentação Bancário"
                    name="visualizar-movimentação-bancaria"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Imprimir Recibo"
                    name="imprimir-recibo"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Categorias de Transação"
                    name="visualizar-categoria-financeira"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Categorias de Transação"
                    name="cadastrar-categoria-financeira"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Categorias de Transação"
                    name="editar-categoria-financeira"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Categorias de Transação"
                    name="excluir-categoria-financeira"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Formas de Recebimento ERP"
                    name="visualizar-formas-recebiemnto-erp"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Formas de Recebimento ERP"
                    name="cadastrar-formas-recebiemnto-erp"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Formas de Recebimento ERP"
                    name="editar-formas-recebiemnto-erp"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Formas de Recebimento ERP"
                    name="excluir-formas-recebiemnto-erp"
                  />
                </div>
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Formas de Recebimento PDV"
                    name="visualizar-formas-recebiemnto-pdv"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Formas de Recebimento PDV"
                    name="cadastrar-formas-recebiemnto-pdv"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Formas de Recebimento PDV"
                    name="editar-formas-recebiemnto-pdv"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Formas de Recebimento PDV"
                    name="excluir-formas-recebiemnto-pdv"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Formas de Pagamento"
                    name="visualizar-formas-pagamento"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Formas de Pagamento"
                    name="cadastrar-formas-pagamento"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Formas de Pagamento"
                    name="editar-formas-pagamento"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Formas de Pagamento"
                    name="excluir-formas-pagamento"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Contas Bancárias"
                    name="visualizar-contas-bancarias"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Contas Bancárias"
                    name="cadastrar-contas-bancarias"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Contas Bancárias"
                    name="editar-contas-bancarias"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Contas Bancárias"
                    name="excluir-contas-bancarias"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Plano de Contas"
                    name="visualizar-plano-contas"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Plano de Contas"
                    name="cadastrar-plano-contas"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Plano de Contas"
                    name="editar-plano-contas"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Plano de Contas"
                    name="excluir-plano-contas"
                  />
                </div>
              </div>
            </div>
            <div
              className="grid-section"
              style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
            >
              <h4>Configurações</h4>
              <div className="grid">
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Loja"
                    name="visualizar-loja"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Loja"
                    name="editar-loja"
                  />

                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Usuários"
                    name="visualizar-usuarios"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Usuários"
                    name="cadastrar-usuarios"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Usuários"
                    name="editar-usuarios"
                  />
                </div>
                <div>
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Usuários"
                    name="excluir-usuarios"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Visualizar Perfil de Acesso"
                    name="visualizar-perfil-acesso"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Cadastrar Perfil de Acesso"
                    name="cadastrar-perfil-acesso"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Editar Perfil de Acesso"
                    name="editar-perfil-acesso"
                  />
                  <CheckboxHookLeft
                    register={register}
                    errors={inputError}
                    label="Excluir Perfil de Acesso"
                    name="excluir-perfil-acesso"
                  />
                </div>
              </div>
            </div>
          </div>
        </S.Section>
      </LayoutHookForm>
    </>
  );
}
