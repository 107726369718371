import { notifyError } from "../Components/notify/notify-component";
import globals from "../Globals.json";
import api from "./api";

export const getTransfers = () => {
  return api.get(`${globals.api.transfersStocks}`);
};

export const showTransfer = (uuid: string) => {
  return api.get(`${globals.api.transfers}/${uuid}`);
};

export const createTransfer = (objTransfer: any) => {
  if (objTransfer?.uuid) {
    const oModel = {
      uuid: objTransfer?.uuid,
      store_id: objTransfer?.store_id,
      stock_transfer_type_id: objTransfer?.stock_transfer_type_id,
      store_destination_id: objTransfer?.store_destination_id,
      stock_destination_id: objTransfer?.stock_destination_id,
      note: objTransfer?.note,
      products: objTransfer?.products,
    };
    return api
      .put(`${globals?.api?.transfers}/${objTransfer?.uuid}/chargeback`, oModel)
      .then((res: any) => {
        if (res?.status === 201 || res?.status === 200) {
          const objCallback = {
            data: res?.data?.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
      });
  } else {
    const oModel = {
      store_id: objTransfer?.store_id,
      stock_transfer_type_id: objTransfer?.stock_transfer_type_id,
      store_destination_id: objTransfer?.store_destination_id,
      stock_destination_id: objTransfer?.stock_destination_id,
      note: objTransfer?.note,
      products: objTransfer?.products,
    };
    return api
      .post(
        `${globals?.api?.stocks}/${objTransfer?.stock_uuid}/transfers`,
        oModel
      )
      .then((res: any) => {
        if (res?.status === 201 || res?.status === 200) {
          const objCallback = {
            data: res?.data?.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
        notifyError(error);
      });
  }
};
