import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";
import { getFirstDataFinancial } from "../utils";

export const getfinancialTransactionAsync = createAsyncThunk(
  "get/financial-transaction",
  async (store_id:number, thunkAPI) => {
    try {
     
      const res = await api.get(
        globals.api.financialTransaction + `?paginate=false&store_id=${store_id}`
      );
      return sortByCreated(res?.data?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getChartAccountAsync = createAsyncThunk(
  "get/chart-account",
  async (store_id:number, thunkAPI) => {
    try {
      const res = await api.get(globals.api.chartAccounts + `?paginate=false&store_id=${store_id}`);
      return sortByCreated(res?.data?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getReceiverAsync = createAsyncThunk(
  "get/receiver",
  async (store_id:number, thunkAPI) => {
    try {
      const res = await api.get(globals.api.people + `?paginate=false&store_id=${store_id}`);
      return sortByCreated(res?.data?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createfinancialLauchTransactionAsync = createAsyncThunk(
  "create/financial-transaction-lauch",
  async (data: any, thunkAPI) => {
    try {
      await api.post(
        `${globals.api.financialTransaction}/${data.uuid}/entry`,
        data.data
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const chargebackFinancialLauchTransactionAsync = createAsyncThunk(
  "chargeback/financial-transaction-lauch",
  async (uuid: string, thunkAPI) => {
    try {
      await api.post(`${globals.api.financialTransaction}/${uuid}/chargeback`);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deletefinancialTransactionAsync = createAsyncThunk(
  "delete/financial-transaction",
  async (uuid: string, thunkAPI) => {
    try {
      await api.delete(globals.api.financialTransaction + "/" + uuid);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBanksAsync = createAsyncThunk(
  "get/banks",
  async (store_id:number, thunkAPI) => {
    try {
      const res = await api.get(globals.api.bankAccounts + `?paginate=false&store_id=${store_id}`);
      return sortByCreated(res?.data?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCategoriesAsync = createAsyncThunk(
  "get/categories",
  async (store_id:number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.transactionCategories + `?paginate=false&store_id=${store_id}`
      );

      return sortByCreated(res?.data?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
