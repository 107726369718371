import { Col, Row } from "react-bootstrap";
import { SelectHook } from "../../../../reactHookForm/SelectHook";
import { InputHook } from "../../../../reactHookForm/InputHook";
import { objectStatus } from "../../../../../utils/objectSelect";
import Format from "../../../../../Services/Format";
import DateUtils from "../../../../../Services/DateUtils";
import { useAppSelector } from "../../../../../redux/store";
import { IStore } from "../../../../../redux/features/userMe/interface";
import * as S from "../styled";

interface IProps {
  register: any;
  control: any;
  watch: any;
  setValue: any;
  setError: any;
  errors: any;
  reset: any;
  openFilter: boolean;
}

export function InputFilter({
  register,
  control,
  watch,
  setValue,
  setError,
  reset,
  openFilter,
}: IProps) {
  const periodoState = watch("periodo");
  const count = useAppSelector(
    ({ countReducer }) => countReducer.value
  );

  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  return (
    <S.Wrapper>
      {openFilter && (
        <div className="min-screen">
          <SelectHook
            register={register}
            control={control}
            label="Loja"
            defaultValue={[]}
            name="store"
            placeholder="Loja"
            options={
              storeMe &&
              storeMe?.user?.relationships?.stores?.map((item: IStore) => ({
                value: item.id,
                label: item.trading_name || item.company_name,
              }))
            }
          />

          <SelectHook
            register={register}
            control={control}
            label="Status"
            defaultValue={objectStatus[0]}
            name="status"
            placeholder="Status"
            options={count.status}
          />

          <SelectHook
            register={register}
            control={control}
            label="Período"
            defaultValue={count.period[0]}
            name="periodo"
            placeholder="Período"
            options={count.period}
            isClearable={false}
          />

          <InputHook
            register={register}
            label="De"
            name="inicialData"
            placeholder="Filtrar por data inicial"
            type="date"
            disabled={periodoState?.value === 1 || !periodoState ? true : false}
            defaultValue={Format.firstDate("YYYY-MM-DD")}
          />

          <InputHook
            register={register}
            label="Até"
            name="finalData"
            placeholder="Filtrar por data final"
            type="date"
            disabled={periodoState?.value === 1 || !periodoState ? true : false}
            defaultValue={Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")}
          />
        </div>
      )}

      <div className="max-screen">
        <SelectHook
          register={register}
          control={control}
          label="Loja"
          defaultValue={[]}
          name="store"
          options={
            storeMe &&
            storeMe?.user?.relationships?.stores?.map((item: IStore) => ({
              value: item.id,
              label: item.trading_name || item.company_name,
            }))
          }
        />

        <SelectHook
          register={register}
          control={control}
          label="Status"
          defaultValue={objectStatus[0]}
          name="status"
          options={count.status}
        />

        <div></div>
        <SelectHook
          register={register}
          control={control}
          label="Período"
          defaultValue={count.period[0]}
          name="periodo"
          options={count.period}
          isClearable={false}
        />

        <InputHook
          register={register}
          label="De"
          name="inicialData"
          type="date"
          disabled={periodoState?.value === 1 || !periodoState ? true : false}
          defaultValue={Format.firstDate("YYYY-MM-DD")}
        />

        <InputHook
          register={register}
          label="Até"
          name="finalData"
          type="date"
          disabled={periodoState?.value === 1 || !periodoState ? true : false}
          defaultValue={Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")}
        />
      </div>
    </S.Wrapper>
  );
}
