import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import LayoutNotFilter from "../../../data/LayoutNotFilter";
import { Tab, Tabs } from "react-bootstrap";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import Loading from "../../../Loading/Loading";
import { ToastContainer } from "react-toastify";
import RevenueModal from "./revenueModal";
import { useDispatch } from "react-redux";
import { getChartAccountAsync } from "../../../../redux/features/chartAccount/services";
import ExpenseModal from "./expenseModal";

export default function ConsultaPlanoContas(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { keyTab, loading } = useAppSelector(
    ({ chartAccountReducer }) => chartAccountReducer.value
  );

  const [key, setKey] = useState(keyTab ? keyTab : "revenue");
  const [keyActived, setKeyActived] = useState("");

  useEffect(() => {
    dispatch(getChartAccountAsync(storeMe?.storeActive?.id));
  }, [dispatch]);

  return (
    <>
      <LayoutNotFilter title={props.Title}>
        <Tabs
          defaultActiveKey="personData"
          className="mt-2"
          activeKey={key}
          onSelect={(k: any) => setKey(k)}
        >
          <Tab eventKey="revenue" title="Receitas" className="mx-3">
            <RevenueModal setKeyActived={setKeyActived} />
          </Tab>
          <Tab eventKey="expense" title="Despesas" className="mx-3">
            <ExpenseModal setKeyActived={setKeyActived} />
          </Tab>
        </Tabs>
      </LayoutNotFilter>
      {loading && <Loading />}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
