import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import api from "../../../../Services/api";
import Loading from "../../../Loading/Loading";
import Globals from "../../../../Globals.json";
import { ToastContainer } from "react-toastify";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { sortByCreated } from "../../../../Services/sortByCreated";
import * as S from "./styles";
import LayoutNotFilter from "../../../data/LayoutNotFilter";
import CardStore from "../CardStore";
import { IListStore } from "../../../../Pages/Cadastro/CadStore";
import { IStore } from "../../interface/store";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import {
  getCstsAsync,
  getStateAsync,
  getStoreAsync,
} from "../../../../redux/features/store/services";
import { NoData } from "../../../noData";
import CustomLoader from "../../../data/customLoader";
import { getStoreMeAsync } from "../../../../redux/features/userMe/services";

interface IFormInput {}

export function ListStore({ onEditItem }: IListStore) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const store = useAppSelector(({ storeReducer }) => storeReducer.value);

  const dispatch = useDispatch<AppDispatch>();

  const handleEdit = (row: IStore) => {
    onEditItem(row);
  };

  useEffect(() => {
    dispatch(getStoreMeAsync());
    dispatch(getStateAsync());
    dispatch(getCstsAsync());
  }, [dispatch]);


  

  return (
    <S.Section>
      <LayoutNotFilter
        title={store.stores.length > 1 ? "Suas Lojas" : "Sua loja"}
      >
        <Row>
          <Col>
            <div className="contractStores">Lojas Contradas: 1/1</div>
          </Col>
        </Row>

        {store.loading ? (
          <CustomLoader />
        ) : (
          <div className="card-store">
            {storeMe.user.relationships.stores.map((item: any) => (
              <CardStore key={item.id} store={item} handleEdit={handleEdit} />
            ))}
          </div>
        )}
        {storeMe.user.relationships.stores.length === 0 && <NoData />}
      </LayoutNotFilter>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </S.Section>
  );
}
