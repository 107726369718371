import { Button,Modal, } from "react-bootstrap";
import { Dispatch, SetStateAction, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../../redux/store";

import { notify, notifyError } from "../../../../notify/notify-component";
import { InputHook } from "../../../../reactHookForm/InputHook";
import { CheckboxHook } from "../../../../reactHookForm/inputCheckbox";
import { ILocalStock } from "../../../../../redux/features/localStock/interface";
import {
  createLocalStockAsync,
  getLocalStockAsync,
  updateLocalStockAsync,
} from "../../../../../redux/features/localStock/services";
import { localStockSchema } from "../../../../../redux/features/localStock/schema";
import * as S from "../../../styled";

interface IProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  listEditeItem: ILocalStock | undefined;
  setListEditeItem: Dispatch<SetStateAction<ILocalStock | undefined>>;
}


interface IInput {
  name: string;

  is_active: boolean;
  is_negative_allowed: boolean;
}

export default function ModalCad({
  show,
  setShow,
  listEditeItem,
  setListEditeItem,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();


  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(localStockSchema),
  });

  const handleClose = () => {
    setShow(false);
    setListEditeItem(undefined);
    resetItens();
  };

  const onSubmit: SubmitHandler<IInput> = async (data: IInput) => {
    const newData = {
      store_id: storeMe?.storeActive?.id,
      ...data,
      is_active: true,
    };

    if (listEditeItem) {
      const newDataUpdate = {
        ...listEditeItem,
        ...newData,
        is_active: data.is_active,
      };
      dispatch(updateLocalStockAsync(newDataUpdate)).then((result: any) => {
        if (updateLocalStockAsync.fulfilled.match(result)) {
          notify("O item foi atualizado.");
          dispatch(getLocalStockAsync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao atualizar item.");
        }
      });
      resetItens();
      setShow(false);
      return;
    }

    dispatch(createLocalStockAsync(newData)).then((result: any) => {
      if (createLocalStockAsync.fulfilled.match(result)) {
        notify("O item foi adicionado.");
        dispatch(getLocalStockAsync(storeMe?.storeActive?.id));
      } else {
        notifyError("Erro ao adicionar item.");
      }
    });
    resetItens();
    setShow(false);
  };

  const inputError = [errors];

  const resetItens = () => {
    setValue("name", "");
    setValue("is_active", "");
    setValue("is_negative_allowed", "");
    reset({});
  };

  useEffect(() => {
    if (listEditeItem && show) {
      setValue("name", listEditeItem?.name);
      setValue("is_active", listEditeItem?.is_active);
      setValue("is_negative_allowed", listEditeItem.is_negative_allowed);
    } else {
      resetItens();
      setValue("is_active", false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ fontSize: "12px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{listEditeItem ? "Editar " : "Adicionar "} Local de Estoque</h3>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <S.Wrapper>
            <InputHook
              register={register}
              errors={inputError}
              label="Nome"
              name="name"
              placeholder="Informe o Nome"
              type="text"
            />

            <CheckboxHook
              register={register}
              errors={inputError}
              label="Estoque Negativo"
              name="is_negative_allowed"
            />

            <CheckboxHook
              register={register}
              errors={inputError}
              label="Ativo"
              name="is_active"
            />
          </S.Wrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" type="submit">
            {listEditeItem ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
