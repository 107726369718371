import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const getCfopAsync = createAsyncThunk(
  "get/user-system",
  async (activedStoreId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.cfops + `?paginate=false&store_id=${activedStoreId}`
      );
          
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCfopAsync = createAsyncThunk(
  "create/user-system",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.cfops, data);
      return res.data.data 
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editeCfopAsync = createAsyncThunk(
  "put/user-system",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.cfops, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCfopAsync = createAsyncThunk(
  "del/user-system",
  async (id: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.cfops + `/${id}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

