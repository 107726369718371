import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { sortByCreated } from "../../../Services/sortByCreated";
import { IUserAccess } from "./interface";
import { getUserAccessAsync } from "./services";

interface InitialState {
  value: IUserAccessState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface IUserAccessState {
  loading: boolean;
  fetchError: string;
  stateForm: StateForm;
  role: IUserAccess[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    role: [],
    stateForm: { state: "default" },
  } as IUserAccessState,
};

export const userAccessState = createSlice({
  name: "user-access",
  initialState,
  reducers: {
    setStateForm: (state: InitialState, action) => {
      state.value.stateForm = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getUserAccessAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getUserAccessAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IUserAccess[]>) => {
        state.value.loading = false;
        state.value.role = sortByCreated(action.payload);
      }
    );
    builder.addCase(
      getUserAccessAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const { setStateForm } = userAccessState.actions;

export default userAccessState.reducer;
