import { Button, Col, Modal, Row } from "react-bootstrap";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  IObj,
  PaymentOptions,
  businessAccountObj,
  paymentsGroupObj,
} from "../typeOptionsGroup/obj";
import { pdvSchema } from "../../../../../../schemas/pdvSchema";
import { AppDispatch, useAppSelector } from "../../../../../../redux/store";
import {
  createPAymentTypePDVAsync,
  getPAymentTypePDVAsync,
  updatePAymentTypePDVAsync,
} from "../../../../../../redux/features/PaymentTypePDV/services";
import { notify, notifyError } from "../../../../../notify/notify-component";
import { InputHook } from "../../../../../reactHookForm/InputHook";
import { SelectHook } from "../../../../../reactHookForm/SelectHook";
import { CheckboxHook } from "../../../../../reactHookForm/inputCheckbox";
import {
  IBrPaymentTypes,
  IBrPaymentTypesProviders,
  IPaymentMechanisms,
} from "../../../../../../redux/features/PaymentTypePDV/interface";
import * as S from "../../../styled";

interface IProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setListPDV: any;
  listPDV: any[];
  listEditeItem: PaymentOptions | undefined;
  setListEditeItem: Dispatch<SetStateAction<PaymentOptions | undefined>>;
  setKeyActived: any;
}

type FormData = Yup.InferType<typeof pdvSchema>;

interface IInput {
  id: number;
  sort_order: number;
  description: string;
  payment_group: IObj;
  br_payment_type_id: IObj;
  br_payment_type_description: string;
  payment_mechanism_id: IObj;
  br_payment_type_provider_id: IObj;
  business_account_id: IObj;
  inactive: string;
  visible_delivery: string;
  requires_exchange: string;
  sent_to_checking_account: string;
}

export default function ModalPDV({
  show,
  setShow,
  setLoading,
  setListPDV,
  listPDV,
  listEditeItem,
  setListEditeItem,
  setKeyActived,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();
  const {
    paymentTypeDdv,
    brPaymentTypes,
    brPaymentTypesProviders,
    paymentMechanisms,
  } = useAppSelector(
    ({ paymentTypePDVReducer }) => paymentTypePDVReducer.value
  );

  //const [modal, setModal] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(pdvSchema),
  });

  const tefForDescription = watch("description");

  const objBrPaymentTypes: any = brPaymentTypes.map(
    (item: IBrPaymentTypes) => ({
      label: item.descricao,
      value: item.id,
    })
  );

  const objPaymentMechanisms: any = paymentMechanisms.map(
    (item: IPaymentMechanisms) => ({
      label: item.descricao,
      value: item.id,
    })
  );

  const objBrPaymentTypesProviders: any = brPaymentTypesProviders.map(
    (item: IBrPaymentTypesProviders) => ({
      label: item.descricao,
      value: item.id,
    })
  );

  const handleClose = () => {
    setShow(false);
    setListEditeItem(undefined);
    resetItens();
  };

  const onSubmit: SubmitHandler<IInput> = async (data: IInput) => {
    setLoading(true);
    const newData = {
      ...data,
      store_id: storeMe?.storeActive?.id,
      br_payment_type_id: data.br_payment_type_id.value?.toString(),
      br_payment_type_provider_id:
        data.br_payment_type_provider_id.value?.toString(),
      business_account_id: data.business_account_id.value,
      payment_group: data.payment_group.value,
      payment_mechanism_id: data.payment_mechanism_id.value?.toString(),
      requires_exchange: data.requires_exchange ? true : false,
      sent_to_checking_account: data.sent_to_checking_account ? true : false,
      visible_delivery: data.visible_delivery ? true : false,
      inactive: false,
      currency_id: "1", //Campo padrão
      cashier_account_id: "0", //campo padrão
      issue_direct_debit: "0", //campo padrão
      generate_mb_reference: "0", //campo padrão
    };

    if (listEditeItem) {
      const newDataUpdate = {
        ...listEditeItem,
        ...newData,
        id: Number(data.id),
        inactive: data.inactive ? true : false,
      };
      dispatch(updatePAymentTypePDVAsync(newDataUpdate)).then((result: any) => {
        if (updatePAymentTypePDVAsync.fulfilled.match(result)) {
          dispatch(getPAymentTypePDVAsync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao atualizar item do PDV.");
        }
      });
      resetItens();
      setShow(false);
      setLoading(false);
      setKeyActived("pdv");
      return;
    }

    dispatch(createPAymentTypePDVAsync(newData)).then((result: any) => {
      if (createPAymentTypePDVAsync.fulfilled.match(result)) {
        dispatch(getPAymentTypePDVAsync(storeMe?.storeActive?.id));
      } else {
        notifyError("Erro ao adicionar item ao PDV.");
      }
    });

    setLoading(false);
    resetItens();
    setShow(false);
    setKeyActived("pdv");
  };

  const inputError = [errors];

  const resetItens = () => {
    setValue("sort_order", "1");
    setValue("description", "");
    setValue("br_payment_type_id", "");
    setValue("br_payment_type_description", "");
    setValue("payment_group", "");
    setValue("payment_mechanism_id", "");
    setValue("br_payment_type_provider_id", "");
    setValue("business_account_id", "");
    setValue("inactive", "");
    setValue("visible_delivery", "");
    setValue("requires_exchange", "");
    setValue("sent_to_checking_account", "");

    reset({});
  };

  useEffect(() => {
    if (listEditeItem && show) {
      setValue("id", listEditeItem?.id);
      setValue("sort_order", listEditeItem?.sort_order);
      setValue("description", listEditeItem?.description);
      setValue(
        "br_payment_type_id",
        objBrPaymentTypes.find(
          (item: any) => item.value === listEditeItem?.br_payment_type_id
        )
      );
      setValue(
        "br_payment_type_description",
        listEditeItem?.br_payment_type_description
      );
      setValue(
        "payment_group",
        paymentsGroupObj.find(
          (item: IObj) =>
            item.value === listEditeItem?.payment_group?.toString()
        )
      );
      setValue(
        "payment_mechanism_id",
        objPaymentMechanisms.find(
          (item: IObj) => item.value === listEditeItem?.payment_mechanism_id
        )
      );
      setValue(
        "br_payment_type_provider_id",
        objBrPaymentTypesProviders.find(
          (item: any) =>
            item.value === listEditeItem?.br_payment_type_provider_id
        )
      );
      setValue(
        "business_account_id",
        businessAccountObj.find(
          (item: IObj) =>
            item.value === listEditeItem?.business_account_id?.toString()
        )
      );
      setValue("inactive", listEditeItem?.inactive);
      setValue("visible_delivery", listEditeItem?.visible_delivery);
      setValue("requires_exchange", listEditeItem?.requires_exchange);
      setValue(
        "sent_to_checking_account",
        listEditeItem?.sent_to_checking_account
      );
    } else {
      setValue(
        "id",
        paymentTypeDdv?.length > 0
          ? paymentTypeDdv[paymentTypeDdv?.length - 1]?.id + 1
          : 1
      );
      setValue("sort_order", paymentTypeDdv?.length + 1);
      setValue("description", "");
      setValue("payment_group", paymentsGroupObj[0]);
      setValue("br_payment_type_id", objBrPaymentTypes[0]);
      setValue("br_payment_type_description", "");
      setValue("payment_mechanism_id", objPaymentMechanisms[0]);
      setValue("br_payment_type_provider_id", objBrPaymentTypesProviders[0]);
      setValue("business_account_id", businessAccountObj[0]);
      setValue("inactive", false);
      setValue("visible_delivery", true);
      setValue("requires_exchange", "");
      setValue("sent_to_checking_account", "");
    }
  }, [show]);

  useEffect(() => {
    setValue("br_payment_type_description", tefForDescription);
  }, [tefForDescription]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ fontSize: "12px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>
            {listEditeItem ? "Editar " : "Adicionar "} Forma de Recebimento
          </h3>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <S.Wrapper>
            <InputHook
              register={register}
              errors={inputError}
              label="Código"
              name="id"
              placeholder="Código"
              type="number"
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Ordem"
              name="sort_order"
              placeholder="Ordem"
              type="number"
            />

            <SelectHook
              register={register}
              control={control}
              errors={inputError}
              label="TEF Pagamento"
              name="br_payment_type_id"
              placeholder="TEF - Tipo de Pagamento"
              options={objBrPaymentTypes}
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Descrição"
              name="description"
              placeholder="Descrição"
              type="text"
            />

            <SelectHook
              register={register}
              control={control}
              errors={inputError}
              label="TEF Operadora"
              name="br_payment_type_provider_id"
              placeholder="TEF - Operadora Credenciada"
              options={objBrPaymentTypesProviders}
            />

            <SelectHook
              register={register}
              control={control}
              errors={inputError}
              label="Grupo"
              name="payment_group"
              placeholder="Grupo de Pagamento"
              options={paymentsGroupObj}
            />

            <SelectHook
              register={register}
              control={control}
              errors={inputError}
              label="Moeda"
              name="business_account_id"
              placeholder="Moeda"
              options={businessAccountObj}
            />

            <SelectHook
              register={register}
              control={control}
              errors={inputError}
              label="Pagamento"
              name="payment_mechanism_id"
              placeholder="Tipo de pagamento"
              options={objPaymentMechanisms}
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Des. TEF"
              name="br_payment_type_description"
              placeholder="Descrição TEF"
              type="text"
            />
          </S.Wrapper>
          <div
            style={{ borderTop: "1px solid #DEE2E6", margin: "1.5rem 0 1rem" }}
          />
          <Row className="mt-4 mx-1 mb-3">
            <h4>Opções XD - Maque para enviar ao PDV</h4>
          </Row>
          {listEditeItem && (
            <Row>
              <Col md="7">
                <CheckboxHook
                  register={register}
                  errors={inputError}
                  label={"Inativo"}
                  name="inactive"
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col md="7">
              <CheckboxHook
                register={register}
                errors={inputError}
                label="Visivel no Delivery"
                name="visible_delivery"
              />
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <CheckboxHook
                register={register}
                errors={inputError}
                label="Requer Troco"
                name="requires_exchange"
              />
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <CheckboxHook
                register={register}
                errors={inputError}
                label="Envia para conta assinada"
                name="sent_to_checking_account"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" type="submit">
            {listEditeItem ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
