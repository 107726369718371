import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISelect, objStatus, objType, objectPeriod } from "./services/object";
import {
  getAllProductsAsync,
  getCategoriesAsync,
  getCountAsync,
  getCountByUuidAsync,
  getProductsAsync,
  getProductsByCategorieAsync,
  getStockAsync,
  getStoresAsync,
} from "./services";
import { ICategorie, IProduct, IStock, IStore, ICount } from "./interface";
import { findMostRecentItem, getType } from "./utils";

interface InitialState {
  value: InventaryState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface InventaryState {
  loading: boolean;
  fetchError: string;
  contagem: ICount[];
  stores: IStore[];
  stock: IStock[];
  categories: ICategorie[];
  allProducts: IProduct[];
  allProductsTable: IProduct[];
  products: IProduct[];
  inventaryType: ISelect[];
  period: ISelect[];
  status: ISelect[];
  typeInventary: number;
  InventaryByUuid: ICount | undefined;
  includeInactiveProducts: boolean;
  itensTable: IProduct[];
  lastUuidCanChargeBack: string;
  stateForm: StateForm;
  contagemSelected: ICount | undefined;
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    contagem: [],
    stores: [],
    stock: [],
    categories: [],
    products: [],
    allProducts: [],
    allProductsTable: [],
    inventaryType: objType,
    period: objectPeriod,
    status: objStatus,
    typeInventary: 1,
    InventaryByUuid: undefined,
    includeInactiveProducts: false,
    contagemSelected: undefined,
    itensTable: [],
    lastUuidCanChargeBack: "",
    stateForm: { state: "default" },
  } as InventaryState,
};

export const count = createSlice({
  name: "count",
  initialState,
  reducers: {
    setTypeInventary: (state: InitialState, action) => {
      state.value.typeInventary = action.payload;
    },
    setIncludeInactiveProducts: (state) => {
      state.value.includeInactiveProducts =
        !state.value.includeInactiveProducts;
    },
    setContagemSelected: (state: InitialState, action) => {
      state.value.contagemSelected = action.payload;
    },
    setIncludeInactiveProductsFalse: (state) => {
      state.value.includeInactiveProducts = false;
    },
    addItensTable: (state: InitialState, action) => {
      state.value.itensTable.push(action.payload);
    },
    deleteItemTable: (state: InitialState, action) => {
      const removeItem = state.value.itensTable.filter(
        (item: IProduct) => item.id !== action.payload
      );
      state.value.itensTable = removeItem;
    },
    deleteAllItensTable: (state: InitialState) => {
      state.value.itensTable = [];
    },
    setStateForm: (state: InitialState, action) => {
      state.value.stateForm = action.payload;
    },
    setUuidEdit: (state: InitialState, action) => {
      state.value.InventaryByUuid = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getCountAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getCountAsync.fulfilled,
      (state: InitialState, action: PayloadAction<ICount[]>) => {
        state.value.loading = false;
        state.value.contagem = getType(action.payload, 2);
        state.value.contagem = getType(action.payload, 1);
        let findLastEntryMoviment = findMostRecentItem(state.value.contagem);

        state.value.lastUuidCanChargeBack = findLastEntryMoviment?.uuid ?? "";
      }
    );
    builder.addCase(
      getCountAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
    builder.addCase(getStoresAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getStoresAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IStore[]>) => {
        state.value.loading = false;
        state.value.stores = action.payload;
      }
    );
    builder.addCase(
      getStoresAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
    builder.addCase(getStockAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getStockAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IStock[]>) => {
        state.value.loading = false;
        state.value.stock = action.payload;
      }
    );
    builder.addCase(
      getStockAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
    builder.addCase(getCategoriesAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getCategoriesAsync.fulfilled,
      (state: InitialState, action: PayloadAction<ICategorie[]>) => {
        state.value.loading = false;
        state.value.categories = action.payload;
      }
    );
    builder.addCase(
      getCategoriesAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
    builder.addCase(getAllProductsAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getAllProductsAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IProduct[]>) => {
        state.value.loading = false;

        const allProductsActived = action.payload.filter(
          (item: IProduct) => item.is_active
        );
        state.value.allProductsTable = action.payload;
        state.value.allProducts = state.value.includeInactiveProducts
          ? action.payload
          : allProductsActived;
      }
    );
    builder.addCase(
      getAllProductsAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
    builder.addCase(getProductsAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getProductsAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IProduct[]>) => {
        state.value.loading = false;
        const productsActived = action.payload.filter(
          (item: IProduct) => item.is_active
        );
        state.value.products = state.value.includeInactiveProducts
          ? action.payload
          : productsActived;
      }
    );
    builder.addCase(
      getProductsAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
    builder.addCase(
      getProductsByCategorieAsync.pending,
      (state: InitialState) => {
        state.value.loading = true;
      }
    );
    builder.addCase(
      getProductsByCategorieAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IProduct[]>) => {
        state.value.loading = false;
        const productsByCatActived = action.payload.filter(
          (item: IProduct) => item.is_active
        );
        state.value.products = state.value.includeInactiveProducts
          ? action.payload
          : productsByCatActived;
      }
    );
    builder.addCase(
      getProductsByCategorieAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
    builder.addCase(getCountByUuidAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getCountByUuidAsync.fulfilled,
      (state: InitialState, action: PayloadAction<ICount>) => {
        state.value.loading = false;
        state.value.InventaryByUuid = action.payload;
      }
    );
    builder.addCase(
      getCountByUuidAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      }
    );
  },
});

export const {
  setTypeInventary,
  setIncludeInactiveProducts,
  setIncludeInactiveProductsFalse,
  addItensTable,
  deleteItemTable,
  deleteAllItensTable,
  setStateForm,
  setUuidEdit,
  setContagemSelected,
} = count.actions;

export default count.reducer;
