import { RefObject, useEffect, useState, useRef } from "react";
import "./styles.css";
import ImageDefault from "../images/backgroud-image-product-2.jpg";
import { FaRegTrashAlt } from "react-icons/fa";
import Button from "react-bootstrap/Button";

interface IImage {
  valid: boolean;
  type: string;
  size: number;
  content: string;
}

interface IDragDropImageProps {
  onGetImage: Function;
  src: string;
}

export default function DragDropImage(props: IDragDropImageProps) {
  const [errorMessage, setErrorMessage] = useState<String>("");

  const inputFile: RefObject<HTMLInputElement> = useRef<any>(null);
  const [selectedImage, setSelectedImage] = useState<IImage>();

  const dragOver = (e: any) => {
    e.preventDefault();
  };

  const dragEnter = (e: any) => {
    e.preventDefault();
  };

  const dragLeave = (e: any) => {
    e.preventDefault();
  };

  const openFile = () => {
    inputFile.current && inputFile.current.click();
  };

  const fileDrop = async (e: any) => {
    e.preventDefault();
    //const files = e.dataTransfer.files;
    await goGetImage(e.dataTransfer.files[0]);
    // const file = await handleImage(files[0]);

    // if (!file.valid) {
    //   setErrorMessage("Formato inválido!");
    //   setSelectedImage(undefined);
    //   return;
    // }

    // if (!validSize(file.size)) {
    //   const convertsize = fileSize(file.size);
    //   setSelectedImage(undefined);
    //   setErrorMessage(
    //     `Imagem não poder ser maior que 2MB a imagem selecionada tem ${convertsize}`
    //   );
    //   return;
    // }
    // setErrorMessage("");
    // setSelectedImage(file);
    // props.onGetImage(file.content);
  };

  const goGetImage = async (image: any) => {
    const file = await handleImage(image);

    if (!file.valid) {
      setErrorMessage("Formato inválido!");
      setSelectedImage(undefined);
      return;
    }

    if (!validSize(file.size)) {
      const convertsize = fileSize(file.size);
      setSelectedImage(undefined);
      setErrorMessage(
        `Imagem não poder ser maior que 1MB a imagem selecionada tem ${convertsize}`
      );
      return;
    }
    setErrorMessage("");
    setSelectedImage(file);
    props.onGetImage(file.content);
  };

  const handleChange = async (e: any) => {
    e.preventDefault();
    await goGetImage(e.target.files[0]);
  };

  const fileSize = (size: number) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const validSize = (size: number): boolean => {
    const validSize = 1048576;
    return validSize > size;
  };

  const validateFile = (file: any) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/x-icon",
    ];
    if (validTypes.indexOf(file) === -1) {
      return false;
    }
    return true;
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer): any => {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  const handleDeleteImage = () => {
    setSelectedImage(undefined);
    props.onGetImage("");
  };
  const handleImage = async (file: any): Promise<any> => {
    let img: IImage = {
      valid: false,
      type: "",
      size: 0,
      content: "",
    };

    //
    const filereader = new FileReader();
    if (file !== undefined) {
      if (validateFile(file.type)) {
        filereader.readAsArrayBuffer(file);
        const result = new Promise((resolve, reject) => {
          filereader.onloadend = function (e) {
            resolve(filereader.result);
          };
        });

        await result.then((res: any) => {
          img = {
            valid: true,
            type: file.type,
            size: file.size,
            content: `data:${file.type};base64,${arrayBufferToBase64(res)}`,
          };
        });
      }
    }
    return img;
  };

  return (
    <>
      <div className="imageContainer">
        <div className="imageHeader">
          {(props.src || selectedImage?.valid) && (
            <Button
              onClick={handleDeleteImage}
              variant="contained"
              color="primary"
              className="btnDeleteImg"
            >
              <FaRegTrashAlt size={14} color="8e8d8d" />
            </Button>
          )}
          <label>Imagem Max (1Mb)</label>
        </div>
        <div
          className="dragImg"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={openFile}
        >
          {props.src || selectedImage?.valid ? (
            <div className="imageSelect">
              <img
                className="selectedImag"
                src={props.src ?? selectedImage?.content}
                alt="Imagem selecionada"
              />
            </div>
          ) : (
            <img src={ImageDefault} alt="Imagem padrão" />
          )}
          <input
            type="file"
            id="file"
            defaultValue={undefined}
            onChange={handleChange}
            ref={inputFile}
            accept="image/*"
            style={{ display: "none" }}
          />
        </div>
        <label id="error">{errorMessage}</label>
      </div>
    </>
  );
}
