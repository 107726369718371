import styled from "styled-components";
import BannerImg from "../../Components/images/bluebg.jpeg";
//<img src="img/fundo_app.png" alt="" />

export const Container = styled.div`
  overflow-x: hidden;
  display: grid;
  color: #555555;
  height: 100vh;
  background-color: #d8d8d8;
  position: relative;

  .imgC {
    height: 100vh;
    background-image: url(${BannerImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
      margin-bottom: 20rem;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      gap: 15px;

      img {
        height: auto;
        width: 350px;
        object-fit: cover;
      }

      h1 {
        font-size: 20px;
        padding: 0 1rem;
        text-align: center;
      }

      h2 {
        font-size: 16px;
        padding: 0 1rem;
        text-align: center;
      }

      .footer {
        position: absolute;
        bottom: -25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 80px;
          height: auto;
        }
      }

      @media screen and (max-width: 1200px) {
        img {
          height: auto;
          width: 340px;
          object-fit: contain;
        }

        h1 {
          font-size: 18px;
          padding: 0 1rem;
          text-align: center;
        }

        h2 {
          font-size: 14px;
          padding: 0 1rem;
          text-align: center;
        }
      }

      @media screen and (max-width: 1060px) {
        img {
          height: auto;
          width: 330px;
          object-fit: contain;
        }

        h1 {
          font-size: 12px !important;
          padding: 0 1rem;
          text-align: center;
        }

        h2 {
          font-size: 12px !important;
          padding: 0 1rem;
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .imgC {
      display: none;
    }
  }
  @media screen and (max-width: 1070px) {
    display: flex;
  }
`;

export const Login = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  height: 500px;
  width: 430px;
  box-shadow: 0 1px 28px #00000014;
  background-color: #fff;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  padding: 30px 35px;
  margin: 0 3rem;
  justify-content: center;

  h2 {
    margin-bottom: 3rem;
  }

  p {
    margin-top: 1rem;
    font-size: 14px;
  }
  .error {
    color: var(--error);
    font-size: 11px;
  }

  .success {
    color: var(--success);
    font-size: 13px;
  }

  .imgDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 15px 0;
    img {
      object-fit: cover;
      width: 200px;
      height: auto;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin-bottom: 1rem;
    font-size: 16px;
    .Entrar {
      font-weight: bolder;
      font-size: 17px;
    }
  }

  @keyframes show {
    0% {
      transform: scale(1.4);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  input {
    width: 100%;
    padding: 9px 9px;
  }

  button {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-radius: 5px;
    background-color: var(--blue-base);
    color: #ffff;
    width: 100%;
    border: none;
    height: 35px;
    font-weight: bold !important;
    font-size: 16px !important;
    cursor: pointer;

    &:hover {
      transition: all 0.5s;
      background-color: var(--blue-20);
    }
  }

  .forgot {
    font-weight: bold;
    font-size: 14px;
    margin-top: 1rem;

    span {
      color: var(--blue-base);
      padding: 0 10px;

      :hover {
        color: var(--blue-20);
        cursor: pointer;
      }
    }
  }
`;

export const Button = styled.button`
  margin-top: 2rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1266f1;
  color: #f5f8fa;
  width: 100%;
  border: none;
  height: 35px;
  font-weight: bold;
  cursor: pointer;

  span {
    font-size: 14px;
  }

  &:hover {
    transition: all 0.5s;
    background-color: #0f57cd;
  }
`;

export const PositionSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`;
