import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";
import { ICategorie, IInventary, IProduct, IStock } from "../interface";

export const createInventaryAsync = createAsyncThunk(
  "create/inventary",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.inventary, data);

      if (res.data.data.uuid) {
        await api.post(
          globals.api.inventary + `/${res.data.data.uuid}/products`,
          data.products
        );
      }

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editeInventaryAsync = createAsyncThunk(
  "put/inventary",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.inventary, data);

      if (res.data.data.uuid) {
        await api.post(
          globals.api.inventary + `/${res.data.data.uuid}/products`,
          data.products
        );
      }

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getInventaryAsync = createAsyncThunk(
  "inventary",
  async (activedStoreId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.inventary + `?paginate=false&store_id=${activedStoreId}`
      );
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getInventaryByUuidAsync = createAsyncThunk(
  "inventary/uuid",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.get(globals.api.inventary + `/${uuid}`);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStoresAsync = createAsyncThunk(
  "inventary/stores",
  async (idStore: number, thunkAPI) => {
    try {
      const res = await api.get(globals.api.stores + `?paginate=false&store_id=${idStore}`);
      
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStockAsync = createAsyncThunk(
  "inventary/stock",
  async (idStore: number, thunkAPI) => {
    try {
      const res = await api.get(globals.api.stocks + `?paginate=false&store_id=${idStore}`);


      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCategoriesAsync = createAsyncThunk(
  "inventary/categories",
  async (idStore: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.categoriesCompleted + `?filter[is_active]=true&paginate=false&store_id=${idStore}`
      );

   
      return  res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllProductsAsync = createAsyncThunk(
  "inventary/all-products",
  async (store_id:number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          `?paginate=false&filter[is_stockable]=true&append=stock_qty&store_id=${store_id}`
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProductsAsync = createAsyncThunk(
  "inventary/products",
  async (data:{ idStock: number, store_id:number}, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          `?paginate=false&filter[is_stockable]=true&append=stock_qty&store_id=${data.store_id}`
      );
      
      let filteredProduct = res.data.data.filter(
        (item: IProduct) => item?.stock_local_id === data.idStock
      );
      return  data.idStock ? filteredProduct : res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProductsByCategorieAsync = createAsyncThunk(
  "inventary/products-by-categorie",
  async ( data :{ catId:number, storeId:number}, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          `?paginate=false&filter[is_stockable]=true&append=stock_qty?paginate=false&store_id=${data.storeId}`
      );

      let filteredProduct = res.data.data.filter(
        (item: IProduct) => item?.product_category_id === data.catId
      );

      return data.catId ? filteredProduct : res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteItemProductTableAsync = createAsyncThunk(
  "inventary/delete",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.inventary + `/products/${uuid}`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addItemProductTableAsync = createAsyncThunk(
  "inventary/add",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(
        globals.api.inventary + `/${data.uuidInventary}/products`,
        [data]
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const putItemProductTableAsync = createAsyncThunk(
  "inventary/put",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(
        globals.api.inventary + `/${data.uuidInventary}/products`,
        {
          qty: data.qty,
          product_id: data.id
        }
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const chargeBackInventory = createAsyncThunk(
  "inventary/chargeback",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.post(globals.api.inventary + `/${uuid}/chargeback`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const closeInventory = createAsyncThunk(
  "inventary/close",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.post(globals.api.inventary + `/${uuid}/close`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
