import { useContext, useEffect, useState } from "react";
import "./styles.css";
import DadosGerais from "../DadosGerais";
import Precos from "../Precos";
import UnidadeOperacao from "../UnidadeOperacao";
import InfoEstoque from "../InfoEstoque";
import InfoFiscais from "../InfoFiscais";
import { Printers } from "../Impressoras";
import { InfoSales } from "../InfoVenda";
import { ToastContainer } from "react-toastify";
import {  notifyError } from "../../../notify/notify-component";
import { ProdutoContext } from "../../../../context/product";
import { CreateAndEdit } from "../../../../Services/CreateAndEdit";
import globals from "../../../../Globals.json";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import LayoutProduct from "../../../data/LayoutProduct";
import Loading from "../../../Loading/Loading";
import { Tab, Tabs } from "react-bootstrap";
import Layout from "../../../data/Layout";
import Complements from "../Complements";

export default function FormProduto(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();

  const {
    setLoading,
    currentAmount, // não possui este campo na api
    status,
    image,
    code,
    barcode,
    descriptionSale,
    descriptionPurchase,
    descriptionTouch,
    category,
    vendor,
    productType,
    sale,
    minSale,
    markup,
    markupPrice,
    coast,
    unit,
    typeOper,
    stock_uuid,
    loading,
    minAmount,
    localStock,
    unitMeasureBuy,
    multiple,
    observation,
    taxProfile,
    stateOrigin,
    stateDestiny,
    types,
    origins,
    ncm,
    cest,
    cfop,
    customer,
    icms,
    ipi,
    cstReasons,
    pis,
    cofins,
    weight,
    aliquot,
    reduction,
    icmsAliquotDeferral,
    icmsCredit,
    aliquotIpi,
    aliquotPis,
    aliquotCofins,
    mva,
    xdRequestQty,
    xdRequestPriceWhenZero,
    xdBalanceQty,
    xdOrderGrouped,
    xdBillGroup,
    xdAddTips,
    xdExcludeMinimumConsumption,
    xdBook,
    xdFollowItem,
    xdPrinters,
    cleanInputs,
    handleEdit,
    handleDuplicate,
    handleValidete,
    listCode,
    localStockName,
    selfService,
    categoriesList,
    setValidLocalStock
  } = useContext(ProdutoContext);

  const [key, setKey] = useState("info-sales");

  const handleSave = () => {
    const valid = handleValidete();

    if (valid === false) {
      notifyError("Por favor preencha todos os campos obrigatórios.");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if(!localStock) {
      notifyError("Porfavor preencha Local de estoque.");
      setValidLocalStock(true)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return
    }

    let data = {
      uuid: props.editando  && props?.produto?.uuid,
      product_type_id: productType,
      ...(storeMe?.storeActive?.id && { store_id: storeMe?.storeActive?.id }),
      ...(category && { product_category_id: category }),
      ...(vendor && { vendor_id: vendor }),
      ...(typeOper && { product_purpose_id: typeOper }),
      ...(unit && { sale_measure_unit_id: unit }),
      ...(unitMeasureBuy && { stock_measure_unit_id: unitMeasureBuy }),
      ...(taxProfile && { tax_profile_id: taxProfile }),
      ...(types && { tax_invoice_type_id: types }),
      ...(origins && { cst_origin_id: origins }),
      ...(ncm && { ncm_id: ncm }),
      ...(cfop && { cfop_id: cfop }),
      ...(cest && { cest_id: cest }),
      ...(icms && { cst_icms_id: icms }),
      ...(stateOrigin && { icms_origin_state_id: stateOrigin }),
      ...(stateDestiny && { icms_destiny_state_id: stateDestiny }),
      ...(customer && { icms_customer_type_id: customer }),
      ...(ipi && { cst_ipi_id: ipi }),
      ...(cstReasons && { cst_ipi_reason_id: cstReasons }),
      ...(pis && { cst_pis_id: pis }),
      ...(cofins && { cst_cofins_id: cofins }),
      ...(barcode && { barcode: barcode }),
      ...(descriptionSale && { sale_name: descriptionSale }),
      ...(descriptionPurchase && { purchase_name: descriptionPurchase }),
      ...(descriptionTouch && { touch_name: descriptionTouch }),
      ...(sale && { sale_price: parseFloat(sale) }),
      ...(coast && { cost_price: parseFloat(coast) }),
      ...(minSale && { sale_minimal_price: parseFloat(minSale) }),

      ...(!props.editando && stock_uuid && { stock_uuid: stock_uuid }),
      ...(!props.editando && { stock_movement_type_id: 49 }), // definindo tipo de movimentação padrão
      ...(!props.editando && { situation_id: 1 }), // difinindo Entrada de tipo de movimentação padrão

      ...(minAmount && { stock_minimal_qty: parseFloat(minAmount) }),
      stock_qty: parseFloat(currentAmount ? currentAmount : "0"),

      ...(observation && { stock_reference: observation }),
      ...(localStock && { stock_local_id: localStock }),

      //ver comportamento

      ...(multiple && { sale_measure_unit_multiplier: parseFloat(multiple) }),
      ...(weight && { tax_weight: parseFloat(weight) }),
      ...(aliquot && { icms_aliquot: parseFloat(aliquot) }),
      ...(icmsCredit && { icms_credit: parseFloat(icmsCredit) }),
      ...(mva && { icms_mva: parseFloat(mva) }),
      ...(reduction && { icms_base_calc_reduction: parseFloat(reduction) }),
      ...(icmsAliquotDeferral && {
        icms_aliquot_deferral: parseFloat(icmsAliquotDeferral),
      }),
      ...(aliquotIpi && { ipi_aliquot: parseFloat(aliquotIpi) }),
      ...(aliquotPis && { pis_aliquot: parseFloat(aliquotPis) }),
      ...(aliquotCofins && { cofins_aliquot: parseFloat(aliquotCofins) }),
      is_active: status,
      ...(markupPrice && { markup_value: parseFloat(markupPrice) }),
      ...(markup && { markup_percentil: parseFloat(markup) }),
      ...(code && code !== props?.produto?.code && { code: code }),
      ...(image && !isUrl(image) && { image: image }),

      ...(xdRequestQty && { xd_request_qty: xdRequestQty }),
      ...(xdRequestPriceWhenZero && {
        xd_request_price_when_zero: xdRequestPriceWhenZero,
      }),
      ...(xdBalanceQty && { xd_balance_qty: xdBalanceQty }),
      ...(xdOrderGrouped && { xd_order_grouped: xdOrderGrouped }),
      ...(xdBillGroup && { xd_bill_group: xdBillGroup }),
      ...(xdAddTips && { xd_add_tips: xdAddTips }),
      ...(xdExcludeMinimumConsumption && {
        xd_exclude_minimum_consumption: xdExcludeMinimumConsumption,
      }),
      ...(xdBook && { xd_book: xdBook }),
      ...(xdPrinters && { xd_printers: xdPrinters }),
      ...(xdFollowItem && { xd_follow_item: xdFollowItem }),
      ...(selfService && { self_service: selfService }),
      product_complements: completmentProduct(categoriesList),
    };

    CreateAndEdit.post(globals.api.products, data).then((res: any) => {
      setLoading(true);
      if (!res) {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      } else {
        setTimeout(() => {
          handleCancel();
          setLoading(false);
        }, 100);
      }
    });
  };

  const isUrl = (str: string) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    return urlRegex.test(str);
  };

  const completmentProduct = (list: any) => {
    const newList = [];
    for (let index = 0; index < list?.length; index++) {
      newList.push({
        product_id: list[index].id,
      });
    }
    return newList;
  };

  const handleCancel = () => {
    props.onCancelAction();
    cleanInputs();
  };

  useEffect(() => {
    props.editando && handleEdit(props.produto);
    props.duplicar && handleDuplicate(props.produto);

    setTimeout(() => {
      props.editando && handleEdit(props.produto);
      props.duplicar && handleDuplicate(props.produto);
    }, 1500);
  }, []);

  useEffect(() => {
    //dispatch(getStoreMeAsync());
  }, [dispatch]);

  return (
    <>
      <div className="mb-4">
        <LayoutProduct
          isEditable={props.editando}
          onClickSave={handleSave}
          onClickEdit={handleSave}
          onClickCancel={handleCancel}
        >
          <DadosGerais isEdit={props.editando} />

          <Precos />

          <UnidadeOperacao />

          <InfoEstoque isEdit={props.editando} isDupli={props.duplicar}/>

          <InfoFiscais isEdit={props.produto} isDupli={props.produto}/>

          <Layout title="">
            <Tabs
              defaultActiveKey="personData"
              className="mt-2"
              activeKey={key}
              onSelect={(k: any) => setKey(k)}
            >
              <Tab eventKey="info-sales" title="Informações de Venda">
                <InfoSales />
              </Tab>
              <Tab eventKey="print" title="Impressoras">
                <Printers />
              </Tab>
              <Tab eventKey="complement" title="Complemento">
                <Complements
                  isEditable={props.editando}
                  isDuplicate={props.duplicar}
                />
              </Tab>
            </Tabs>
          </Layout>
        </LayoutProduct>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />

      {loading && <Loading />}
    </>
  );
}
