import { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import { IListFichaTecnica } from "../../../../Pages/Cadastro/CadFichaTecnica";
import { IFichaTecnica } from "../interface/IFichaTecnica";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import api from "../../../../Services/api";
import globals from "./../../../../Globals.json";
import { notify, notifyError } from "../../../notify/notify-component";
import { ToastContainer } from "react-toastify";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { sortByCreated } from "../../../../Services/sortByCreated";
import LayoutTable from "../../../data/LayoutTable";
import LayoutFilter from "../../../data/LayoutFilter";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import Format from "../../../../Services/Format";
import DateUtils from "../../../../Services/DateUtils";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { DatasheetContext } from "../../../../context/data-sheet";
import FilterBy from "../../../../Services/FilterBy";
import { useAppSelector } from "../../../../redux/store";
import ModalBootstrap from "../../../data/Modal";
import CustomLoader from "../../../data/customLoader";
import * as S from "./styled";

export default function ListaFichaTecnica({
  OnAddNewCli,
  title,
  onEditItem,
  OnAddNewFichaTecnica,
}: IListFichaTecnica) {
  const { loading, setLoading, fichaTecnica, setFichaTecnica } =
    useContext(DatasheetContext);

  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [typedate, setTypeDate] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );

  const [dataSheetList, setDataSheetList] = useState<any>([]);
  const [fichaTecnicaList, setFichaTecnicaList] = useState<any>([]);
  const [listProducts, setListProducts] = useState<any>([]);
  const [fichaTecnicaListComplenta, setFichaTecnicaListCompleta] =
    useState<any>([]);
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [itemList, setItemList] = useState<any>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const listdate = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Data Cadastro" },
  ];

  const columns = [
    {
      name: "Produto",
      selector: (row: any) => row.relationships?.product?.sale_name?.toLowerCase(),
      sortable: true,
    },
    {
      name: "Rendimentos",
      selector: (row: any) => row.qty,
      center: true,
      grow: 0.3,
    },
    {
      name: "Custo",
      selector: (row: any) => {
        let listPrice: number[] = [];
        for (let index = 0; index < row.relationships.inputs.length; index++) {
          let productCost = listProducts?.find(
            (item: any) =>
              item.value === row.relationships.inputs[index].product_id
          )?.cost;

          const finalPrice =
            parseFloat(productCost) *
            parseFloat(row?.relationships?.inputs[index]?.qty);

          if (finalPrice) {
            listPrice.push(finalPrice);
          }
        }

        const sumProdcts = listPrice.reduce(function (acumulador, elemento) {
          return acumulador + elemento;
        }, 0);
        return sumProdcts ? Format.currency(sumProdcts * row.qty) : "---";
      },
      center: true,
      grow: 0.3,
    },
    {
      name: "Ação",
      cell: (row: any) => (
        <div className="actions">
          <FaEdit
            size={15}
            data-toggle="tooltip"
            data-placement="top"
            title="Editar"
            className="action"
            onClick={() => handleEdit(row)}
            style={{ marginRight: 16 }}
            color="8e8d8d"
          />
          <FaRegTrashAlt
            data-toggle="tooltip"
            data-placement="top"
            title="Deletar"
            className="action"
            onClick={() => handleModalId(row)}
            size={14}
            color="8e8d8d"
          />
        </div>
      ),
      button: true,
    },
  ];

  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      return;
    }
    setStartDate(date);
    let dateValid = isLater(date, endDate);
    if (dateValid) {
      return;
    }
  };

  const handleDateEnd = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      return;
    }
    setEndDate(date);
    let dateValid = isLater(startDate, date);
    if (dateValid) {
      return;
    }
  };

  const handleAdd = () => {
    OnAddNewCli(true);
  };

  const handleEdit = (item: IFichaTecnica) => {
    onEditItem(item);
  };

  function handleDelete(item: any) {
    api
      .delete(globals.api.creations + `/${item.uuid}`)
      .then((response) => {
        handleFetchDataSheet();
        notify("Categoria excluida com sucesso!");
      })
      .catch((error: Error) => {
        notifyError(`Ocorreu um erro ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const clearFields = () => {
    setFichaTecnica(0);
    setTypeDate(0);
    handleFetchDataSheet();
  };

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  function handleFetchDataSheet() {
    setLoading(true);
    api
      .get(
        globals.api.creations +
          `?paginate=false&store_id=${storeMe?.storeActive?.id}`
      )
      .then((response) => {
        const data = response.data;

        setDataSheetList(sortByCreated(data.data));
        setFichaTecnicaListCompleta(sortByCreated(data.data));
      })
      .catch((error: Error) => {
        notifyError(`Ocorreu um erro ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleSearchList = () => {
    setLoading(true);
    if (fichaTecnica > 0 && typedate === 1) {
      const nameList = FilterBy.findName(
        fichaTecnicaListComplenta,
        fichaTecnica
      );

      const dateList = FilterBy.date(nameList, startDate, endDate);

      setDataSheetList(dateList);
      setLoading(false);
      return;
    }

    if (fichaTecnica) {
      setDataSheetList(
        FilterBy.findName(fichaTecnicaListComplenta, fichaTecnica)
      );
      setLoading(false);
      return;
    }

    if (typedate === 1) {
      setDataSheetList(
        FilterBy.date(fichaTecnicaListComplenta, startDate, endDate)
      );
      setLoading(false);
      return;
    }
  };

  const handleModalId = (item: any) => {
    setItemList(item);
    setModalConfirmation(true);
  };

  const handleDeleteAdress = () => {
    setModalConfirmation(false);
    handleDelete(itemList);
  };
  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };
  useEffect(() => {
    handleFetchDataSheet();
    GetEndPoint.productCreation(setFichaTecnicaList, storeMe?.storeActive?.id);
    GetEndPoint.products(setListProducts, storeMe?.storeActive?.id);
  }, []);


  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir a ficha técnica?"
        handleAction={handleDeleteAdress}
      />
      <LayoutFilter
        title={title}
        onClickAdd={handleAdd}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        {" "}
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Produto"
                placeholder="Produto"
                state={fichaTecnica}
                options={fichaTecnicaList}
                setState={setFichaTecnica}
                isLoading={fichaTecnicaList ? false : true}
              />
              <ReactSelect
                label="Período"
                state={typedate}
                options={listdate}
                isInvalid={false}
                setState={setTypeDate}
              />

              <InputDefault
                label="De"
                type="date"
                disabled={typedate === 0}
                state={startDate}
                onChenge={(e: any) => handleDateStart(e.target.value)}
              />

              <InputDefault
                label="Até"
                type="date"
                disabled={typedate === 0}
                state={endDate}
                onChenge={(e: any) => handleDateEnd(e.target.value)}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Produto"
              state={fichaTecnica}
              options={fichaTecnicaList}
              setState={setFichaTecnica}
              isLoading={fichaTecnicaList ? false : true}
            />

            <ReactSelect
              label="Período"
              state={typedate}
              options={listdate}
              isInvalid={false}
              setState={setTypeDate}
            />

            <InputDefault
              label="De"
              type="date"
              disabled={typedate === 0}
              state={startDate}
              onChenge={(e: any) => handleDateStart(e.target.value)}
            />

            <InputDefault
              label="Até"
              type="date"
              disabled={typedate === 0}
              state={endDate}
              onChenge={(e: any) => handleDateEnd(e.target.value)}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>

      <LayoutTable>
        <DataTable
          columns={columns}
          data={dataSheetList || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
