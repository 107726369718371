import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import inventaryReducer from "./features/inventary/inventary-slice";
import chartAccountReducer from "./features/chartAccount/chartAccount-slice";
import categorieFinancialReducer from "./features/categorieFinancial/categorieFinancial-slice";
import storeReducer from "./features/store/store-slice";
import storeMeReducer from "./features/userMe/userMe-slice";
import paymentTypePDVReducer from "./features/PaymentTypePDV/payment-type-pdv-slice";
import paymentTypeReducer from "./features/paymentType/payment-type-slice";
import externalBankReducer from "./features/bankAccount/bank-account-slice";
import localStockReducer from "./features/localStock/local-stock-slice";
import financialTransactionReducer from "./features/financialTransaction/financial-transaction-slice";
import stockMovimentReducer from "./features/stockMoviment/stock-moviment-slice";
import userProfileReducer from "./features/userProfile/user-profile-slice";
import userSystemReducer from "./features/userSystem/user-system-slice";
import cfopReducer from "./features/Cfop/cfop-slice"
import userAccessReducer from "./features/userAccess/user-access-slice"
import stockTransferReducer from "./features/stockTransfer/stock-transfer-slice"

export const store = configureStore({
  reducer: {
    inventaryReducer,
    chartAccountReducer,
    categorieFinancialReducer,
    storeReducer,
    storeMeReducer,
    paymentTypePDVReducer,
    paymentTypeReducer,
    externalBankReducer,
    localStockReducer,
    financialTransactionReducer,
    stockMovimentReducer,
    userProfileReducer,
    userSystemReducer,
    cfopReducer,
    userAccessReducer,
    stockTransferReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
