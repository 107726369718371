import { useState, useContext, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { IFormFichaTecnica } from "../../../../Pages/Cadastro/CadFichaTecnica";
import Layout from "../../../data/Layout";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import { DatasheetContext } from "../../../../context/data-sheet";
import { ToastContainer } from "react-toastify";
import GetEndPoint from "../../../../Services/GetEndPoint";
import Loading from "../../../Loading/Loading";
import { StoreContext } from "../../../../context/store-context";
import { notify, notifyError } from "../../../notify/notify-component";
import { CreateAndEdit } from "../../../../Services/CreateAndEdit";
import globals from "../../../../Globals.json";
import api from "../../../../Services/api";
import { useAppSelector } from "../../../../redux/store";
import ModalBootstrap from "../../../data/Modal";
import * as S from "./styled";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";

export default function FormFichaTecnica({
  OnAddNewFichaTecnica,
  fichaTecnica,
  title,
  editando,
}: IFormFichaTecnica) {
  const {
    loading,
    setLoading,
    produto,
    setProduto,
    unidadeMedida,
    setUnidadeMedida,
    rendimentos,
    setRendimentos,
    tipoProducao,
    setTipoProducao,
    produtoInsumo,
    setProdutoInsumo,
    quantidade,
    setQuantidade,
    listaProdutosAdd,
    setListaProdutosAdd,
    isValidproduto,
    setIsValidProduto,
    isValidrendimentos,
    setIsValidRendimentos,
    isValidprodutoInsumo,
    setIsValidProdutoInsumo,
    isValidquantidade,
    setIsValidQuantidade,
    unidadeMedidaId,
    setUnidadeMedidaId,

    handleEdit,
    handleValidete,
    clearFilds,
  } = useContext(DatasheetContext);

  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [listProdutoEstocavelComplenta, setListProdutoEstocavelComplenta] =
    useState<any>([]);
  const [listaProdutoEstocavel, setListaProdutoEstocavel] = useState<any>([]);

  const [modalConfirmationAPI, setModalConfirmationAPI] =
    useState<boolean>(false);
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [uuid, setUuid] = useState<string>("");
  const [editeProductApi, setEditeProductApi] = useState<boolean>(false);

  async function handleSubmit() {
    const valid = handleValidete();

    if (valid === false) {
      notifyError("Por favor preencha todos os campos obrigatórios.");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    if (listaProdutosAdd.length === 0) {
      notifyError("Por favor informe pelo menos um insumo.");
      handleValideteInsumos();
      return;
    }

    const uuidProduto = listProdutoEstocavelComplenta.find(
      (item: any) => item.id === produto
    )?.uuid;

    const oModel = {
      uuid: editando && fichaTecnica ? fichaTecnica.uuid : null,
      store_id: storeMe?.storeActive?.id,
      measure_unit_id:
        editando && fichaTecnica
          ? fichaTecnica.measure_unit_id
          : unidadeMedidaId,
      qty: rendimentos,
      is_produced_on_stock_movement: 0, // Se  1 somente sera produzido na movimentação de estoque
    };

    try {
      const res = await CreateAndEdit.post(
        globals.api.products + `/${uuidProduto}/creations`,
        oModel,
        globals.api.creations
      ).then((res: any) => {
        setLoading(true);
        if (!res) {
          setTimeout(() => {
            setLoading(false);
          }, 1200);
        } else {
          let uuidForInsums = res.data.uuid;

          const newList = listaProdutosAdd.filter(
            (objeto) => !objeto.hasOwnProperty("uuid")
          );

          if (newList.length > 0) {
            try {
              api.post(globals.api.creations + `/${uuidForInsums}/inputs`, {
                inputs: newList,
              });
            } catch (error: any) {
              notifyError(error);
            }
          }

          setTimeout(() => {
            handleCancel();
            setLoading(false);
          }, 100);
        }
      });
    } catch (error) {
      notifyError("Já existe uma criação para este produto.");
    }
  }

  const handleUnidadeMedida = () => {
    let list = listProdutoEstocavelComplenta.find(
      (item: any) => item.id === produto
    );

    let unidade = list?.relationships?.sale_measure_unit?.name;
    let unidadeId = list?.relationships?.sale_measure_unit?.id;

    setUnidadeMedida(unidade ? unidade : "");
    setUnidadeMedidaId(unidadeId);
  };

  function handleCancel() {
    clearFilds();
    OnAddNewFichaTecnica(false);
  }

  const getUnit = () => {
    let itemSelected = listProdutoEstocavelComplenta.find(
      (item: any) => item.id === produtoInsumo
    );
    let unidade = itemSelected?.relationships?.sale_measure_unit?.name;
    return unidade ? `${unidade}` : "";
  };

  const handlePreAddInsumos = (
    id: number,
    qty: string,
    unit: number,
    uuid: string
  ) => {
    if (!unit)
      return notifyError(
        "Por favor selecione um produto com unidade de medida."
      );

    if (!id || !qty) return handleValideteInsumos();
    let existId = listaProdutosAdd.find((item: any) => item.product_id === id);

    if (existId) return notifyError("Produto ja adicionado na lista.");

    let list = {
      product_id: id,
      qty,
      measure_unit_id: unit,
      store_id: storeMe?.storeActive?.id,
      ...(uuid && { uuid: uuid }),
    };

    setListaProdutosAdd([...listaProdutosAdd, list]);

    setProdutoInsumo(0);
    setQuantidade("");
  };

  const handleCancelEditetable = () => {
    setProdutoInsumo(0);
    setQuantidade("");
    setEditeProductApi(false);
  };

  const handleRemoveInsumos = (id: number) => {
    setListaProdutosAdd(
      listaProdutosAdd.filter((item: any) => item.product_id !== id)
    );
  };

  const handleRemoveInsumosFetch = async (uuid: string, id: number) => {
    setLoading(true);
    try {
      await api.delete(globals.api.inputs + "/" + uuid);
    } catch (error: any) {
      notifyError(error);
      notifyError("Desculpe não foi possivel remover o produto.");
    }
    setLoading(false);
    handleRemoveInsumos(id);
  };

  const handleValideteInsumos = () => {
    if (!produtoInsumo) setIsValidProdutoInsumo(true);
    if (produtoInsumo) setIsValidProdutoInsumo(false);
    if (!quantidade) setIsValidQuantidade(true);
    if (quantidade) setIsValidQuantidade(false);

    if (produtoInsumo && quantidade) {
      return true;
    }

    return false;
  };

  const handleModalIdApi = (uuid: string, id: number) => {
    setId(id);
    setUuid(uuid);
    setModalConfirmationAPI(true);
  };

  async function editeProducts(data: any, uuid?: string, id?: any) {
    setEditeProductApi(true);
    setProdutoInsumo(data?.product_id);
    setQuantidade(data?.qty);

    setListaProdutosAdd(
      listaProdutosAdd?.filter(
        (item: any) => item?.product_id !== data?.product_id
      )
    );
    setId(id);
    uuid && handleRemoveInsumosFetch(uuid, id);
  }

  const handleModalId = (id: number) => {
    setId(id);
    setModalConfirmation(true);
  };

  const handleDeleteInsulmoAPI = () => {
    setModalConfirmationAPI(false);
    handleRemoveInsumosFetch(uuid, id);
  };

  const handleDeleteInsulmo = () => {
    setModalConfirmation(false);
    handleRemoveInsumos(id);
  };

  useEffect(() => {
    setListaProdutoEstocavel([]);
    GetEndPoint.productsStockable(
      setListaProdutoEstocavel,
      setListProdutoEstocavelComplenta,
      storeMe?.storeActive?.id
    );
  }, []);

  useEffect(() => {
    if (produtoInsumo) setIsValidProdutoInsumo(false);
    if (quantidade) setIsValidQuantidade(false);
  }, [produtoInsumo, quantidade]);

  useEffect(() => {
    setTimeout(() => {
      handleUnidadeMedida();
    }, 2000);
  }, [produto]);

  useEffect(() => {
    editando && fichaTecnica && handleEdit(fichaTecnica);
  }, []);

  //product_purpose_id

  const listProductsOnlyStock = listaProdutoEstocavel.filter(
    (item: any) => item.product_purpose_id === 2
  );

  return (
    <>
      <ModalBootstrap
        state={modalConfirmationAPI}
        setState={setModalConfirmationAPI}
        text="Certeza que deseja excluir o insumo?"
        handleAction={handleDeleteInsulmoAPI}
      />
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir o insumo?"
        handleAction={handleDeleteInsulmo}
      />
      <Layout
        title={title}
        isEditable={editando}
        onClickSave={handleSubmit}
        onClickEdit={handleSubmit}
        onClickCancel={handleCancel}
      >
        {/* 
          
        <Row className="mt-3">
          <Col md="6">
            <h4 className="mb-1">Tipo de produção</h4>
            <br />
            <Form.Check
              inline
              checked={tipoProducao === 1}
              label="Baixar insumos na produção"
              name="group1"
              type="radio"
              id="0"
              value={1}
              onChange={(e) => setTipoProducao(parseInt(e.target.value))}
            />

            <Form.Check
              inline
              checked={tipoProducao === 0}
              label="Baixar insumos na venda"
              name="group1"
              type="radio"
              id="1"
              value={0}
              onChange={(e) => setTipoProducao(parseInt(e.target.value))}
            />
          </Col>
        </Row>
          */}
        <S.Wrapper>
          <ReactSelect
            label="Produto"
            state={produto}
            isClearable={true}
            placeholder="Produto"
            options={listaProdutoEstocavel}
            onChange={(e: any) => setProduto(e?.value)}
            isInvalid={isValidproduto}
          />

          <InputDefault
            label="Uni. medida"
            type="text"
            autoComplete="off"
            state={
              produto
                ? listProdutoEstocavelComplenta.find(
                    (item: any) => item.id === produto
                  )?.relationships?.sale_measure_unit?.name
                : ""
            }
            placeholder="Unidade de medida"
            maxLength={50}
            disabled
            onChenge={() =>
              setUnidadeMedidaId(
                produto &&
                  listProdutoEstocavelComplenta.find(
                    (item: any) => item.id === produto
                  )?.relationships?.sale_measure_unit.id
              )
            }
          />

          <InputDefault
            label="Rendimentos"
            type="number"
            autoComplete="off"
            state={rendimentos}
            placeholder="0"
            maxLength={50}
            setState={setRendimentos}
            isInvalid={isValidrendimentos}
          />
        </S.Wrapper>

        <h4>Escolha os produtos (insumos) que compõem a ficha técnica</h4>

        <S.Wrapper>
          <ReactSelect
            label="Produto"
            state={produtoInsumo}
            setState={setProdutoInsumo}
            isClearable={true}
            placeholder="Produto"
            options={listProductsOnlyStock}
            isInvalid={isValidprodutoInsumo}
          />

          <InputDefault
            label="Uni. medida"
            state={getUnit()}
            placeholder="Unidade de medida"
            type="text"
            disabled
          />

          <InputDefault
            label={`Quantidade `}
            type="text"
            autoComplete="off"
            state={quantidade}
            placeholder="0,00"
            onChenge={(e: any) => setQuantidade(e.target.value)}
            isInvalid={isValidquantidade}
          />
        </S.Wrapper>

        <div
          style={{
            margin: "5px 15px",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "end",
            gap: "2rem",
          }}
        >
          {/*editeProductApi && (
            <Button
              onClick={() => handleCancelEditetable()}
              variant="secondary"
              type="button"
            >
              Cancelar
            </Button>
          )*/}
          <Button
            onClick={() =>
              handlePreAddInsumos(
                produtoInsumo,
                quantidade,
                listProdutoEstocavelComplenta.find(
                  (item: any) => item.id === produtoInsumo
                )?.relationships?.sale_measure_unit?.id,
                uuid
              )
            }
            variant="primary"
            type="button"
          >
            {editeProductApi ? "Salvar" : "Adicionar"}
          </Button>
        </div>

        {listaProdutosAdd.length > 0 && (
          <Table striped hover bordered style={{ marginTop: "30px" }}>
            <thead>
              <tr className="trTable">
                <th>Nome do produto</th>
                <th>Quantidade</th>
                <th>Unid. de Medida</th>
                <th>Remover</th>
              </tr>
            </thead>

            {!fichaTecnica?.relationships.inputs.length ? (
              <tbody>
                {listaProdutosAdd?.map((item: any) => {
                  return (
                    <tr key={item.product_id}>
                      <td>
                        {
                          listProdutoEstocavelComplenta.find(
                            (product: any) => product.id === item.product_id
                          )?.purchase_name
                        }
                      </td>
                      <td>{item.qty}</td>
                      <td>
                        {
                          listProdutoEstocavelComplenta.find(
                            (product: any) => product?.id === item.product_id
                          )?.relationships?.sale_measure_unit?.name
                        }
                      </td>

                      <td
                        style={{
                          display: "flex",
                          gap: "1rem",
                        }}
                      >
                        <button
                          style={{ border: "none", background: "none" }}
                          type="button"
                          onClick={() => editeProducts(item)}
                        >
                          <FaEdit size={16} color="666666" />
                        </button>
                        <button
                          style={{ border: "none", background: "none" }}
                          type="button"
                          onClick={() => handleModalId(item?.product_id)}
                        >
                          <FaRegTrashAlt size={16} color="666666" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                {listaProdutosAdd?.map((item: any) => {
                  return (
                    <tr key={item?.product_id}>
                      <td>
                        {
                          listProdutoEstocavelComplenta.find(
                            (product: any) => product?.id === item?.product_id
                          )?.purchase_name
                        }
                      </td>
                      <td>
                        {Number(item?.qty)?.toFixed(2)?.replace(".", ",")}
                      </td>
                      <td>
                        {
                          listProdutoEstocavelComplenta.find(
                            (product: any) => product?.id === item?.product_id
                          )?.relationships?.sale_measure_unit?.name
                        }
                      </td>

                      <td
                        style={{
                          display: "flex",
                          gap: "1rem",
                        }}
                      >
                        <button
                          style={{ border: "none", background: "none" }}
                          type="button"
                          onClick={() =>
                            editeProducts(item, item?.uuid, item?.product_id)
                          }
                        >
                          <FaEdit size={16} color="666666" />
                        </button>
                        <button
                          style={{ border: "none", background: "none" }}
                          type="button"
                          onClick={() =>
                            handleModalIdApi(item?.uuid, item?.product_id)
                          }
                        >
                          <FaRegTrashAlt size={16} color="666666" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
        )}
      </Layout>

      {loading && <Loading />}
      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
