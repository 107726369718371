import { IFormPerfilUsuario } from "../../../../Pages/Cadastro/CadPermissions";
import * as Yup from "yup";
import { perfilUsuarioSchemaForm } from "../../../../redux/features/userProfile/schema";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LayoutHookForm from "../../../reactHookForm/LayoutForm";
import * as S from "./styles";
import { InputHook } from "../../../reactHookForm/InputHook";
import { CheckboxHookLeft } from "../../../reactHookForm/inputCheckboxLeft";
import { setStateForm } from "../../../../redux/features/userProfile/user-profile-slice";
import {
  createUserProfileAsync,
  editeUserProfileAsync,
  getUserProfileAsync,
} from "../../../../redux/features/userProfile/services";
import { notifyError } from "../../../notify/notify-component";
import { IFormCfops } from "../../../../Pages/Cadastro/CadCfops";
import ReactSelect from "../../../data/reactSelect";
import { SelectHook } from "../../../reactHookForm/SelectHook";

type FormData = Yup.InferType<typeof perfilUsuarioSchemaForm>;

export function FormCfops({
  title,
  editando,
  OnAddNewStore,
  selectOptions,
  perfilUsuario,
}: IFormCfops) {
  const dispatch = useDispatch<AppDispatch>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { stateForm } = useAppSelector(
    ({ userProfileReducer }) => userProfileReducer.value
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(perfilUsuarioSchemaForm) });

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    dispatch(setStateForm({ state: "loading" }));

    if (editando) {
      dispatch(editeUserProfileAsync(data)).then((result: any) => {
        if (editeUserProfileAsync.fulfilled.match(result)) {
          dispatch(getUserProfileAsync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao atualizar item.");
        }
      });

      dispatch(setStateForm({ state: "default" }));
      return;
    }
    const { name, description, ...extratData } = data;
    const truePermissions = Object.keys(extratData).filter(
      (key) => extratData[key] === true
    );
    const newData = {
      storeId: storeMe?.storeActive?.id,
      name,
      description,
      permission_names: truePermissions,
    };

    dispatch(createUserProfileAsync(newData)).then((result: any) => {
      if (createUserProfileAsync.fulfilled.match(result)) {
        dispatch(getUserProfileAsync(storeMe?.storeActive?.id));
      } else {
        notifyError("Erro ao adicionar item.");
      }
    });
    dispatch(setStateForm({ state: "default" }));
  };

  const handleCancel = () => {
    reset();
    OnAddNewStore(false);
  };

  const inputError = [errors];

  return (
    <>
      <LayoutHookForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={title}
        isEditable={editando}
        onClickCancel={handleCancel}
        stateForm={stateForm}
      >
        <S.Section>
          <div className="top mb-4">
            <h3>Selecione as configurações do seu CFOP</h3>
            <S.WrapperInput>
              <SelectHook
                register={register}
                control={control}
                label="CFOP"
                name="cfop_principal"
                placeholder="CFOP Principal"
                options={[]}
                errors={inputError}
              />
              <SelectHook
                register={register}
                control={control}
                label="CFOP"
                name="cfop_principal"
                placeholder="CFOP de contra partida (opcional)"
                options={[]}
                errors={inputError}

              />
            </S.WrapperInput>
          </div>
          <div className="top borderTop">
            <h3>Movimentação de estoque</h3>
            <p>
              Defina o tipo de movimentação que será feita ao utilizar o CFOP
              escolhido. Você também pode optar por não movimentar o estoque.
            </p>
            <S.WrapperInput>
              <SelectHook
                register={register}
                control={control}
                label="Tipo/movimentação"
                name="cfop_principal"
                placeholder="Tipo de movimentação"
                options={[]}
                errors={inputError}

              />
              <SelectHook
                register={register}
                control={control}
                label="Valoração"
                name="cfop_principal"
                placeholder="Valoração"
                options={[]}
                errors={inputError}

              />
            </S.WrapperInput>
          </div>
          <div className="top borderTop">
            <h3>
              Situações tributárias<span>(opcional)</span>
            </h3>
            <p>
              Você pode optar por definir um padrão de tributação ao utilizar
              este CFOP. Caso não seja configurado uma tributação específica
              para este CFOP, será utilizada a tributação informada no cadastro
              do produto.
            </p>
            <S.WrapperInput>
              <SelectHook
                register={register}
                control={control}
                label="Situação"
                name="cfop_principal"
                placeholder="Situação tributária de ICMS"
                options={[]}
                errors={inputError}

              />
              <InputHook
                register={register}
                errors={inputError}
                label="Benefício Fiscal "
                name="address_street"
                type="text"
                placeholder="Benefício Fiscal "
                
              />
            </S.WrapperInput>
            <S.WrapperInput>
              <SelectHook
                register={register}
                control={control}
                label="ICMS"
                name="cfop_principal"
                placeholder="Motivo da desoneração do ICMS"
                options={[]}
                errors={inputError}
              />
              <SelectHook
                register={register}
                control={control}
                label="CSOSN "
                name="cfop_principal"
                placeholder="CSOSN"
                options={[]}
                errors={inputError}
              />
            </S.WrapperInput>
            <S.WrapperInput>
              <SelectHook
                register={register}
                control={control}
                label="PIS e COFINS"
                name="cfop_principal"
                placeholder="Situação tributária de PIS e COFINS"
                options={[]}
                errors={inputError}
              />
              <SelectHook
                register={register}
                control={control}
                label="IPI "
                name="cfop_principal"
                placeholder="Situação tributária de IPI"
                options={[]}
                errors={inputError}
              />
            </S.WrapperInput>
          </div>
          <div className="top borderTop">
            <h3>Alterações nos preços e fornecedor do produto</h3>
            <p>
              Deseja que os produtos com este CFOP tenham seus preços
              recalculados e fornecedor atualizado na importação da nota fiscal?
            </p>
          </div>
        </S.Section>
      </LayoutHookForm>
    </>
  );
}
