import { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaEdit, FaPowerOff, FaTrash } from "react-icons/fa";
import { ICategories } from "../Cadastro/Categorias/interface/ICategories";
import { CreateAndEdit } from "../../Services/CreateAndEdit";
import globals from "../../Globals.json";
import { notify, notifyError } from "../notify/notify-component";
import ModalBootstrap from "../data/Modal";
import { Services } from "../../Services/newService";

interface Props {
  key?: any;
  category: ICategories;
  onEditItem: (item: ICategories) => void;
  OnAddNewCategorie: (e: boolean) => void;
  handleFetchTree: any;
}

function Tree({
  category,
  onEditItem,
  OnAddNewCategorie,
  handleFetchTree,
}: Props) {
  const [expand, setExpand] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>("");

  const handleEdit = (item: ICategories) => {
    onEditItem(item);
  };

  const handleDelete = async (uuid: string) => {
    const res = await Services.categoriProductsDelete(uuid);

    if (res.status === 200) {
    notify(res.data.message)
       

      handleFetchTree();
    } else {
      notifyError("Erro ao excluir plano de conta.");
    }
  };

  const handleActived = (uuid: any) => {
    const oModel = {
      uuid: uuid,
      is_active: category.is_active === true ? false : true,
    };

    CreateAndEdit.post(globals.api.categoriesAlt, oModel).then((res: any) => {
      if (!res) {
        handleFetchTree();
      } else {
        notify(
          oModel?.is_active ? "Categoria ativada" : "Categoria desativada"
        );
        handleFetchTree();
      }
    });
  };
  const handleModalUuid = (uuid: string) => {
    setUuid(uuid);
    setModalConfirmation(true);
  };

  const handleDeleteItem = () => {
    setModalConfirmation(false);
    handleDelete(uuid);
  };
  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text={
          "Tem certeza de que deseja excluir? Se este item estiver vinculado a algum produto, ele será excluido."
        }
        handleAction={handleDeleteItem}
      />
      <div className="row ItemTree" style={{ paddingTop: "10px" }}>
        <div
          className={`col ${
            category?.relationships?.children?.length >= 1
              ? "subCategoryBackGroup"
              : "categoryBackGroup"
          }`}
        >
          <section
            className="divBackgroundColor"
            style={{ paddingLeft: "10px" }}
          >
            <div className="px-2" style={{ fontSize: "12px" }}>
              {category?.name}

              {category?.relationships?.children?.length >= 1 && (
                <button onClick={() => setExpand(!expand)}>
                  {expand ? (
                    <MdKeyboardArrowUp size={20} color="#a1a5b7" />
                  ) : (
                    <MdKeyboardArrowDown size={20} color="#a1a5b7" />
                  )}
                </button>
              )}
            </div>

            <div className="ms-auto">
              <button>
                <FaEdit
                  size={15}
                  color="8e8d8d"
                  onClick={() => handleEdit(category)}
                />
              </button>
              <button className="px-2">
                { category?.relationships?.children?.length >= 1? (
                  <FaPowerOff
                    size={15}
                    color={category?.is_active === false ? "FF6666" : "green"}
                    onClick={() => handleActived(category?.uuid)}
                  />
                ) : (
                  <FaTrash
                    size={14}
                    color="8e8d8d"
                    onClick={() => handleModalUuid(category.uuid)}
                  />
                )}
              </button>
            </div>
          </section>
        </div>

        <div style={{ display: expand ? "block" : "none" }}>
          {category?.relationships?.children?.map((explore: any) => (
            <div className="d-flex flex-row-reverse bd-highlight">
              <Tree
                key={explore?.id}
                category={explore}
                onEditItem={onEditItem}
                OnAddNewCategorie={OnAddNewCategorie}
                handleFetchTree={handleFetchTree}
              />
            </div>
          ))}
        </div>
      </div>

      <div style={{ height: "-5px" }}></div>
    </>
  );
}

export default Tree;
