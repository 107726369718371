import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";

export const getUserAccessAsync = createAsyncThunk(
  "user-Access",
  async (activedStoreId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.role + `?paginate=false&store_id=${activedStoreId}`
      );
      
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createUserAccessAsync = createAsyncThunk(
  "create/UserAccess",
  async (data: any, thunkAPI) => {
    try {
     
      const res = await api.post(globals.api.role, data);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editeUserAccessAsync = createAsyncThunk(
  "put/UserAccess",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.role + `/${data.uuid}`, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteUserAccessAsync = createAsyncThunk(
  "delete/payment-type-pdv",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.role + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
