import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const getBankAccountAsync = createAsyncThunk(
  "get/banck-account",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.bankAccounts + `?paginate=false&store_id=${storeActivedId}`
      );
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getExternalBanksAsync = createAsyncThunk(
  "get/bancks",
  async (_, thunkAPI) => {
    try {
      const res = await api.get(globals.api.banks + `?paginate=false`);

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createBankAccountAsync = createAsyncThunk(
  "create/banck-account",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.bankAccounts, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBankAccountAsync = createAsyncThunk(
  "update/banck-account",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(
        globals.api.bankAccounts + `/${data.uuid}`,
        data
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteBankAccountAsync = createAsyncThunk(
  "delete/banck-account",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.bankAccounts + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
