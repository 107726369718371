import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const createStockMovimentAsync = createAsyncThunk(
  "create/stockMoviment",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.inventary, data);

      if (res.data.data.uuid) {
        await api.post(
          globals.api.inventary + `/${res.data.data.uuid}/products`,
          data.products
        );
      }

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
