import * as S from "./styled";
import { useContext } from "react";
import { ThemeContext } from "../../../context/theme-context";
import {
  FaAngleLeft,
} from "react-icons/fa";
import { CgMenuGridR } from "react-icons/cg";
import { ItemsMenu } from "../menu-item/itemsMenu";
import { MenuItem } from "../menu-item";

export function MenuStore() {
  const { collapsed, handleCollapsed } = useContext(ThemeContext);
  const paddingLeft = collapsed ? "7px" : "";

  return (
    <S.Sidebar collapse={collapsed}>
      <div className="btn">
        <button
          onClick={handleCollapsed}
          type="button"
          style={{ border: "none", background: "none", paddingLeft }}
        >
          {!collapsed ? (
            <div className="arrow">
              <FaAngleLeft style={{ color: "#f0f0f0" }} size={30} />
            </div>
          ) : (
            <div>
              <CgMenuGridR size={30} color="white" />
            </div>
          )}
        </button>
      </div>
      {ItemsMenu.map((item, index) => (
        <MenuItem key={item.titulo + index} item={item} />
      ))}
    </S.Sidebar>
  );
}
