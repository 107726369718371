import React, { useState, useEffect, useContext } from "react";
import styles from "./styles.module.scss";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import {
  msgStyles,
  NoOptionsMessage,
} from "../../../NoOptionsMessage/no-options-message-component";
import Format from "../../../../Services/Format";
import { getReceiver } from "../../../../Services/people-service";
import { getBankAccounts } from "../../../../Services/bank-accounts";
import { getPaymentType } from "../../../../Services/payment-type";
import { toast, ToastContainer } from "react-toastify";
import { notify, notifyError } from "../../../notify/notify-component";
import Loading from "../../../Loading/Loading";
import { StoreContext } from "../../../../context/store-context";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { getChartAccount } from "../../../../Services/chart-accounts-service";
import { createFinancialTransaction } from "../../../../Services/financial-transaction";
import { useAppSelector } from "../../../../redux/store";
import {
  createfinancialLauchTransactionAsync,
  getfinancialTransactionAsync,
} from "../../../../redux/features/financialTransaction/services";
import { sortByCreated } from "../../../../Services/sortByCreated";

export default function CadLauch({
  show,
  Title,
  onHide,
  setAmountInstallments,
  setDiscount,
  setInterest,
  EntryType,
  amountInstallments,
  pendingValue,
  discount,
  interest,
  launchTransacao,
  Placeholder,
  Label,
  setPendingValue,
  dispatch,
}: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);


  const [loading, setLoading] = useState<boolean>(false);
  const [paymentTypeId, setPaymentTypeId] = useState<number>(0);
  const [banckAccountId, setBanckaccountId] = useState<number>(0);
  const [chartAccountId, setChartAccountId] = useState<number>(0);
  const [categories, setCategories] = useState<number>(0);

  const [description, setDescription] = useState<string>("");
  const [receiver, setReceiver] = useState<number>();
  const [document, setDocument] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [status, setStatus] = useState<number>(0);
  const [amountValue, setAmountValue] = useState<number>(0);

  const [paymentDate, setPaymentDate] = useState<string>(
    Format.date(new Date(), "YYYY-MM-DD")
  );
  const [dueDate, setDueDate] = useState<string>(
    Format.date(new Date(), "YYYY-MM-DD")
  );

  const [listReceiver, setListReceiver] = useState<Array<any>>([]);
  const [listBankaccount, setListBankAccount] = useState<Array<any>>([]);
  const [listPaymentType, setListPaymentType] = useState<Array<any>>([]);
  const [listChartAccount, setListChartAccount] = useState<Array<any>>([]);
  const [number, setNumber] = useState<number | undefined>();

  const disabled = status === 2 ? true : false;

  const handleCancel = () => {
    onHide();
    setAmountInstallments(undefined);
    setDiscount(undefined);
    setInterest(undefined);
  };

  const fillReceiver = async (store_id:number) => {
    await getReceiver(store_id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      let list = data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setListReceiver(list);
    });
  };

  const fillBankAccounts = async (store_id:number) => {
    await getBankAccounts(store_id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      let list = data.map((item: any) => ({
        value: item.id,
        label: item.description,
      }));
      setListBankAccount(list);
    });
  };

  const fillChartAccount = async (store_id:number) => {
    await getChartAccount(store_id).then((response) => {
      const data = sortByCreated(response.data.data);

      const filterRevenue = data.filter(
        (revenue: any) => revenue.entry_type === EntryType
      );

      let list = filterRevenue.map((item: any) => ({
        value: item.id,
        label: item.description,
      }));
      setListChartAccount(list);
    });
  };

  const fillPaymentType = async (store_id:number) => {
    await getPaymentType(store_id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      const filterPaymentType = data.filter(
        (paymentType: any) => paymentType.entry_type === EntryType
      );

      let list = filterPaymentType.map((item: any) => ({
        value: item.id,
        label: item.description,
      }));
      setListPaymentType(list);
    });
  };

  const handleinterest = () => {
    if (amountInstallments > pendingValue) {
      let newValue: any = amountInstallments - pendingValue;
      setInterest(newValue.toFixed(2));
    } else {
      setInterest(0);
    }
  };

  const handleDiscount = () => {
    if (amountInstallments < pendingValue) {
      let newValue: any = amountInstallments - pendingValue;
      setDiscount(newValue.toFixed(2));
    } else {
      setDiscount(0);
    }
  };

  async function handleSubmit() {
    if (
      !amountValue ||
      !pendingValue ||
      !paymentTypeId ||
      !chartAccountId ||
      !banckAccountId
    )
      return notifyError("Por favor preencher todos os campos.");

    if (amountInstallments || paymentTypeId || banckAccountId) {
      const oModel = {
        document: launchTransacao.document,
        amount: amountInstallments ? amountInstallments : pendingValue,
        payment_type_id: paymentTypeId,
        bank_account_id: banckAccountId,
        chart_account_id: chartAccountId,
        must_close: false,
        discount: discount === undefined ? 0 : discount,
        interest: interest === undefined ? 0 : interest,
        note: note,
      };
      setLoading(true);

      try {
        const newData = {
          data: oModel,
          uuid: launchTransacao.uuid,
        };

        dispatch(createfinancialLauchTransactionAsync(newData)).then(
          (result: any) => {
            if (createfinancialLauchTransactionAsync.fulfilled.match(result)) {
              dispatch(getfinancialTransactionAsync(storeMe?.storeActive?.id));
              onHide();
            } else {
              notifyError("Erro no lançamento.");
            }
          }
        );
      } catch (error) {
        notifyError("Erro no lançamento");
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    fillReceiver(storeMe?.storeActive?.id);
    fillPaymentType(storeMe?.storeActive?.id);
    fillBankAccounts(storeMe?.storeActive?.id);
    fillChartAccount(storeMe?.storeActive?.id);
  }, []);

  useEffect(() => {
    if (launchTransacao) {
      setDescription(launchTransacao?.description);
      setCategories(launchTransacao?.financial_transaction_category_id);
      setReceiver(launchTransacao?.receiver_people_id);
      setStatus(launchTransacao?.status);
      setDocument(launchTransacao?.document_description);
      setAmountValue(launchTransacao?.amount.toFixed(2));
      setPendingValue(launchTransacao?.pending_amount.toFixed(2));
      setPaymentTypeId(launchTransacao?.payment_type_id);
      setBanckaccountId(launchTransacao?.bank_account_id);
      setDueDate(launchTransacao?.due_date);
      setChartAccountId(launchTransacao?.chart_account_id);
      setNote(launchTransacao?.note);
      setNumber(launchTransacao?.installment_number);
    }
  }, [launchTransacao]);

  useEffect(() => {
    handleDiscount();
    handleinterest();
  }, [amountInstallments]);

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className={`${styles.modalHeader}`}>
          <Modal.Title className={`fs-3`}>{Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "12px" }}>
          <Form className={styles.form}>
            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Descrição :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="text"
                    name="description"
                    placeholder="Descrição"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Favorecido :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="text"
                    value={
                      receiver && listReceiver?.length > 0
                        ? listReceiver?.filter(
                            (option: any) => option.value === receiver
                          )?.[0]?.label
                        : ""
                    }
                  />
                </Col>
              </Col>
            </Row>

            <Row style={{ marginLeft: "4.1rem" }}>
              <Col md="5" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Número :{" "}
                </label>

                <Form.Control
                  className="fs-7"
                  disabled={true}
                  size="sm"
                  autoComplete="off"
                  type="text"
                  name="numero doc."
                  placeholder="REC-000"
                  min="0"
                  onChange={(e) => setDocument(e.target.value)}
                  value={document}
                />
              </Col>
              {number && (
                <Col md="5" className="mb-1 d-flex align-items-center">
                  <label
                    className="px-1"
                    style={{ width: "150px", textAlign: "right" }}
                  >
                    NºParcela :
                  </label>

                  <Form.Control
                    className="fs-7"
                    disabled={true}
                    size="sm"
                    autoComplete="off"
                    type="text"
                    name="numero parcela."
                    placeholder="REC-000"
                    min="0"
                    value={number}
                  />
                </Col>
              )}
            </Row>

            <Row>
              <Col md="7" className="mb-1  d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Total :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    type="number"
                    step="0.1"
                    placeholder="0,00"
                    min="0"
                    disabled={true}
                    size="sm"
                    value={amountValue}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="7" className="mb-1  d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Pendente :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    type="number"
                    step="0.1"
                    placeholder="0,00"
                    min="0"
                    disabled={true}
                    size="sm"
                    value={pendingValue}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="7" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Valor :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    size="sm"
                    autoComplete="off"
                    type="number"
                    name="tax"
                    step="0.1"
                    placeholder="0,00"
                    min="0"
                    value={amountInstallments}
                    onChange={(e) =>
                      setAmountInstallments(Number.parseFloat(e.target.value))
                    }
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="7" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Juros :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    size="sm"
                    autoComplete="off"
                    type="number"
                    name="interest"
                    min="0"
                    placeholder="0"
                    value={interest}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="7" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Desconto :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    size="sm"
                    autoComplete="off"
                    type="number"
                    name="discount"
                    min="0"
                    placeholder="0"
                    value={discount && Math.abs(discount)}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  {Label} :{" "}
                </label>
                <Col>
                  {disabled ? (
                    <Form.Control
                      className="fs-7"
                      disabled={true}
                      size="sm"
                      autoComplete="off"
                      type="text"
                      value={
                        paymentTypeId && listPaymentType?.length > 0
                          ? listPaymentType?.filter(
                              (option: any) => option.value === paymentTypeId
                            )?.[0]?.label
                          : ""
                      }
                    />
                  ) : (
                    <Select
                      value={
                        paymentTypeId
                          ? listPaymentType.filter(
                              (option: any) => option.value === paymentTypeId
                            )
                          : ""
                      }
                      isClearable={true}
                      placeholder={Placeholder}
                      options={listPaymentType}
                      components={{ NoOptionsMessage }}
                      name={"type"}
                      styles={{
                        noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
                      }}
                      onChange={(e) => setPaymentTypeId(e?.value)}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: "#e4e6ef",
                          primary25: "#e4e6ef",
                          primary75: "#e4e6ef",
                          primary: "#0d6efd",
                        },
                      })}
                    />
                  )}
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Conta :{" "}
                </label>
                <Col>
                  {disabled ? (
                    <Form.Control
                      className="fs-7"
                      disabled={true}
                      size="sm"
                      autoComplete="off"
                      type="text"
                      value={
                        banckAccountId && listBankaccount?.length > 0
                          ? listBankaccount?.filter(
                              (option: any) => option.value === banckAccountId
                            )?.[0]?.label
                          : ""
                      }
                    />
                  ) : (
                    <Select
                      value={listBankaccount.filter(
                        (option: any) => option.value === banckAccountId
                      )}
                      isClearable={true}
                      placeholder={"Conta"}
                      options={listBankaccount}
                      components={{ NoOptionsMessage }}
                      styles={{
                        noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
                      }}
                      onChange={(e) => setBanckaccountId(e?.value)}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: "#e4e6ef",
                          primary25: "#e4e6ef",
                          primary75: "#e4e6ef",
                          primary: "#0d6efd",
                        },
                      })}
                    />
                  )}
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1 d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Plano de Contas :{" "}
                </label>
                <Col>
                  {disabled ? (
                    <Form.Control
                      className="fs-7"
                      disabled={true}
                      size="sm"
                      autoComplete="off"
                      type="text"
                      value={
                        chartAccountId && listChartAccount?.length > 0
                          ? listChartAccount?.filter(
                              (option: any) => option.value === chartAccountId
                            )?.[0]?.label
                          : ""
                      }
                    />
                  ) : (
                    <Select
                      value={listChartAccount.filter(
                        (option: any) => option.value === chartAccountId
                      )}
                      isClearable={true}
                      placeholder={"Contas"}
                      options={listChartAccount}
                      components={{ NoOptionsMessage }}
                      name={"Plano de Contas"}
                      styles={{
                        noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
                      }}
                      onChange={(e) => setChartAccountId(e?.value)}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary50: "#e4e6ef",
                          primary25: "#e4e6ef",
                          primary75: "#e4e6ef",
                          primary: "#0d6efd",
                        },
                      })}
                    />
                  )}
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1  d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Data de Recebimento :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={disabled}
                    size="sm"
                    type="date"
                    name="data vencimento"
                    placeholder="dd/mm/aa"
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                  />
                </Col>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="mb-1  d-flex align-items-center">
                <label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Data de Vencimento :{" "}
                </label>
                <Col>
                  <Form.Control
                    className="fs-7"
                    disabled={disabled}
                    size="sm"
                    type="date"
                    name="data vencimento"
                    placeholder="dd/mm/aa"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="mb-1  d-flex align-items-center">
                <Form.Label
                  className="px-2"
                  style={{ width: "150px", textAlign: "right" }}
                >
                  Observação :{" "}
                </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    disabled={disabled}
                    value={note}
                    onChange={(e: any) => setNote(e.target.value)}
                  />
                </Col>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer
          className={`${styles.footer} d-flex justify-content-center gap-20 py-4`}
        >
          <Button variant="primary" onClick={handleCancel} size="sm">
            Cancelar
          </Button>
          {!disabled && (
            <Button variant="primary" onClick={handleSubmit} size="sm">
              Confirmar
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {loading && <Loading />}

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
