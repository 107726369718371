import { useEffect, useState } from "react";
import { Row,  Col, Button} from "react-bootstrap/";
import Loading from "../../../Loading/Loading";
import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { IFormTransferenciaEstoque } from "../../../../Pages/Cadastro/CadTransfEstoque";
import { useAppSelector, type AppDispatch } from "../../../../redux/store";

import * as S from "../../styled";
import { useDispatch } from "react-redux";
import { addItemTable } from "../../../../redux/features/stockTransfer/stock-transfer-slice";
import { useForm, type SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LayoutHookForm from "../../../reactHookForm/LayoutForm";
import { SelectHook } from "../../../reactHookForm/SelectHook";
import { InputHook } from "../../../reactHookForm/InputHook";
import { createStockTransferAsync, getProductsAsync, getStockAllLocationAsync, getStockLocationAsync, getStockTransferAsync, updateStockTransferAsync } from "../../../../redux/features/stockTransfer/services";
import { stockTransferSchema } from "../../../../redux/features/stockTransfer/schema";
import type {
  IStockLocation,
} from "../../../../redux/features/stockTransfer/interface";
import { CadProduct } from "./catProduct";
import { notifyError } from "../../../notify/notify-component";

type FormData = {
  stock_selected?: {value: number, uuid:number};
  note?:string
  stock_destination_id?: {value: number, uuid:number};
  store_destination_id?:{value: number, uuid:number};
};

export function FormTransferenciaEstoque({
  title,
  OnAddNewEstoque,
  editando,
  estoque,
}: IFormTransferenciaEstoque) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();
  const { stateForm, stocklocation, stockAlllocation, tableProducts } = useAppSelector(
    ({ stockTransferReducer }) => stockTransferReducer.value
  );
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(stockTransferSchema),
  });
  const inputError = [errors];

  const [isIntern, setIsIntern] = useState<boolean>(true);
  const [stocksfiltered, setStocksfiltered] = useState<IStockLocation[]>([]);

  const stores = storeMe.user.relationships.stores
    ?.filter((item) => item.id !== storeMe?.storeActive?.id)
    ?.map((item) => ({
      value: item.id,
      label: item.trading_name,
    }));
  const stock_selected = watch("stock_selected");
  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {

    if(isIntern && !watch("stock_destination_id")?.value) {
      return setError("stock_destination_id", {
        type: "custom",
        message: "Por favor selecione o estoque de destino.",
      });
    } else if (!isIntern && !watch("store_destination_id")?.value) {
      return setError("store_destination_id", {
        type: "custom",
        message: "Por favor selecione a loja de destino.",
      });
    }

    if(tableProducts.length === 0) return notifyError("Adicione pelo menos um produto para continuar!")
    const objTransfer = {
      ...estoque,
      store_id: storeMe?.storeActive?.id,
      stock_transfer_type_id: isIntern ? 1 : 2,
      stock_destination_id: data?.stock_destination_id?.value  ?? null,
      store_destination_id: data?.store_destination_id?.value ?? null,
      stock_uuid: stock_selected?.uuid,
      note: data?.note,
      products: tableProducts,
    };
     
      if(!editando) {
        dispatch(createStockTransferAsync(objTransfer)).then((result: any) => {
          if (createStockTransferAsync.fulfilled.match(result)) {
            dispatch(getStockTransferAsync({store_id:storeMe?.storeActive?.id}));
            handleCancelar()
          } else if (result) {
            notifyError(result?.payload?.response?.data?.message  || "Erro ao adicionar item.");
          }
        });
        
      } else {
        dispatch(updateStockTransferAsync(objTransfer)).then((result: any) => {
          if (updateStockTransferAsync.fulfilled.match(result)) {
            dispatch(getStockTransferAsync({store_id:storeMe?.storeActive?.id}));
            handleCancelar()
          } else if (result) {
            notifyError( result?.payload?.response?.data?.message  || "Erro ao adicionar item.");
          }
        });
      }
      
      setValue("stock_destination_id", {});
      setError("stock_destination_id", {
        type: "custom",
        message: "",
      });
      setValue("store_destination_id", {});
      setError("store_destination_id", {
        type: "custom",
        message: "",
      });
      reset()
    
  };

  function handleCancelar() {
    OnAddNewEstoque(false);
    dispatch(addItemTable([]));
  }

  useEffect(() => {
    dispatch(getStockLocationAsync(storeMe?.storeActive?.id));
    dispatch(getStockAllLocationAsync(storeMe?.storeActive?.id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProductsAsync({idStock:stock_selected?.value , store_id:storeMe?.storeActive?.id}));
  }, [dispatch, stock_selected]);

  useEffect(() => {
    setValue("stock_destination_id", {});
    
    setStocksfiltered(
      stockAlllocation?.filter((item) => item.id !== stock_selected?.value)
    );
  }, [stock_selected?.value]);  


  
  return (
    <>

      <LayoutHookForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={title}
        isEditable={editando}
        onClickCancel={handleCancelar}
        stateForm={stateForm}
        //isDisable={isDisable}
      >
        <Row>
          <Col className="buttonColumns mb-3 mt-3" md="3">
            <label className="mb-1 mx-2">Tipo de Transferência</label>
            <Button
              size="sm"
              type="button"
              variant={isIntern ? "success" : "primary"}
              onClick={() => setIsIntern(!isIntern)}
            >
              {!isIntern ? "EXTERNA" : "INTERNA"}
            </Button>
          </Col>
        </Row>

        <h3>Local Estoque</h3>
        <S.Wrapper>
          {isIntern ? (
            <>
              <SelectHook
                register={register}
                control={control}
                label="Origem"
                defaultValue={stocklocation?.map((item) => ({
                  value: item.id,
                  label: item.name.toLocaleUpperCase(),
                  uuid: item.uuid
                }))[stocklocation.length - 1]}
                name="stock_selected"
                placeholder="Local de estoque origem"
                options={stocklocation?.map((item) => ({
                  value: item.id,
                  label: item.name.toLocaleUpperCase(),
                  uuid: item.uuid
                }))}
                errors={inputError}
              />
              <SelectHook
                register={register}
                control={control}
                label="Destino"
                defaultValue={[]}
                name="stock_destination_id"
                placeholder="Local de estoque destino"
                isDisabled={stock_selected?.value ? false : true}
                options={stocksfiltered?.map((item) => ({
                  value: item.id,
                  label: item.name.toLocaleUpperCase(),
                }))}
                errors={inputError}
              />
            </>
          ) : (
            <>
              <SelectHook
                register={register}
                control={control}
                label="Origem"
                defaultValue={[]}
                name="stock_selected"
                placeholder="Local de estoque origem"
                options={stocklocation?.map((item) => ({
                  value: item.id,
                  label: item.name.toLocaleUpperCase(),
                }))}
                errors={inputError}
              />
              <SelectHook
                register={register}
                control={control}
                label="Loja"
                defaultValue={[]}
                name="store_destination_id"
                placeholder="Loja de destino"
                options={stores}
                errors={inputError}
              />
            </>
          )}
          <InputHook
            register={register}
            errors={inputError}
            label="Observação"
            name="node"
            placeholder="Observação"
            type="text"
          />
        </S.Wrapper>
          <CadProduct  stockTransfer={stock_selected}/>
      </LayoutHookForm>

      {stateForm.state === "loading" && <Loading />}
    </>
  );
}
