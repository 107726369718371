import { Button, Col, Modal, Row } from "react-bootstrap";
import { InputDefault } from "../../../../data/inputDefault";
import React, { useState } from "react";
import { useAppSelector } from "../../../../../redux/store";
import api from "../../../../../Services/api";
import Globals from "../../../../../Globals.json";
import { notify, notifyError } from "../../../../notify/notify-component";
import GetEndPoint from "../../../../../Services/GetEndPoint";
import * as S from "./styled";

interface IProps {
  show: boolean;
  setShow: any;
  setLoading: any;
  setMeasureUnitList: any;
}

export default function ModalUnit({
  show,
  setShow,
  setLoading,
  setMeasureUnitList,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [nome, setNome] = useState<string>("");
  const [sigla, setSigla] = useState<string>("");
  const [casas, setCasas] = useState<number>(0);
  const [nomeInValid, setNomeInValid] = useState<boolean>(false);
  const [siglaInValid, setSiglaInValid] = useState<boolean>(false);
  const [casasInValid, setCasasInValid] = useState<boolean>(false);
  const [unidade, setUnidade] = useState<any>();

  const handleClose = () => {
    setShow(false);
    clear();
  };

  const clear = () => {
    setNome("");
    setSigla("");
    setCasas(0);
    setNomeInValid(false);
    setSiglaInValid(false);
    setCasasInValid(false);
    setUnidade(undefined);
  };

  const handleNome = (item: any) => {
    setNome(item);
    if (item.length > 2) {
      setUnidade({ ...unidade, name: item });
      setNomeInValid(false);
      return;
    }
    setNomeInValid(true);
  };
  const handlSigla = (item: any) => {
    setSigla(item);
    if (item.length > 0 && item.length <= 3) {
      setUnidade({ ...unidade, initials: item });
      setSiglaInValid(false);
      return;
    }
    setSiglaInValid(true);
  };
  const handleCasas = (item: any) => {
    setCasas(item);
    if (item > 0) {
      setUnidade({ ...unidade, decimal_places: item });
      setCasasInValid(false);
      return;
    }
    setCasasInValid(true);
  };

  const verify = (): boolean => {
    let result: boolean = true;
    if (nome.length < 3) {
      result = false;
      setNomeInValid(true);
    }
    if (casas === 0) {
      result = false;
      setCasasInValid(true);
    }
    if (!sigla) {
      result = false;
      setSiglaInValid(true);
    }
    return result;
  };
  const handleSave = () => {
    if (verify()) {
      const data = { ...unidade, store_id: storeMe?.storeActive?.id };

      doAddNew(data);
    }
  };

  const doAddNew = (unidade: any) => {
    setLoading(true);
    api
      .post(Globals.api.measure, unidade)
      .then((response) => {
        if (response.status === 201) {
          notify("Unidade cadastrada com sucesso");
          GetEndPoint.productMeasureUnits(setMeasureUnitList, storeMe?.storeActive?.id);
          handleClose();
          clear();
        } else {
          notifyError("Não foi possivel cadastrar a unidade");
        }
      })
      .catch((error: Error) => {
        notifyError(`Ocorreu um erro ${error.message}`);
        console.error("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Nova unidade de medida </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <S.Wrapper>
          <InputDefault
            label="Nome"
            type="text"
            autoComplete="off"
            state={nome}
            placeholder="Nome"
            maxLength={50}
            isInvalid={nomeInValid}
            onChenge={(e) => handleNome(e.target.value)}
          />

          <InputDefault
            label="Sigla"
            type="text"
            autoComplete="off"
            state={sigla}
            placeholder="Sigla"
            maxLength={3}
            isInvalid={siglaInValid}
            onChenge={(e) => handlSigla(e.target.value.toUpperCase())}
          />

          <InputDefault
            label="Qtd casas decimais"
            type="number"
            autoComplete="off"
            state={casas}
            isInvalid={casasInValid}
            maxLength={2}
            onChenge={(e) => handleCasas(parseInt(e.target.value))}
          />
        </S.Wrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={handleSave} type="button">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
