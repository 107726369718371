import * as Yup from "yup";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as S from "./styles";
import { InputHook } from "../../../reactHookForm/InputHook";
import { setStateForm } from "../../../../redux/features/userProfile/user-profile-slice";
import { notifyError } from "../../../notify/notify-component";
import { IFormPerfilAcesso } from "../../../../Pages/Cadastro/CadPerfilAcesso";
import { perfilAccsessSchema } from "../../../../redux/features/userAccess/schema";
import { SelectHook } from "../../../reactHookForm/SelectHook";
import {
  createUserAccessAsync,
  editeUserAccessAsync,
  getUserAccessAsync,
} from "../../../../redux/features/userAccess/services";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

type FormData = Yup.InferType<typeof perfilAccsessSchema>;

export function FormPerfilAcesso({
  show,
  setShow,
  listEditeItem,
  setListEditeItem,
}: IFormPerfilAcesso) {
  const dispatch = useDispatch<AppDispatch>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const store = useAppSelector(({ storeReducer }) => storeReducer.value);
  const objectStore = storeMe?.user?.relationships?.stores || [];
  const { stateForm } = useAppSelector(
    ({ userProfileReducer }) => userProfileReducer.value
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<any>({ resolver: yupResolver(perfilAccsessSchema) });

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    dispatch(setStateForm({ state: "loading" }));
   
    if (listEditeItem) {
      const newDataEdit = {
        ...data,
        name: data.name,
        description: data.description,
      };
      dispatch(editeUserAccessAsync(newDataEdit)).then((result: any) => {
        if (editeUserAccessAsync.fulfilled.match(result)) {
          dispatch(getUserAccessAsync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao atualizar item.");
        }
      });

      dispatch(setStateForm({ state: "default" }));
      handleClose();
      return;
    }
    const newData = {
      name: data.name,
      description: data.description,
      store_id: storeMe?.storeActive?.id,
    };
    dispatch(createUserAccessAsync(newData)).then((result: any) => {
      if (createUserAccessAsync.fulfilled.match(result)) {
        dispatch(getUserAccessAsync(storeMe?.storeActive?.id));
      } else {
        notifyError("Erro ao adicionar item.");
      }
    });
    handleClose()
    dispatch(setStateForm({ state: "default" }));
  };

  const resetItens = () => {
    setValue("description", "");
    setValue("name", "");
    setValue("store_id", "");

    reset({});
  };

  const handleClose = () => {
    setShow(false);
    setListEditeItem(undefined);
    resetItens();
  };

  const inputError = [errors];

  useEffect(() => {
    if (listEditeItem && show) {

      reset(listEditeItem);
      setValue(
        "store_id",
        objectStore.find((item: any) => item.label === listEditeItem?.store_id)
      );
    } else {
      resetItens();
    }
  }, [show]);
  

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ fontSize: "12px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{listEditeItem ? "Editar " : "Adicionar "} Perfil de Acesso</h3>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <S.Wrapper>
            <InputHook
              register={register}
              errors={inputError}
              label="Perfil"
              name="name"
              placeholder="Nome do perfil"
              type="text"
            />
           
         
            <InputHook
              register={register}
              errors={inputError}
              label="Descrição"
              name="description"
              placeholder="Descrição"
              type="text"
            />
          </S.Wrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" type="submit">
            {listEditeItem ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
