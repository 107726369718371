import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Row, Form, Col, Button } from "react-bootstrap/";
import { ToastContainer } from "react-toastify";
import Loading from "../../../Loading/Loading";
import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";
import globals from "../../../../Globals.json";
import { notify, notifyError } from "../../../notify/notify-component";
import { getChartAccount } from "../../../../Services/chart-accounts-service";
import { getPaymentType } from "../../../../Services/payment-type";
import { createFinancialTransaction } from "../../../../Services/financial-transaction";
import Format from "../../../../Services/Format";
import { getReceiver } from "../../../../Services/people-service";
import { getTransactionCategories } from "../../../../Services/transaction-categories-service";
import { getBankAccounts } from "../../../../Services/bank-accounts";
import { Badge } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { addDays, format } from "date-fns";
import { addMonths } from "date-fns";
import { ITransacao } from "../../Interface/ITransacao";
import api from "../../../../Services/api";
import Layout from "../../../data/Layout";
import { InputDefault } from "../../../data/inputDefault";
import ReactSelect from "../../../data/reactSelect";
import InputCode from "../../../data/inputCode";
import { InputInternText } from "../../../data/inputInternText";
import { convertDatePTbr } from "../../../../utils";
import { useAppSelector } from "../../../../redux/store";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { getfinancialTransactionAsync } from "../../../../redux/features/financialTransaction/services";
import { sortByCreated } from "../../../../Services/sortByCreated";
import * as S from "./styled";
import ReactSelectFornecedor from "../../../data/reactSelectFornecedor";
import { IoPeopleSharp } from "react-icons/io5";
import ModalSupplier from "../../../Cadastro/Produtos/FormProduto/ModalSupplier";
import ModalReceiver from "./ModalReceiver";

export function FormTransacao({
  transacao,
  Title,
  EntryType,
  Label,
  Placeholder,
  onAddNewTransaction,
  OnClear,
  editando,
  dispatch,
}: ITransacao) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [loading, setLoading] = useState<boolean>(false);

  const [descricao, setDescricao] = useState<string>("");
  const [categories, setCategories] = useState<number>(0);
  const [note, setNote] = useState<string>("");
  const [chartAccountId, setChartAccountId] = useState<number>(0);
  const [receiver, setReceiver] = useState<number>();
  const [amountValue, setAmountValue] = useState<number | undefined>();
  const [documento, setDocumento] = useState<number>();
  const [issueDate, setIssueDate] = useState<string>(
    Format.date(new Date(), "YYYY-MM-DD")
  );
  const [dueDate, setDueDate] = useState<string>(
    Format.date(new Date(), "YYYY-MM-DD")
  );
  const [pendingValue, setPendingValue] = useState<number>(0);

  const [installments, setInstallments] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [banckAccountId, setBanckaccountId] = useState<number>(0);
  const [paymentTypeId, setPaymentTypeId] = useState<number>(0);
  const [numberInstallments, setNumberInstallments] = useState<number>(0);
  const [dueDateInstallments, setDueDateInstallments] = useState<string>(
    Format.date(new Date(), "YYYY-MM-DD")
  );
  const [amountInstallments, setAmountInstallments] = useState<number>(0);

  const [disableNumberInstallments, setDisableNumberInstallments] =
    useState<boolean>(false);

  const [disableAmountInstallments, setDisableAmountInstallments] =
    useState<boolean>(false);

  const [dataInstallments, setDataInstallments] = useState<any[]>([]);
  const [showDataTable, setShowDataTable] = useState<boolean>(false);

  const [listChartAccount, setListChartAccount] = useState<Array<any>>([]);
  const [listCategories, setListCategories] = useState<Array<any>>([]);
  const [listReceiver, setListReceiver] = useState<Array<any>>([]);
  const [listPaymentType, setListPaymentType] = useState<Array<any>>([]);
  const [listBankaccount, setListBankAccount] = useState<Array<any>>([]);

  const [methodType, setMethodType] = useState<number>(7);
  const [listMethod, setListMethod] = useState<Array<any>>([]);
  const [show, setShow] = useState<boolean>(false);

  const disable =
    transacao?.status === 2 ||
    transacao?.relationships?.stockMovement?.situation_id === 2;
  addDays(new Date(dueDateInstallments), 1);
  const columns = [
    {
      name: "Número da parcela",
      selector: (row: any) => row?.number,
    },
    {
      name: "Data de vencimento",
      center: true,
      selector: (row: any) => convertDatePTbr(row?.due_date),
    },
    {
      name: "Valor",
      selector: (row: any) =>
        "R$ " + Number(row?.amount).toFixed(2).toString().replace(".", ","),
    },
    {
      name: "Forma de Recebimento",
      selector: (row: any) =>
        listPaymentType
          ? listPaymentType.find((item) => item.value === row?.payment_type_id)
              .label
          : "",
    },
    {
      name: "Status",
      selector: (row: any) => {
        if (row.status === 2 || row.status === 3) {
          return <Badge>Fechada</Badge>;
        } else if (row.status === 1) {
          return <Badge>Aberto</Badge>;
        }
      },
    },
  ];

  const objMethod = [
    {
      id: 1,
      value: "DIARIO",
    },
    {
      id: 7,
      value: "SEMANAL",
    },
    {
      id: 15,
      value: "QUINZENAL",
    },
    {
      id: 30,
      value: "MENSAL",
    },
  ];

  const fillPaymentType = async () => {
    await getPaymentType(storeMe?.storeActive?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      const filterByActivedStore = data.filter(
        (item: any) => item.store_id === storeMe?.storeActive?.id
      );
      const filterPaymentType = filterByActivedStore.filter(
        (paymentType: any) => paymentType.entry_type === EntryType
      );

      let list = filterPaymentType.map((item: any) => ({
        value: item.id,
        label: item.description,
        store_id: item.store_id,
      }));
      setListPaymentType(list);
    });
  };

  const fillMethod = async () => {
    let list = objMethod.map((item: any) => ({
      value: item.id,
      label: item.value,
      store_id: item.store_id,
    }));
    setListMethod(list);
  };

  const fillcategories = async () => {
    await getTransactionCategories(storeMe?.storeActive?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      const filterByActivedStore = data.filter(
        (item: any) => item.store_id === storeMe?.storeActive?.id
      );

      const filterRevenue = filterByActivedStore.filter(
        (revenue: any) => revenue.entry_type === EntryType
      );

      let list = filterRevenue.map((item: any) => ({
        value: item.id,
        label: item.description,
        store_id: item.store_id,
      }));
      setListCategories(list);
    });
  };

  const fillChartAccount = async () => {
    await getChartAccount(storeMe?.storeActive?.id).then((response) => {
      const data = sortByCreated(response.data.data);

      const filterByActivedStore = data.filter(
        (item: any) => item.store_id === storeMe?.storeActive?.id
      );

      const filterRevenue = filterByActivedStore.filter(
        (revenue: any) => revenue.entry_type === EntryType
      );

      let list = filterRevenue.map((item: any) => ({
        value: item.id,
        label: item.description,
        store_id: item.store_id,
      }));
      setListChartAccount(list);
    });
  };

  const fillReceiver = async () => {
    await getReceiver(storeMe?.storeActive?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      const filterByActivedStore = data.filter(
        (item: any) => item.store_id === storeMe?.storeActive?.id
      );

      let list = filterByActivedStore.map((item: any) => ({
        value: item.id,
        label: item.name,
        store_id: item.store_id,
      }));
      setListReceiver(list);
    });
  };

  const fillBankAccounts = async () => {
    await getBankAccounts(storeMe?.storeActive?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      const filterByActivedStore = data.filter(
        (item: any) => item.store_id === storeMe?.storeActive?.id
      );
      let list = filterByActivedStore.map((item: any) => ({
        value: item.id,
        label: item.description,
        store_id: item.store_id,
      }));
      setListBankAccount(list);
    });
  };

  function handleCancelar() {
    onAddNewTransaction(false);
    OnClear(true);
  }

  async function handleSubmit() {
    if (
      !descricao ||
      !chartAccountId ||
      !categories ||
      !receiver ||
      !dueDate ||
      !paymentTypeId ||
      !banckAccountId ||
      !amountValue ||
      !documento
    ) {
      return notifyError("Por favor preencher todos os campos.");
    }

    if (installments) {
      if (dataInstallments.length === 0)
        return notifyError(
          'Por favor gere as parcelas antes de enviar ou remova a opção de "Irá se repetir"'
        );

      if (
        !banckAccountId ||
        !paymentTypeId ||
        !numberInstallments ||
        !dueDateInstallments ||
        !amountInstallments
      ) {
        return notifyError("Por favor preencher todos os campos.");
      }

      const oModel = {
        uuid: transacao?.uuid ? transacao.uuid : null,
        store_id: storeMe?.storeActive?.id,
        chart_account_id: chartAccountId,
        financial_transaction_category_id: categories,
        status: status ? 2 : 1,
        description: descricao,
        receiver_people_id: receiver,
        document: documento.toString(),
        note: note,
        creation_date: issueDate,
        //...(!transacao?.uuid && { document: documento.toString() }),
        installments: dataInstallments,
      };

      await createFinancialTransaction(oModel).then((res: any) => {
        setLoading(true);
        if (!res) {
          setTimeout(() => {
            notifyError("Ocorreu um erro");
            setLoading(false);
          }, 100);
        } else {
          if (status === true) {
            for (let i = 0; i < res.data.length; i++) {
              try {
                api.post(
                  `${globals.api.financialTransaction}/${res.data[i].uuid}/entry`,
                  {
                    amount: res.data[i].amount,
                    payment_type_id: res.data[i].payment_type_id,
                    bank_account_id: res.data[i].bank_account_id,
                    must_close: false,
                    discount: res.data[i].discount ? res.data[i].discount : 0,
                    interest: res.data[i].interest ? res.data[i].interest : 0,
                  }
                );
              } catch (error) {
                console.log("erro", error);
              }
            }
          }

          setTimeout(() => {
            dispatch(getfinancialTransactionAsync(storeMe?.storeActive?.id));
            handleCancelar();
            setLoading(false);
          }, 100);
        }
      });
    } else {
      let dueDateAdd = addDays(new Date(dueDate), 1);
      const dataFormatadaDue_date = format(dueDateAdd, "yyyy-MM-dd");
      const oModel = {
        uuid: transacao?.uuid ? transacao.uuid : null,
        store_id: storeMe?.storeActive?.id,
        chart_account_id: chartAccountId,
        financial_transaction_category_id: categories,
        status: status ? 2 : 1,
        description: descricao,
        receiver_people_id: receiver,
        due_date: dataFormatadaDue_date,
        amount: amountValue,
        document: documento.toString(),
        creation_date: issueDate,
        //...(!transacao?.uuid && { document: documento.toString() }),
        note: note,
        bank_account_id: banckAccountId,
        payment_type_id: paymentTypeId,
      };

      await createFinancialTransaction(oModel).then((res: any) => {
        setLoading(true);

        if (!res) {
          setTimeout(() => {
            notifyError("Ocorreu um erro");
            setLoading(false);
            return;
          }, 100);
        } else {
          if (status === true) {
            try {
              api.post(
                `${globals.api.financialTransaction}/${res.data.uuid}/entry`,
                {
                  amount: res.data.amount,
                  payment_type_id: res.data.payment_type_id,
                  bank_account_id: res.data.bank_account_id,
                  must_close: false,
                  discount: res.data.discount ? res.data.discount : 0,
                  interest: res.data.interest ? res.data.interest : 0,
                }
              );
            } catch (error) {
              console.log("erro", error);
            }
          }

          setTimeout(() => {
            dispatch(getfinancialTransactionAsync(storeMe?.storeActive?.id));
            handleCancelar();
            setLoading(false);
          }, 100);
        }
      });
    }
  }

  const handleAmmountValue = (e: any) => {
    setAmountValue(Number.parseFloat(e.target.value));
    setPendingValue(Number.parseFloat(e.target.value));
  };

  const handleInstallments = () => {
    if (!paymentTypeId || !amountValue)
      return notifyError("Por favor preencha todos os campos");
    setInstallments(!installments);
    setDataInstallments([]);
    setNumberInstallments(0);
    setAmountInstallments(0);
    setMethodType(0);
  };

  const handleSituation = () => {
    setStatus(!status);
  };

  const handleNumberInstallments = (e: any) => {
    setNumberInstallments(parseInt(e.target.value));
    setDisableAmountInstallments(true);
  };

  const handleAmountInstallments = (e: any) => {
    setAmountInstallments(parseFloat(e.target.value));
    setDisableNumberInstallments(true);
  };

  const numberGenerator = (e: any) => {
    e.preventDefault();
    let max = 999;
    let min = 100;
    let value = Math.random() * (max - min) + min;

    setDocumento(Math.trunc(value));
  };

  const getNumberInstallments = () => {
    let newDueDateInstallments = addDays(new Date(dueDateInstallments), 1);

    setLoading(true);

    if (numberInstallments) {
      let installmentsData: any = [];

      for (let i = numberInstallments; i < numberInstallments + 1; i++) {
        let initialI = 0;
        for (let i = 1; i < numberInstallments; i++) {
          if (methodType && amountInstallments) {
            installmentsData.push({
              bank_account_id: banckAccountId,
              number: i,
              due_date:
                methodType === 30
                  ? format(
                      addMonths(newDueDateInstallments, initialI),
                      "yyyy-MM-dd"
                    )
                  : format(
                      addDays(newDueDateInstallments, initialI * methodType),
                      "yyyy-MM-dd"
                    ),

              amount: amountInstallments,
              payment_type_id: listPaymentType.find(
                (item) => item.value === paymentTypeId
              ).value,
              status: status ? 2 : 1,
            });
          }
          initialI += 1;
        }
        if (numberInstallments % 1 !== 0) {
          let decimalPart = numberInstallments - Math.floor(numberInstallments);

          if (amountInstallments && amountValue && methodType) {
            let newAmountInstallments = amountInstallments * decimalPart;
            installmentsData.push({
              bank_account_id: banckAccountId,
              number: Math.trunc(i + 1),
              due_date:
                methodType === 30
                  ? format(
                      addMonths(newDueDateInstallments, initialI),
                      "yyyy-MM-dd"
                    )
                  : format(
                      addDays(newDueDateInstallments, initialI * methodType),
                      "yyyy-MM-dd"
                    ),

              amount: newAmountInstallments,
              payment_type_id: listPaymentType.find(
                (item) => item.value === paymentTypeId
              ).value,
              status: status ? 2 : 1,
            });
          }
          setNumberInstallments(Math.ceil(numberInstallments));
          setLoading(false);
        } else {
          if (amountInstallments && amountValue && methodType) {
            installmentsData.push({
              bank_account_id: banckAccountId,
              number: i,
              due_date:
                methodType === 30
                  ? format(
                      addMonths(newDueDateInstallments, initialI),
                      "yyyy-MM-dd"
                    )
                  : format(
                      addDays(newDueDateInstallments, initialI * methodType),
                      "yyyy-MM-dd"
                    ),
              amount:
                amountValue - amountInstallments * (numberInstallments - 1),
              payment_type_id: listPaymentType.find(
                (item) => item.value === paymentTypeId
              ).value,
              status: status ? 2 : 1,
            });
          }
        }
        initialI += 1;
      }

      setDataInstallments(installmentsData);
    }

    setLoading(false);
  };

  const handleAddInstallments = () => {
    if (!methodType)
      return notifyError("Por favor informe o Período de parcelamento.");

    if (!dueDateInstallments)
      return notifyError("Por favor informe data de vencimento.");

    if (!numberInstallments && !amountInstallments)
      return notifyError("Por favor informe o número ou valor da parcela.");

    setDataInstallments([]);

    if (numberInstallments) {
      if (disableAmountInstallments === true) {
        if (amountValue && numberInstallments) {
          if (amountValue) {
            let newAmountValue = amountValue / numberInstallments;
            setAmountInstallments(Number(newAmountValue.toFixed(2)));
            setPendingValue(amountValue - amountValue);
          }
        }
      }
    }

    if (amountInstallments) {
      if (disableNumberInstallments === true) {
        if (amountValue && amountInstallments) {
          setNumberInstallments(amountValue / amountInstallments);
          if (amountValue && numberInstallments) {
            let newAmountValue = amountValue / numberInstallments;
            setAmountInstallments(Number(newAmountValue.toFixed(2)));
            setPendingValue(amountValue - amountValue);
          }
        }
      }
    }
    setDisableAmountInstallments(true);
    setDisableNumberInstallments(true);
    setShowDataTable(true);
  };

  const HandleClearInstallments = () => {
    setDataInstallments([]);
    setShowDataTable(false);
    setNumberInstallments(0);
    setAmountInstallments(0);
    setMethodType(0);
  };

  useEffect(() => {
    fillPaymentType();
    fillChartAccount();
    fillReceiver();
    fillcategories();
    fillBankAccounts();
    fillMethod();

    if (transacao) {
      setDescricao(transacao.description);
      setCategories(transacao.financial_transaction_category_id);
      setChartAccountId(transacao.chart_account_id);
      setReceiver(transacao.receiver_people_id);
      setDocumento(transacao?.document);
      setDueDate(transacao.due_date);
      setStatus(transacao.status === 2 ? true : false);
      setPaymentTypeId(transacao.payment_type_id);
      setNote(transacao.note);
      setBanckaccountId(transacao.bank_account_id);
      setAmountValue(transacao.amount);
      setIssueDate(transacao.creation_date);
    }
  }, []);

  useEffect(() => {
    getNumberInstallments();
    let time1 = setTimeout(async () => {
      if (!numberInstallments) setDisableAmountInstallments(false);
    }, 1700);
    let time2 = setTimeout(() => {
      if (!amountInstallments) setDisableNumberInstallments(false);
    }, 1700);

    return () => {
      clearTimeout(time1);
      clearTimeout(time2);
    };
  }, [amountInstallments, numberInstallments, status]);

  useEffect(() => {
    setLoading(true);
    let time1 = setTimeout(() => {
      if (dataInstallments.length > 1) {
        setShowDataTable(true);
      }
    }, 1000);
    setLoading(false);
    return () => {
      clearTimeout(time1);
    };
  }, [listPaymentType]);

  return (
    <>
      <ModalReceiver
        show={show}
        setShow={setShow}
        setLoading={setLoading}
        setSuplier={setListReceiver}
      />
      <Layout
        title={Title}
        isEditable={editando}
        onClickSave={handleSubmit}
        onClickEdit={handleSubmit}
        onClickCancel={handleCancelar}
        onlyView={disable}
      >
        <Row>
          &nbsp;
          <br />
          <h4 className="fs-6">Informações básicas </h4>
        </Row>
        <S.Wrapper4>
          {editando ? (
            <InputDefault
              label="Descrição"
              disabled={true}
              type="text"
              placeholder="Descrição"
              setState={setDescricao}
              state={documento ? descricao + " Nº" + documento : descricao}
            />
          ) : (
            <InputDefault
              label="Descrição"
              disabled={disable}
              type="text"
              placeholder="Descrição"
              setState={setDescricao}
              state={descricao}
            />
          )}

          {disable ? (
            <InputDefault
              label="Categoria"
              disabled={true}
              type="text"
              state={
                categories && listCategories?.length > 0
                  ? listCategories?.filter(
                      (option: any) => option.value === categories
                    )?.[0]?.label
                  : ""
              }
            />
          ) : (
            <ReactSelect
              label="Categoria"
              state={categories}
              isClearable={true}
              placeholder={"Categoria"}
              options={listCategories}
              setState={setCategories}
            />
          )}

          {disable ? (
            <InputDefault
              label="Plano"
              disabled={true}
              type="text"
              state={
                chartAccountId && listChartAccount.length > 0
                  ? listChartAccount?.filter((option: any) =>
                      option.value === chartAccountId
                        ? option.value === chartAccountId
                        : [0]
                    )?.[0]?.label
                  : ""
              }
            />
          ) : (
            <ReactSelect
              label="Plano"
              state={chartAccountId}
              isClearable={true}
              placeholder={"Plano de Contas"}
              options={listChartAccount}
              setState={setChartAccountId}
            />
          )}

          {disable ? (
            <InputDefault
              label="Favorecido"
              disabled={true}
              type="text"
              state={
                receiver && listReceiver?.length > 0
                  ? listReceiver?.filter(
                      (option: any) => option?.value === receiver
                    )?.[0]?.label
                  : ""
              }
            />
          ) : (
            <ReactSelectFornecedor
              label="Favorecido"
              state={receiver}
              isClearable={true}
              placeholder={"Favorecido"}
              options={listReceiver}
              setState={setReceiver}
              setShow={setShow}
              icon={<IoPeopleSharp />}
            />
          )}
        </S.Wrapper4>

        <Row>
          &nbsp;
          <br />
          <h4 className="fs-6">Documento </h4>
        </Row>
        <S.Wrapper>
          {disable ? (
            <InputDefault
              label={"Código"}
              state={documento}
              type="number"
              disabled={transacao ? true : false}
            />
          ) : (
            <InputCode
              label={"Código"}
              state={documento}
              onClick={numberGenerator}
              type="number"
              placeholder="Gerar codigo"
              disabled={transacao ? true : false}
            />
          )}

          {transacao?.installment_number > 0 && (
            <InputDefault
              label="Nº Parcela"
              disabled
              type="string"
              state={transacao?.installment_number}
            />
          )}

          <InputDefault
            label="Emissão"
            disabled={disable}
            type="date"
            placeholder="dd/mm/aa"
            state={issueDate}
            setState={setIssueDate}
          />

          <InputDefault
            label="Vencimento"
            disabled={disable}
            type="date"
            placeholder="dd/mm/aa"
            state={dueDate}
            setState={setDueDate}
          />

          {disable ? (
            <InputDefault
              label="Conta"
              disabled={true}
              type="text"
              state={
                banckAccountId && listBankaccount?.length > 0
                  ? listBankaccount?.filter(
                      (option: any) => option.value === banckAccountId
                    )?.[0]?.label
                  : ""
              }
            />
          ) : (
            <ReactSelect
              label="Conta"
              state={banckAccountId}
              isClearable={true}
              placeholder={"Conta"}
              options={listBankaccount}
              setState={setBanckaccountId}
            />
          )}

          {disable ? (
            <InputDefault
              label={Label}
              disabled={true}
              type="text"
              state={
                paymentTypeId && listPaymentType?.length > 0
                  ? listPaymentType?.filter(
                      (option: any) => option.value === paymentTypeId
                    )?.[0]?.label
                  : ""
              }
            />
          ) : (
            <ReactSelect
              label={Label}
              state={paymentTypeId}
              isClearable={true}
              placeholder={Placeholder}
              options={listPaymentType}
              setState={setPaymentTypeId}
            />
          )}

          <InputDefault
            label="Valor Total"
            disabled={disable}
            autoComplete="off"
            type="number"
            step="0.1"
            placeholder="Valor Tttal"
            min="0"
            onChenge={handleAmmountValue}
            state={amountValue}
          />
        </S.Wrapper>

        <Row>
          <Col md="2" className="d-flex align-items-center gap-2 mt-3">
            <label>Marcar como pago</label>
            <input
              type="checkbox"
              disabled={disable}
              checked={status}
              onChange={handleSituation}
            />
          </Col>
          {!transacao?.uuid && (
            <Col md="2" className="d-flex align-items-center gap-2 mt-3">
              <label>Irá se repetir</label>
              <input
                type="checkbox"
                disabled={disable}
                checked={installments}
                onChange={handleInstallments}
              />
            </Col>
          )}
        </Row>

        <Row className="container-data mt-4">
          <Col>
            <Form.Label>Descreva sua observação</Form.Label>
            <Form.Control
              style={{
                border: "1px solid var(--color-input-border)",
                boxShadow: "var(--color-input-shadow)",
              }}
              disabled={disable}
              as="textarea"
              rows={3}
              value={note}
              onChange={(e: any) => setNote(e.target.value)}
            />
          </Col>
        </Row>

        {(dataInstallments.length > 1 || installments) && (
          <>
            <Row>
              &nbsp;
              <br />
              <h4 className="fs-6">Parcelas </h4>
            </Row>
            <S.Wrapper>
              <ReactSelect
                label="Período"
                state={methodType}
                isClearable={true}
                placeholder={"Período"}
                options={listMethod}
                setState={setMethodType}
              />

              <InputDefault
                label="Parcelas"
                disabled={disableNumberInstallments}
                type="number"
                placeholder="Nº"
                min="0"
                max="400"
                onChenge={handleNumberInstallments}
                state={numberInstallments}
              />

              <InputDefault
                label="Valor"
                disabled={disableAmountInstallments}
                type="number"
                step="0.1"
                placeholder="0,00"
                min="0"
                onChenge={handleAmountInstallments}
                state={amountInstallments}
              />

              <InputDefault
                label="Vencimento"
                type="date"
                placeholder="dd/mm/aa"
                state={dueDateInstallments}
                setState={setDueDateInstallments}
              />

              <InputDefault
                label="Valor Pendente"
                type="number"
                step="0.1"
                placeholder="0,00"
                min="0"
                disabled={true}
                state={pendingValue}
              />
            </S.Wrapper>

            <S.BtnAdd>
              {!showDataTable && (
                <Button variant="success" onClick={handleAddInstallments}>
                  Adicionar
                </Button>
              )}
            </S.BtnAdd>

            <Col md="3"></Col>

            <Row md="12" className="mt-3">
              <Col>
                {showDataTable && (
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={HandleClearInstallments}
                  >
                    <i className="fa fa-trash"></i>Excluir Parcelas
                  </Button>
                )}
              </Col>
            </Row>

            {showDataTable && (
              <Col md="12" className="mt-4">
                {dataInstallments.length > 0 ? (
                  <DataTable
                    className={styles.dataTable}
                    columns={columns}
                    data={dataInstallments || []}
                    striped
                    customStyles={tableStyleDefault}
                  />
                ) : (
                  <h4 className="text-center">Nenhuma parcela registrada.</h4>
                )}
              </Col>
            )}
          </>
        )}
      </Layout>

      {loading && <Loading />}

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
