import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  gap: 1rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  @media only screen and (max-width: 600px) {
    margin-top: 0.5rem;
  }

  button {
    display: flex;
    gap: 0.5rem;
    border: none;
    background-color: #8c8c8c;
    color: white;
    padding: 0 10px 0 0;
    align-items: center;
    font-weight: bold;
    transition: all 0.5s ease-in-out;

    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    :hover {
      opacity: 0.8;

      :disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    span {
      background-color: #0b5ed7;
      padding: 5px 10px;
      margin-right: 5px;
    }
  }
`;
