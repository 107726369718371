import { useRef, useState } from "react";
import { StateForm } from "../../interfaces/state-form";

import * as Yup from "yup";

import { InputForm } from "../../Components/data/inputsWeb/Input";
import Spinner from "../../Components/spinner/Spinner";
import * as S from "./styles";
import { YupValidation } from "../../Services/yup-validation";
import { Link, useHistory } from "react-router-dom";
import Globals from "./../../Globals.json";
import { forgotSchema } from "../../schemas/forgotSchema";
import api from "../../Services/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputHook } from "../../Components/reactHookForm/InputHook";
import { InputHookDefal } from "../../Components/reactHookForm/InputHookDefal";

export const emailRecoverySchema = Yup.object().shape({
  email: Yup.string()
    .email("Por favor, insira um email válido.")
    .required("Por favor, insira um email."),
});
interface IFormInput {}
type FormData = Yup.InferType<typeof emailRecoverySchema>;

interface ILogin {
  email: string;
}

export default function Login() {
  const history = useHistory();
  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(emailRecoverySchema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    try {
      setStateForm({ state: "loading" });

      const { email } = data;

      await api.post(Globals.api.resetPassword, { email });

      setTimeout(() => {
        history.push(Globals.paths.login);
      }, 2000);
      setStateForm({
        state: "send",
        message: "E-mail enviado com as instruções para redefinir sua senha.",
      });
    } catch (err: any) {
        setStateForm({
          state: "error",
          message: err?.response?.data?.error || "api_error_please_contact_support",
        });
    }
  };

  const inputError = [errors];

  return (
    <S.Container>
      <S.Login>
        <S.Card>
          <h2>Recuperar senha</h2>
          <p>
            Insira o endereço de email associado à sua conta abaixo. Clique no
            botão "Enviar" e nós enviaremos imediatamente um email para esse
            endereço. No email que você receber, encontrará instruções
            detalhadas sobre como redefinir sua senha com segurança.
          </p>
          {stateForm.state === "error" && (
            <p className="error">{stateForm.message}</p>
          )}
          {stateForm.state === "send" && (
            <p className="success">{stateForm.message}</p>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputHookDefal
              register={register}
              errors={inputError}
              name="email"
              type="email"
              placeholder="E-mail"
              label="Email"
            />
            {
              <S.Button>
                {stateForm.state === "loading" ? (
                  <S.PositionSpinner>
                    <Spinner button />
                  </S.PositionSpinner>
                ) : (
                  "Enviar"
                )}
              </S.Button>
            }
            <div className="forgot">
              <span>
                <Link to={Globals.paths.login}>Voltar para login</Link>
              </span>
            </div>
          </form>
        </S.Card>
      </S.Login>
    </S.Container>
  );
}
