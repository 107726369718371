import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1px;

  .top {
    h3 {
      font-size: 13px;
      color: gray;
      span{
        font-size: 11px;
        margin-left: 0.5rem;
        color: #c6c4c4;
        font-weight: normal;
      }
    }

    p {
      margin: 1rem 2rem;
    }
  }

  .borderTop {
    border-top: 1px solid #B3B3B3;
    padding: 2rem 0;
  }

  .bottom {
    h3 {
      font-size: 13px;
      color: gray;
    }

    p {
      font-size: 13px;
      margin-left: 1.5rem;
      margin-top: 20px;
    }
    .grid-section {
      margin-left: 1.5rem;
      color: gray;

      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;

export const WrapperInput = styled.div`
  margin-top: 1.5rem;
  margin-right: 5rem;
  margin-bottom: 1rem;
  @media only screen and (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
    gap: 0.3rem;
    align-self: center;
    height: auto;
    margin-top: 1.5rem;

    .value-label {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .flex {
        display: flex;
      }
    }

    .initial-value {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .final_value {
      input {
        width: 110px;
      }
    }

    label > .label {
      text-align: right;
      margin-right: 0.5rem;
    }
  }

  .label {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
  }

  label {
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: left;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      .label {
        display: none;
      }
    }
  }

  .wrapper-date {
    display: grid;
    grid-template-columns: 1fr;
  }

  .btnAdd {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1rem;
  }
`;
