import styled from "styled-components";

export const WrapperImg = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  button {
    border: 1px solid white;
    color: white;
    height: 1.8rem;
  }
`;

export const Wrapper = styled.div`
  margin: 1rem 1rem;

  .check {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  @media only screen and (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    gap: 0.3rem;

    .value-label {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .flex {
        display: flex;
      }
    }

    .initial-value {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .final_value {
      input {
        width: 110px;
      }
    }

    label > .label {
      text-align: right;
      margin-right: 0.5rem;
    }
  }

  .label {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
  }

  label {
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: left;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      .label {
        display: none;
      }
    }
  }

  .wrapper-date {
    display: grid;
    grid-template-columns: 1fr;
  }

  .btnAdd {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1rem;
  }
`;

export const Wrapper4 = styled.div`
  margin: 1rem 1rem;

  @media only screen and (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    gap: 0.3rem;

    .value-label {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .flex {
        display: flex;
      }
    }

    .initial-value {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .final_value {
      input {
        width: 110px;
      }
    }

    label > .label {
      text-align: right;
      margin-right: 0.5rem;
    }
  }

  .label {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
  }

  label {
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: left;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      .label {
        display: none;
      }
    }
  }

  .wrapper-date {
    display: grid;
    grid-template-columns: 1fr;
  }

  .btnAdd {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1rem;
  }
`;

export const BtnAdd = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 0rem;
  padding-right: 1rem;
`;

export const Title = styled.div`
  border-bottom: 1px solid #dee2e6;
  font-size: 16px;
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
`;

export const Balance = styled.div`
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: end;
  margin-right: 1rem;
  font-size: 14px;

  ul {
    list-style: none;
    margin-top: 0.5rem;

    li {
      display: flex;
      margin-bottom: 0.2rem;
      justify-content: space-between;
      gap: 8rem;
      padding-right: 1rem;
    }

    .total {
      border-top: 1px solid #dee2e6;
      font-size: 16px;
      font-weight: bold;
      margin-top: 0.5rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: end;
`;

export const Wrapper2 = styled.div`
  margin: 1rem 1rem;

  @media only screen and (min-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    gap: 0.3rem;

    .value-label {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .flex {
        display: flex;
      }
    }

    .initial-value {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .final_value {
      input {
        width: 110px;
      }
    }

    label > .label {
      text-align: right;
      margin-right: 0.5rem;
    }
  }

  .label {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
  }

  label {
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: left;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      .label {
        display: none;
      }
    }
  }

  .wrapper-date {
    display: grid;
    grid-template-columns: 1fr;
  }

  .btnAdd {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1rem;
  }
`;
