import { useContext, useEffect, useState } from "react";
import { ProdutoContext } from "../../../../context/product";
import GetEndPoint from "../../../../Services/GetEndPoint";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import Layout from "../../../data/Layout";
import { useAppSelector } from "../../../../redux/store";
import * as S from "../styled";

export default function InfoEstoque({ isEdit, isDupli }: { isEdit: any, isDupli:any }) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const {
    unit,
    currentAmount,
    setCurrentAmount,
    minAmount,
    setMinAmount,
    localStock,
    setLocalStock,
    unitMeasureBuy,
    setUnitMeasureBuy,
    multiple,
    setMultiple,
    observation,
    setObservation,
    setLocalStockName,
    setStock_uuid,
    validqty,
    validLocalStock,
    typeOper,
  } = useContext(ProdutoContext);

  const [localStockList, setLocalStockList] = useState<any[]>([]);
  const [measureUnitsList, setMeasureUnitList] = useState<any[]>([]);

  const handlelocalStock = (e: any) => {
    console.log(e);
    
    setLocalStock(e?.value);
    setLocalStockName(e?.label);
    setStock_uuid(e?.uuid);
  };

  useEffect(() => {
    GetEndPoint.productMeasureUnits(setMeasureUnitList, storeMe?.storeActive?.id);
    GetEndPoint.stocks(setLocalStockList, storeMe?.storeActive?.id);
  }, []);

  useEffect(() => {
    let stock = localStockList.find((item: any) => item.value === localStock);
    setStock_uuid(stock?.uuid);
  }, [localStock, localStockList]);

  let newMeasureUnitList = measureUnitsList.filter(
    (item) => item.store_id === storeMe?.storeActive?.id
  );

  useEffect(() => {
      handlelocalStock(localStockList?.find(
          (item: any) => item?.label === "LOCAL PADRÃO"
        ),
      );
  }, [localStockList]);

  

  return (
    <Layout title="Informações de Estoque e Movimentação">
      <S.Wrapper>
        <InputDefault
          label="Qtd. Atual"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={currentAmount}
          setState={setCurrentAmount}
          disabled={isEdit ? true : false}
          isInvalid={validqty}
        />

        <InputDefault
          label="Qtd.  mínima"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={minAmount}
          setState={setMinAmount}
        />

        <ReactSelect
          label="Loc. estoque"
          isClearable
          options={localStockList}
          onChange={handlelocalStock}
          defaultValue={ localStockList?.find(
            (item: any) => item?.label === "LOCAL PADRÃO"
          )?.value}
          state={localStock}
          placeholder="Local de estoque padrão"
          isInvalid={validLocalStock}
          
        />

        <ReactSelect
          label="Uni. compra"
          isClearable
          options={newMeasureUnitList}
          state={unitMeasureBuy ? unitMeasureBuy : unit}
          setState={setUnitMeasureBuy}
          placeholder="Unidade de medida na compra"
        />

        <InputDefault
          label="Multiplicador"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={multiple}
          setState={setMultiple}
        />

        <ReactSelect
          label="Uni. venda"
          isClearable
          options={measureUnitsList}
          state={unit}
          setState={""}
          placeholder="Unidade de medida na venda"
          isDisabled={true}
        />

        {/* 
        
        <Col md="4">
          <InputDefault
            label='Locais e Quantidades'
            placeholder="Sem lançamentos de estoque"
            type='text'
            autoComplete='off'
            maxLength={50}
            disabled={true}
          />
        </Col>
        */}

        <InputDefault
          label="Observação"
          placeholder="Informe a referência de estoque"
          type="text"
          autoComplete="off"
          maxLength={50}
          state={observation}
          setState={setObservation}
        />
      </S.Wrapper>
    </Layout>
  );
}
