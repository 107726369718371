import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Format from "../../../../Services/Format";
import { FaEdit, FaEye } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as S from "./styles";

import Loading from "../../../Loading/Loading";

import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";

import DateUtils from "../../../../Services/DateUtils";
import { IListaMovimEstoque } from "../../../../Pages/Cadastro/CadAjusteEstoque";
import LayoutNotFilter from "../../../data/LayoutNotFilter";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { sortByCreated } from "../../../../Services/sortByCreated";
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { IStokeAjust } from "../../Interface/IStokeAjust";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { NoData } from "../../../noData";
import CustomLoader from "../../../data/customLoader";
import { useAppSelector } from "../../../../redux/store";
import { notifyError } from "../../../notify/notify-component";

export default function ListaAjusteEstoque({
  title,
  OnAddNewCli,
  onEditItem,
}: IListaMovimEstoque) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [listAdjust, setListAdjust] = useState<Array<any>>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showAjuste, setShowAjuste] = useState<IStokeAjust | undefined>();
  const [arrayStore, setArrayStore] = useState<any[]>([]);
  const [arrayMovimentStock, setArrayMovimentStock] = useState<any>([]);
  const [arrayProduct, setArrayProduct] = useState<any[]>([]);

  const handleClose = () => setModalIsOpen(false);

  const handleShow = (row: IStokeAjust) => {
    setShowAjuste(row);
    setModalIsOpen(true);
  };

  const handlePageChange = (page: any) => {
    getAdjusts(page);
  };

  const columns = [
    {
      name: "Nome do Ajuste",
      sortable: true,
      selector: (row: any) => row.note.toLowerCase(),
    },
    {
      name: "Data de Criação",
      center: true,
      grow: 0.3,
      selector: (row: any) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Estoque",
      center: true,
      grow: 0.4,
      selector: (row: any) => row.relationships?.stock.name.toLowerCase(),
    },
    {
      name: "Ação",
      center: true,
      grow: 0.2,
      cell: (row: any) => (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Visualizar</Tooltip>}
        >
          <span className="d-inline-block">
            <Button
              onClick={() => handleShow(row)}
              variant="contained"
              color="primary"
            >
              <FaEye size={16} color="8e8d8d" />
            </Button>
          </span>
        </OverlayTrigger>
      ),
      button: true,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };
  const handleAdd = () => {
    OnAddNewCli(true);
  };

  const fillProperties = (data: any) => {
    const meta = data.meta;
    const perpage =
      data.data.length < meta.per_page ? data.data.length : meta.per_page;
    setPerPage(perpage);
    setTotalRows(meta.total);
    setLastPage(meta.last_page);
    setListAdjust(sortByCreated(data.data));
  };

  const getAdjusts = (filter?: string) => {
    const uri =
      filter !== undefined
        ? `${filter}&paginate=false&store_id=${storeMe?.storeActive?.id}`
        : `paginate=false&store_id=${storeMe?.storeActive?.id}`;

    setLoading(true);
    api
      .get(Globals.api.stockAdjusts + `?${uri}`)
      .then((response) => {
        const data = response.data;

        setListAdjust(sortByCreated(data.data));
      })
      .catch((error) => {
        notifyError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAdjusts();
    GetEndPoint.store(setArrayStore, storeMe?.storeActive?.id);
  }, []);

  useEffect(() => {
    if (showAjuste?.relationships?.stock_movement?.uuid) {
      GetEndPoint.movimentStockByUuid(
        setArrayMovimentStock,
        showAjuste?.relationships?.stock_movement?.uuid
      );
    }
  }, [showAjuste]);

  return (
    <>
      <LayoutNotFilter title={title} onClickAdd={handleAdd}>
        <div className="mt-3" />
        <DataTable
          columns={columns}
          data={listAdjust || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutNotFilter>

      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: "#ced0d4f4" }}>
          <S.Title>{showAjuste?.note}</S.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f7f7f8f4" }}>
          <S.Wrapper>
            <ul>
              <li>
                <span>Loja: </span>{" "}
                {
                  arrayStore.find(
                    (item) =>
                      item.value ===
                      showAjuste?.relationships?.stock_movement?.store_id
                  )?.label
                }
              </li>
              <li>
                <span>Local de estoque: </span>{" "}
                {showAjuste?.relationships?.stock?.name}
              </li>
              <li>
                <span>Produto: </span>{" "}
                {
                  arrayMovimentStock?.relationships?.products[0]?.relationships
                    ?.product?.sale_name
                }
              </li>
              <li>
                <span>Codigo de Barras: </span>{" "}
                {
                  arrayMovimentStock?.relationships?.products[0]?.relationships
                    ?.product?.barcode
                }
              </li>
              <li>
                <span>Codigo: </span>{" "}
                {
                  arrayMovimentStock?.relationships?.products[0]?.relationships
                    ?.product?.code
                }
              </li>
              <li>
                <span>Quantidade Atualizada: </span>{" "}
                {arrayMovimentStock?.relationships?.products[0]?.qty}
              </li>
            </ul>
          </S.Wrapper>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#f7f7f8f4" }}></Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
