import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const getUserSystemAsync = createAsyncThunk(
  "get/user-system",
  async (activedStoreId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.user + `?paginate=false&store_id=${activedStoreId}`
      );
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createUserSystemAsync = createAsyncThunk(
  "create/user-system",
  async (data: any, thunkAPI) => {
    try {
      const creatUser = {
        password: "a123#",
        password_confirmation: "a123#",
        name: data.name,
        email: data.email,
      };
      
      const res = await api.post(globals.api.user, creatUser);

      const creatRole = {
        user_id: res.data.data.id,
        role_id: data?.role_id,
        store_id: data.storeId,
      };

      const resRole = await api.post(globals.api.userRole, creatRole);


      //create rota bind
      return res.data.data | resRole.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editeUserSystemAsync = createAsyncThunk(
  "put/user-system",
  async (data: any, thunkAPI) => {
    try {
      const creatUser = {
        name: data.name,
        email: data.email,
      };
      
      const res = await api.put(globals.api.user + `/${data.uuid}`, creatUser);

      const creatRole = {
        user_id: res.data.data.id,
        role_id: data?.role_id,
        store_id: data.storeId,
      };

    
      const resRole = await api.put(globals.api.userRole + `/${data.uuid}`, creatRole);

      return res.data.data ;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPermissionsAsync = createAsyncThunk(
  "get-permissions",
  async (activedStoreId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.permissions + `?paginate=false&store_id=${activedStoreId}`
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const deleteUserSystemAsync = createAsyncThunk(
  "delete/user-system",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.user + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
