import { notifyError } from "../Components/notify/notify-component";
import globals from "../Globals.json";
import api from "./api";

export const createStockAdjust = (objStockAdjust: any) => {
  if (objStockAdjust.uuid) {
    return api
      .post(`${globals.api.stocks}/${objStockAdjust.uuid}/adjusts`, {
        store_id: objStockAdjust.store_id,
        note: objStockAdjust.note,
        products: objStockAdjust.products,
      })
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
      });
  } else {
    return api
      .post(`${globals.api.stockAdjusts}`, objStockAdjust)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
      });
  }
};

export const getStockAdjusts = () => {
  return api.get(`${globals.api.stockAdjusts}`);
};
