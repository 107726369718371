import { useState, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Format from "../../../Services/Format";
import { addDays, addMonths, format } from "date-fns";
import { getPaymentType } from "../../../Services/payment-type";
import { getChartAccount } from "../../../Services/chart-accounts-service";
import { getBankAccounts } from "../../../Services/bank-accounts";
import DataTable from "react-data-table-component";
import ReactSelect from "../reactSelect";
import { InputDefault } from "../inputDefault";
import tableStyleDefault from "../../../Services/tableStyleDefault";
import { getTransactionCategories } from "../../../Services/transaction-categories-service";
import { FormHandles } from "@unform/core";
import { notifyError } from "../../notify/notify-component";
import { InputInternText } from "../inputInternText";
import { useAppSelector } from "../../../redux/store";
import * as S from "../../Estoque/styled";

interface IParcelamento {
  total: number;
  entryType: number;
  dataInstallments: any;
  setDataInstallments: any;
  aProducts: any;
  banckAccountId: any;
  setBanckaccountId: any;
  paymentTypeId: any;
  setPaymentTypeId: any;
  isvalidBanck: any;
  isvalidPayment: any;
  parcelamento: any;
  setParcelamento: any;
  chart_account_id: any;
  setChart_account_id: any;
  financial_transaction_category_id: any;
  setFinancial_transaction_category_id: any;
  due_date: any;
  setDue_date: any;
  isvalidChartAccount: any;
  isvalidTransitionCategory: any;
  onlyView: boolean;
  status: boolean;
  setStatus: any;
}

export default function Parcelamento({
  total,
  entryType,
  dataInstallments,
  setDataInstallments,
  aProducts,
  banckAccountId,
  setBanckaccountId,
  paymentTypeId,
  setPaymentTypeId,
  isvalidBanck,
  isvalidPayment,
  parcelamento,
  setParcelamento,
  due_date,
  setDue_date,
  financial_transaction_category_id,
  setFinancial_transaction_category_id,
  chart_account_id,
  setChart_account_id,
  isvalidChartAccount,
  isvalidTransitionCategory,
  onlyView,
  status,
  setStatus,
}: IParcelamento) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [loading, setLoading] = useState<boolean>(false);

  const [numberInstallments, setNumberInstallments] = useState<number>(0);
  const [amountInstallments, setAmountInstallments] = useState<number>(0);

  const [disableNumberInstallments, setDisableNumberInstallments] =
    useState<boolean>(false);

  const [disableAmountInstallments, setDisableAmountInstallments] =
    useState<boolean>(false);

  const [showDataTable, setShowDataTable] = useState<boolean>(false);

  const [listChartAccount, setListChartAccount] = useState<Array<any>>([]);
  const [listTrasactionCatecory, setListTrasactionCatecory] = useState<
    Array<any>
  >([]);
  const [listPaymentType, setListPaymentType] = useState<Array<any>>([]);
  const [listBankaccount, setListBankAccount] = useState<Array<any>>([]);

  const [methodType, setMethodType] = useState<number>(30);
  const [listMethod, setListMethod] = useState<Array<any>>([]);

  const [chartId, setChartId] = useState<number>();
  const [newPaymentList, setNewPaymentList] = useState<Array<any>>([]);

  addDays(new Date(due_date), 1);
  const columns = [
    {
      name: "Conta",
      selector: (row: any) =>
        listBankaccount
          ? listBankaccount.find((item) => item.value === row?.bank_account_id)
              .label
          : "",
    },
    {
      name: "Número da parcela",
      selector: (row: any) => row?.number,
    },
    {
      name: "Data de vencimento",
      center: true,
      selector: (row: any) => Format.date(addDays(new Date(row?.due_date), 1)),
    },
    {
      name: "Valor",
      selector: (row: any) =>
        "R$ " + Number(row?.amount).toFixed(2).toString().replace(".", ","),
    },
    {
      name: "Forma de Recebimento",
      selector: (row: any) =>
        listPaymentType
          ? listPaymentType.find((item) => item.value === row?.payment_type_id)
              .label
          : "",
    },
  ];

  const objMethod = [
    {
      id: 1,
      value: "DIARIO",
    },
    {
      id: 7,
      value: "SEMANAL",
    },
    {
      id: 15,
      value: "QUINZENAL",
    },
    {
      id: 30,
      value: "MENSAL",
    },
  ];

  const fillPaymentType = async () => {
    await getPaymentType(storeMe?.storeActive?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);

      const filterStore = data.filter(
        (item: any) => item.store_id === storeMe?.storeActive?.id
      );
      const filterPaymentType = !onlyView
        ? filterStore.filter(
            (paymentType: any) => paymentType.entry_type === entryType
          )
        : data;

      let list = filterPaymentType.map((item: any) => ({
        value: item.id,
        label: item.description,
        chart_account_id: item.chart_account_id,
      }));
      setListPaymentType(list);
    });
  };

  const fillMethod = async () => {
    let list = objMethod.map((item: any) => ({
      value: item.id,
      label: item.value,
    }));
    setListMethod(list);
  };

  const fillChartAccount = async () => {
    await getChartAccount(storeMe?.storeActive?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);


      const filterRevenue = !onlyView
        ? data.filter((revenue: any) => revenue.entry_type === entryType)
        : data;

      let list = filterRevenue.map((item: any) => ({
        value: item.id,
        label: item.description,
        id: item.id,
      }));
      setListChartAccount(list);
    });
  };

  const fillTransactionCategory = async () => {
    await getTransactionCategories(storeMe?.storeActive?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      const filterStore = data.filter(
        (item: any) => item.store_id === storeMe?.storeActive?.id
      );
      const category = !onlyView
        ? filterStore.filter((revenue: any) => revenue.entry_type === entryType)
        : data;

      let list = category.map((item: any) => ({
        value: item.id,
        label: item.description,
      }));
      setListTrasactionCatecory(list);
    });
  };

  const fillBankAccounts = async () => {
    await getBankAccounts(storeMe?.storeActive?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      const filterStore = data.filter(
        (item: any) => item.store_id === storeMe?.storeActive?.id
      );
      let list = filterStore.map((item: any) => ({
        value: item.id,
        label: item.description,
      }));
      setListBankAccount(list);
    });
  };

  const handleNumberInstallments = (e: any) => {
    setNumberInstallments(parseInt(e.target.value));
    setDisableAmountInstallments(true);
  };

  const handleAmountInstallments = (e: any) => {
    setAmountInstallments(parseFloat(e.target.value));
    setDisableNumberInstallments(true);
  };

  const getNumberInstallments = () => {
    //let newDueDateInstallments = new Date(due_date);
    let newDueDateInstallments = addDays(new Date(due_date), 1);

    setLoading(true);

    if (numberInstallments) {
      let installmentsData: any = [];

      for (let i = numberInstallments; i < numberInstallments + 1; i++) {
        let initialI = 0;
        for (let i = 1; i < numberInstallments; i++) {
          if (methodType && amountInstallments) {
            installmentsData.push({
              bank_account_id: banckAccountId,
              number: i,
              installment_group: 0,
              due_date:
                methodType === 30
                  ? format(
                      addMonths(newDueDateInstallments, initialI),
                      "yyyy-MM-dd"
                    )
                  : format(
                      addDays(newDueDateInstallments, initialI * methodType),
                      "yyyy-MM-dd"
                    ),
              amount: amountInstallments,
              payment_type_id: listPaymentType.find(
                (item) => item.value === paymentTypeId
              ).value,
              //parcelamento: parcelamento ? 2 : 1,
            });
          }
          initialI += 1;
        }
        if (numberInstallments % 1 !== 0) {
          let decimalPart = numberInstallments - Math.floor(numberInstallments);

          if (amountInstallments && total && methodType) {
            let newAmountInstallments = amountInstallments * decimalPart;
            installmentsData.push({
              bank_account_id: banckAccountId,
              number: Math.trunc(i + 1),
              installment_group: 0,
              due_date:
                methodType === 30
                  ? format(
                      addMonths(new Date(newDueDateInstallments), initialI),
                      "yyyy-MM-dd"
                    )
                  : format(
                      addDays(
                        new Date(newDueDateInstallments),
                        initialI * methodType
                      ),
                      "yyyy-MM-dd"
                    ),
              amount: newAmountInstallments,

              payment_type_id: listPaymentType.find(
                (item) => item.value === paymentTypeId
              ).value,
              //parcelamento: parcelamento ? 2 : 1,
            });
          }
          setNumberInstallments(Math.ceil(numberInstallments));
          setLoading(false);
        } else {
          if (amountInstallments && total && methodType) {
            installmentsData.push({
              bank_account_id: banckAccountId,
              number: i,
              installment_group: 0,
              due_date:
                methodType === 30
                  ? format(
                      addMonths(new Date(newDueDateInstallments), initialI),
                      "yyyy-MM-dd"
                    )
                  : format(
                      addDays(
                        new Date(newDueDateInstallments),
                        initialI * methodType
                      ),
                      "yyyy-MM-dd"
                    ),
              amount: total - amountInstallments * (numberInstallments - 1),

              payment_type_id: listPaymentType.find(
                (item) => item.value === paymentTypeId
              ).value,
              //parcelamento: parcelamento ? 2 : 1,
            });
          }
        }
        initialI += 1;
      }
      setDataInstallments(installmentsData);
    }

    setLoading(false);
  };

  const handleAddInstallments = () => {
    if (!total) return notifyError("Por favor adicione pelo menos um item.");

    if (!due_date) return notifyError("Por favor informe data de vencimento.");

    if (!paymentTypeId)
      return notifyError(
        `Por favor informe ${
          entryType === 1 ? "forma de recebimento" : "forma de pagamento"
        }`
      );

    if (!banckAccountId)
      return notifyError(`Por favor informe conta bancaria.`);

    if (!methodType)
      return notifyError("Por favor informe o Período de parcelamento.");

    if (!numberInstallments && !amountInstallments)
      return notifyError("Por favor informe o número ou valor da parcela.");

    setDataInstallments([]);

    if (numberInstallments) {
      if (disableAmountInstallments === true) {
        if (total && numberInstallments) {
          if (total) {
            let newAmountValue = total / numberInstallments;
            setAmountInstallments(Number(newAmountValue.toFixed(2)));
          }
        }
      }
    }

    if (amountInstallments) {
      if (disableNumberInstallments === true) {
        if (total && amountInstallments) {
          setNumberInstallments(total / amountInstallments);
          if (total && numberInstallments) {
            let newAmountValue = total / numberInstallments;
            setAmountInstallments(Number(newAmountValue.toFixed(2)));
          }
        }
      }
    }
    setDisableAmountInstallments(true);
    setDisableNumberInstallments(true);
    setShowDataTable(true);
  };

  const HandleClearInstallments = () => {
    setDataInstallments([]);
    setShowDataTable(false);
    setNumberInstallments(0);
    setAmountInstallments(0);
    setMethodType(30);
  };

  useEffect(() => {
    fillChartAccount();
    fillBankAccounts();
    fillMethod();
  }, []);

  useEffect(() => {
    fillPaymentType();
    fillChartAccount();
    fillTransactionCategory();
  }, [entryType]);

  useEffect(() => {
    getNumberInstallments();
    let time1 = setTimeout(async () => {
      if (!numberInstallments) setDisableAmountInstallments(false);
    }, 1700);
    let time2 = setTimeout(() => {
      if (!amountInstallments) setDisableNumberInstallments(false);
    }, 1700);

    return () => {
      clearTimeout(time1);
      clearTimeout(time2);
    };
  }, [amountInstallments, numberInstallments, parcelamento]);

  useEffect(() => {
    setLoading(true);
    let time1 = setTimeout(() => {
      if (dataInstallments.length > 1) {
        setShowDataTable(true);
      }
    }, 1000);
    setLoading(false);
    return () => {
      clearTimeout(time1);
    };
  }, [listPaymentType]);

  useEffect(() => {
    HandleClearInstallments();
  }, [aProducts]);

  useEffect(() => {
    setNewPaymentList([
      ...listPaymentType.filter(
        (item: any) => item.chart_account_id === chartId
      ),
    ]);
  }, [chartId]);

  return (
    <div>
      {!onlyView && (
        <div className="mt-2">
          <Row>
            <h5>Marcar como pago?</h5>
          </Row>
          <Row className="mb-4 mt-2">
            <Col md="4">
              <Button
                style={{ marginRight: "20px" }}
                className={`px-4 py-1 border border-1  ${
                  status ? "bg-success text-white" : "border border-1"
                }`}
                variant="withe"
                onClick={() => setStatus(true)}
              >
                {"Sim"}
              </Button>
              <Button
                className={`px-4 py-1 border border-1  ${
                  !status ? "bg-success text-white" : "border border-1"
                }`}
                variant="withe"
                onClick={() => setStatus(false)}
              >
                {"Não"}
              </Button>
            </Col>
          </Row>
        </div>
      )}

      <S.Wrapper>
        <ReactSelect
          label="Plano"
          isClearable
          placeholder="Plano de Conta"
          options={listChartAccount}
          state={chart_account_id}
          onChange={(e: any) => {
            setChart_account_id(e?.value);
            setChartId(e?.id);
          }}
          isInvalid={isvalidChartAccount}
          isDisabled={onlyView}
        />

        <ReactSelect
          label="Categoria"
          isClearable
          placeholder={"Categoria da Transação"}
          options={listTrasactionCatecory}
          state={financial_transaction_category_id}
          setState={setFinancial_transaction_category_id}
          isInvalid={isvalidTransitionCategory}
          isDisabled={onlyView}
        />

        <InputDefault
          label="Vencimento"
          type="date"
          state={due_date}
          setState={setDue_date}
          disabled={onlyView}
        />

        <ReactSelect
          label="Conta"
          state={banckAccountId}
          setState={setBanckaccountId}
          isClearable
          placeholder={"Conta"}
          options={listBankaccount}
          isInvalid={isvalidBanck}
          isDisabled={onlyView}
        />

        <ReactSelect
          label={entryType === 1 ? "Recebimento" : "Pagamento"}
          state={paymentTypeId}
          setState={setPaymentTypeId}
          isClearable
          placeholder={
            entryType === 1 ? "Forma de recebimento" : "Forma de pagamento"
          }
          options={listPaymentType}
          isInvalid={isvalidPayment}
          isDisabled={onlyView}
        />
      </S.Wrapper>
      {!onlyView && (
        <>
          <Row className="mt-2 mb-2">
            <br />
            <h5>Parcelar</h5>
          </Row>
          <Row className="mb-4">
            <Col md="4">
              <Button
                style={{ marginRight: "20px" }}
                className={`px-4 py-1 border border-1  ${
                  parcelamento ? "bg-success text-white" : "border border-1"
                }`}
                variant="withe"
                onClick={() => setParcelamento(true)}
              >
                {"Sim"}
              </Button>
              <Button
                className={`px-4 py-1 border border-1  ${
                  !parcelamento ? "bg-success text-white" : "border border-1"
                }`}
                variant="withe"
                onClick={() => setParcelamento(false)}
              >
                {"Não"}
              </Button>
            </Col>
          </Row>
        </>
      )}

      {parcelamento && (
        <>
          {!onlyView && (
            <S.Wrapper>
              <ReactSelect
                label="Período"
                state={methodType}
                setState={setMethodType}
                isClearable
                placeholder={"Período"}
                options={listMethod}
              />

              <InputDefault
                label="Parcelas"
                type="number"
                autoComplete="off"
                state={numberInstallments}
                placeholder="Nº"
                maxLength={50}
                onChenge={handleNumberInstallments}
                disabled={disableNumberInstallments}
              />

              <InputDefault
                label="Valor"
                type="number"
                autoComplete="off"
                state={amountInstallments}
                placeholder="0,00"
                maxLength={50}
                onChenge={handleAmountInstallments}
                disabled={disableAmountInstallments}
              />

              <Col md="1" style={{ marginTop: "20px" }}>
                {!showDataTable && (
                  <Button variant="success" onClick={handleAddInstallments}>
                    Adicionar
                  </Button>
                )}
              </Col>

               
               
            </S.Wrapper>
          )}
              <Row md="1">
                <Col>
                  {showDataTable && (
                    <Button
                      variant="secondary"
                      onClick={HandleClearInstallments}
                    >
                      <i className="fa fa-trash"></i>Excluir Parcelas
                    </Button>
                  )}
                </Col>
              </Row>

          {showDataTable && (
            <Col md="12" className="mt-4 mb-4">
              {dataInstallments.length > 0 ? (
                <DataTable
                  columns={columns}
                  data={dataInstallments || []}
                  striped
                  customStyles={tableStyleDefault}
                />
              ) : (
                <Card.Text className="text-center">
                  Nenhuma parcela registrada.
                </Card.Text>
              )}
            </Col>
          )}
        </>
      )}
    </div>
  );
}
