import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { SelectHook } from "../../../../reactHookForm/SelectHook";
import { AppDispatch, useAppSelector } from "../../../../../redux/store";
import {
  ICategorie,
  IProduct,
  IStock,
  IStore,
} from "../../../../../redux/features/inventary/interface";
import { BtnTypeInventary } from "../BtnTypeInventary";
import { BtnInput } from "../../../../reactHookForm/BtnInput";
import { useDispatch } from "react-redux";
import {
  addItensTable,
  deleteItemTable,
  setIncludeInactiveProducts,
  setStateForm,
} from "../../../../../redux/features/inventary/inventary-slice";
import { InputHook } from "../../../../reactHookForm/InputHook";
import { Btn } from "../../../../data/Btn";
import DataTable from "react-data-table-component";
import tableStyleDefault from "../../../../../Services/tableStyleDefault";
import { paginationOptions } from "../../../../data/PagineteInfo";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useEffect, useState } from "react";
import {
  addItemProductTableAsync,
  deleteItemProductTableAsync,
  getCategoriesAsync,
  getProductsAsync,
  getProductsByCategorieAsync,
  getStockAsync,
  putItemProductTableAsync,
} from "../../../../../redux/features/inventary/services";
import Loading from "../../../../Loading/Loading";
import { ToastContainer } from "react-toastify";
import { notify, notifyError } from "../../../../notify/notify-component";
import { getStoreMeAsync } from "../../../../../redux/features/userMe/services";
import ModalBootstrap from "../../../../data/Modal";
import tableStyleDefaulInternal from "../../../../../Services/tableStyleDefaultInternal";
import { InputCheckboxUseStatekbox } from "../../../../reactHookForm/inputCheckboxUseState";
import * as S from "../../../styled";

interface IProps {
  register: unknown;
  control: unknown;
  watch: any;
  setValue: any;
  setError: any;
  errors: unknown;
  reset?: any;
  isEditable: boolean;
  inventaryData?: any;
  contagemName: string | undefined;
}
export function InputClone({
  register,
  control,
  watch,
  setValue,
  setError,
  errors,
  reset,
  isEditable,
  inventaryData,
  contagemName,
}: IProps) {
  const dispatch = useDispatch<AppDispatch>();
  const inventary = useAppSelector(
    ({ inventaryReducer }) => inventaryReducer.value
  );
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const selectedStore = watch("store");
  const selectedStock = watch("stock");
  const selectedProduct = watch("sale_name");
  const selectedProductCode = watch("code");
  const selectedProductBarCode = watch("barcode");
  const selectedCategorie = watch("categorie");
  const costPrice = watch("cost_price");
  const stockQty = watch("stock_qty");
  const qty = watch("qty");

  const [idForEditeItemTable, setIdForEditeItemTable] = useState<string>("");

  const isDissable =
    inventary.InventaryByUuid?.status === 2 ||
    inventary.InventaryByUuid?.status === 3;

  const [isEditeItemTable, setIsEditeItemTable] = useState<boolean>(false);
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [uuid, setUuid] = useState<string>("");

  const columns = [
    {
      name: "Código",
      selector: (row: IProduct) => row?.code,
      maxWidth: "200px",
    },
    {
      name: "Produto",
      selector: (row: IProduct) => row?.sale_name,
      maxWidth: "350px",
    },
    {
      name: "Unidade",
      width: "120px",
      center: true,
      selector: (row: IProduct) => row?.relationships?.sale_measure_unit?.name,
    },
    {
      name: "QTD Atual",
      maxWidth: "140px",
      center: true,
      selector: (row: IProduct) =>
        Number(row?.stock_qty).toFixed(2).replace(".", ","),
    },
    {
      name: "Quantidade",
      maxWidth: "140px",
      center: true,
      selector: (row: IProduct) =>
        Number(row?.qty).toFixed(2).replace(".", ","),
    },
    {
      name: "Diferença",
      maxWidth: "140px",
      center: true,
      selector: (row: IProduct) =>
        Number(row?.stock_qty - row?.qty)
          .toFixed(2)
          .replace(".", ","),
    },

    {
      name: "Ação",
      center: true,

      cell: (row: any) => (
        <>
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
          >
            <span className="d-inline-block">
              <Button
                onClick={() => handleEditeItemTable(row)}
                variant="contained"
                color="primary"
              >
                <FaEdit size={14} color="8e8d8d" />
              </Button>
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Excluir</Tooltip>}
          >
            <span className="d-inline-block">
              <Button
                onClick={() => handleModalId(row.uuidTable, row.id)}
                variant="contained"
                color="primary"
              >
                <FaTrash size={13} color="8e8d8d" />
              </Button>
            </span>
          </OverlayTrigger>
        </>
      ),
      button: true,
    },
  ];

  const columnsOnlyView = [
    {
      name: "Código",
      selector: (row: IProduct) => row?.code,
      maxWidth: "200px",
    },
    {
      name: "Produto",
      selector: (row: IProduct) => row?.sale_name,
      maxWidth: "450px",
    },
    {
      name: "Unidade",
      width: "120px",
      center: true,
      selector: (row: IProduct) => row?.relationships?.sale_measure_unit?.name,
    },
    {
      name: "QTD Atual",
      maxWidth: "140px",
      center: true,
      selector: (row: IProduct) =>
        Number(row?.stock_qty).toFixed(2).replace(".", ","),
    },
    {
      name: "Quantidade",
      maxWidth: "140px",
      center: true,
      selector: (row: IProduct) =>
        Number(row?.qty).toFixed(2).replace(".", ","),
    },
    {
      name: "Diferença",
      maxWidth: "140px",
      center: true,
      selector: (row: IProduct) => {
        const diff = row?.stock_qty - row?.qty;
        return "- " + Number(diff).toFixed(2).replace(".", ",");
      },
    },
  ];

  const handleItemTable = async (product: { value: number; label: string }) => {
    if (!product) {
      setError("sale_name", {
        type: "custom",
        message: "Selecione um produto.",
      });
      setError("code", {
        type: "custom",
        message: "Selecione um produto.",
      });
      setError("barcode", {
        type: "custom",
        message: "Selecione um produto.",
      });
    }
    if (!costPrice) {
      setError("cost_price", {
        type: "custom",
        message: "Informe o custo.",
      });
    }
    if (!qty) {
      setError("qty", {
        type: "custom",
        message: "Informe a quantidade.",
      });
    }

    if (!product || !costPrice || !qty) return;

    let productInTable = inventary.itensTable.find(
      (item: IProduct) => item?.id === product.value
    );

    if (productInTable) {
      notifyError("Produto já foi adicionado.");
      return;
    }

    let itemSelected = inventary.products.find(
      (item: IProduct) => item?.id === product?.value
    );

    const newItem = {
      ...itemSelected,
      cost_price: costPrice,
      stock_qty: stockQty,
      qty: Number(qty),
      product_id: itemSelected ? itemSelected.id : null,
      uuidInventary: inventary?.InventaryByUuid?.uuid,
    };
    
    if (!isEditable) {
      dispatch(addItensTable(newItem));
    } else {
      if (!isEditeItemTable) {
        dispatch(addItemProductTableAsync(newItem)).then((result: any) => {
          if (addItemProductTableAsync.fulfilled.match(result)) {
            const res = result.payload.data;
            const ultimoItem = res[res.length - 1];

            let getProduct = inventary.allProducts.find(
              (item: IProduct) => item?.id === ultimoItem?.product_id
            );

            let newItem = {
              ...getProduct,
              qty: qty,
            };
            dispatch(
              addItensTable({ ...newItem, uuidTable: ultimoItem?.uuid })
            );
          } else {
            notifyError("Erro ao adicionar produto.");
          }
        });
      } else {
        
        const newEdite = {
          qty: Number(qty),
          id: product?.value,
          idForEditeItemTable: idForEditeItemTable,
          uuidInventary: inventary?.InventaryByUuid?.uuid,
        };
        dispatch(putItemProductTableAsync(newEdite)).then(
          async (result: any) => {
            if (putItemProductTableAsync.fulfilled.match(result)) {
              const res = await result.payload.data;

              let getProduct = inventary.allProducts.find(
                (item: IProduct) => item?.id === res?.product_id
              );

              let newItem = {
                ...getProduct,
                qty: qty,
              };

              dispatch(addItensTable({ ...newItem, uuidTable: res?.uuid }));
            } else {
              notifyError("Erro ao atualizar produto.");
            }
          }
        );
      }
    }
    setValue("sale_name", "");
    setValue("code", "");
    setValue("barcode", "");
    setValue("cost_price", "");
    setValue("stock_qty", "");
    setValue("qty", "");
  };

  const handleEditeItemTable = (product: IProduct) => {
    setIdForEditeItemTable(product.uuidTable);
    dispatch(deleteItemTable(product.id));
    setIsEditeItemTable(true);
    setValue("sale_name", { value: product.id, label: product.sale_name });
    setValue("code", { value: product.id, label: product.code });
    setValue("barcode", { value: product.id, label: product.barcode });
    setValue("cost_price", product.cost_price);
    setValue("stock_qty", product.stock_qty);
    setValue("qty", product.qty);
  };

  const storeEditeObject = inventary.stores.find(
    (item: IStore) => item.id === inventary.InventaryByUuid?.store_id
  );

  const stockEditeObject = inventary?.stock?.find(
    (item: IStock) => item.id === inventary.InventaryByUuid?.stock_id
  );



  useEffect(() => {
    if (dispatch) {
      dispatch(getStockAsync(selectedStore?.value));
      dispatch(getCategoriesAsync(selectedStore?.value));
      //setValue("stock", 0);
    }
  }, [selectedStore, dispatch]);

  useEffect(() => {
    if (dispatch) {
      dispatch(getProductsAsync(selectedStock?.value));
    }
  }, [selectedStock, dispatch, inventary.includeInactiveProducts]);

  useEffect(() => {
    if (dispatch) {
      dispatch(getProductsByCategorieAsync({catId:selectedCategorie?.value, storeId:  storeMe?.storeActive?.id}));
    }
  }, [selectedCategorie, dispatch]);

  let productItemByName = inventary.products.find(
    (item: IProduct) => item.id === selectedProduct?.value
  );

  let productItemByCode = inventary.products.find(
    (item: IProduct) => item.id === selectedProductCode?.value
  );

  let productItemByBarCode = inventary.products.find(
    (item: IProduct) => item.id === selectedProductBarCode?.value
  );

  const handleDeleteAsync = (uuid: string, id: string) => {
    dispatch(deleteItemProductTableAsync(uuid)).then((result: any) => {
      if (deleteItemProductTableAsync.fulfilled.match(result)) {
        notify("Produto deletado.");
        dispatch(deleteItemTable(Number(id)));
      } else {
        notifyError("Erro ao deletar produto.");
      }
    });
  };

  const handleModalId = (uuid: string, id: string) => {
    setId(id);
    setUuid(uuid);
    setModalConfirmation(true);
  };

  const handleDeleteItemTable = () => {
    setModalConfirmation(false);
    if (isEditable) {
      handleDeleteAsync(uuid, id);
    } else {
      dispatch(deleteItemTable(id));
    }
  };

  useEffect(() => {
    if (productItemByName) {
      setValue("code", {
        value: productItemByName?.id,
        label: productItemByName?.code,
      });
      setValue("barcode", {
        value: productItemByName?.id,
        label: productItemByName?.barcode,
      });

      setValue("cost_price", productItemByName?.cost_price);
      setValue("stock_qty", productItemByName?.stock_qty);
    }
  }, [productItemByName]);

  useEffect(() => {
    if (productItemByCode) {
      setValue("sale_name", {
        value: productItemByCode?.id,
        label: productItemByCode?.sale_name,
      });
      setValue("barcode", {
        value: productItemByCode?.id,
        label: productItemByCode?.barcode,
      });

      setValue("cost_price", productItemByCode?.cost_price);
      setValue("stock_qty", productItemByCode?.stock_qty);
    }
  }, [productItemByCode]);

  useEffect(() => {
    if (productItemByBarCode) {
      setValue("sale_name", {
        value: productItemByBarCode?.id,
        label: productItemByBarCode?.sale_name,
      });
      setValue("code", {
        value: productItemByBarCode?.id,
        label: productItemByBarCode?.code,
      });

      setValue("cost_price", productItemByBarCode?.cost_price);
      setValue("stock_qty", productItemByBarCode?.stock_qty);
    }
  }, [productItemByBarCode]);

  useEffect(() => {
    setValue("name", inventary?.InventaryByUuid?.name);
  }, [inventary?.InventaryByUuid, dispatch]);

  let initCat = inventary.categories.filter(
    (item) => item.store_id === storeMe?.storeActive?.id
  );

  let newCategories: any = [...initCat];

  newCategories.unshift({
    id: 0,
    name: "Todos",
  });

  useEffect(() => {
    setValue("name", contagemName);
  }, [contagemName]);

  useEffect(() => {
    setValue(
      "store",
      storeMe?.user?.relationships?.stores?.map((item: any) => ({
        value: item.id,
        label: item.company_name || item.trading_name,
      }))[0]
    );
    setValue(
      "stock",
      inventary?.stock?.map((item: IStock) => ({
        value: item.id,
        label: item.name,
      }))[0]
    );
  }, []);

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir o produto?"
        handleAction={handleDeleteItemTable}
      />
      <div
        className="mt-2"
        style={{
          display: "flex",
          justifyContent: "start",
        }}
      >
        <InputCheckboxUseStatekbox
          name="Incluir produtos inativos"
          label="Incluir produtos inativos"
          checked={inventary.includeInactiveProducts}
          setChecked={setIncludeInactiveProducts}
          dispatch={dispatch}
        />
      </div>
      <S.Wrapper>
        <InputHook
          register={register}
          errors={errors}
          label="Inventário"
          name="name"
          type="string"
          placeholder="Nome do inventário"
        />

        <SelectHook
          register={register}
          control={control}
          label="Loja"
          defaultValue={
            isEditable && storeEditeObject
              ? {
                  value: storeEditeObject.id,
                  label:
                    storeEditeObject.company_name ||
                    storeEditeObject.trading_name,
                }
              : storeMe.user.relationships.stores.map((item: any) => ({
                  value: item.id,
                  label: item.company_name || item.trading_name,
                }))[0]
          }
          name="store"
          placeholder="Loja"
          isDisabled={isDissable}
          options={storeMe.user.relationships.stores.map((item: any) => ({
            value: item.id,
            label: item.company_name || item.trading_name,
          }))}
        />

        <SelectHook
          register={register}
          control={control}
          label="Estoque"
          defaultValue={
            isEditable && stockEditeObject
              ? {
                  value: stockEditeObject.id,
                  label: stockEditeObject.name,
                }
              : inventary.stock.map((item: IStock) => ({
                  value: item.id,
                  label: item.name,
                }))[0]
          }
          name="stock"
          placeholder="Estoque"
          isDisabled={isDissable}
          options={inventary.stock.map((item: IStock) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </S.Wrapper>
      {!isDissable && (
        <>
          <hr style={{ width: "100%", marginTop: "2rem" }} />
          <Row>
            <br />
            <h3 className="fs-6 mt-2">Adicionar Itens </h3>
          </Row>

          <S.Wrapper>
            <SelectHook
              register={register}
              control={control}
              label="Categoria"
              defaultValue={
                newCategories.map((item: ICategorie) => ({
                  value: item.id,
                  label: item.name,
                }))[0]
              }
              name="categorie"
              placeholder="Categoria"
              options={newCategories.map((item: ICategorie) => ({
                value: item.id,
                label: item.name,
              }))}
            />

            <SelectHook
              register={register}
              control={control}
              label="Produto"
              defaultValue={[]}
              errors={errors}
              name="sale_name"
              placeholder="Produto"
              options={inventary.products.map((item: IProduct) => ({
                value: item.id,
                label: item.sale_name,
              }))}
            />

            <SelectHook
              register={register}
              control={control}
              label="Código"
              defaultValue={[]}
              errors={errors}
              name="code"
              placeholder="Código"
              options={inventary.products.map((item: IProduct) => ({
                value: item.id,
                label: item.code,
              }))}
            />

            <SelectHook
              register={register}
              control={control}
              label="Cód. barras"
              defaultValue={[]}
              errors={errors}
              name="barcode"
              placeholder="Código de barras"
              options={inventary.products.map((item: IProduct) => ({
                value: item.id,
                label: item.barcode,
              }))}
            />

            <InputHook
              register={register}
              errors={errors}
              label="Últ. custo"
              name="cost_price"
              type="number"
              min="0"
              placeholder="Último custo"
            />

            <InputHook
              register={register}
              errors={errors}
              label="Qtd."
              name="qty"
              placeholder="Quantidade"
              type="number"
              min="0"
            />

            <InputHook
              register={register}
              errors={errors}
              label="Qtd atual"
              name="stock_qty"
              type="number"
              min="0"
              placeholder="Quantidade atual"
              disabled
            />
          </S.Wrapper>
          <S.BtnAdd>
            <div>
              <Btn
                text={!isEditeItemTable ? `Adicionar item` : "Atualizar item"}
                onClick={() =>
                  handleItemTable(
                    selectedProduct?.length === 0 ? undefined : selectedProduct
                  )
                }
                variante="primary"
                icon="plus"
                type="button"
              />
            </div>
          </S.BtnAdd>
        </>
      )}

      {inventary.itensTable.length > 0 && (
        <div className="mx-3 mt-4">
          <Row className="mb-2">
            <br />
            <h3 style={{ fontSize: "16px" }}> Itens </h3>
          </Row>
          <DataTable
            columns={!isDissable ? columns : columnsOnlyView}
            data={inventary?.itensTable || []}
            striped
            pagination
            paginationDefaultPage={1}
            paginationPerPage={25}
            customStyles={tableStyleDefaulInternal}
            paginationComponentOptions={paginationOptions}
          />
        </div>
      )}
      {inventary.loading && <Loading />}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
