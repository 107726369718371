import { useState, useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { IStore } from "../../../Components/Configuracao/interface/store";
import { FormStore } from "../../../Components/Dashboard/Representative/Store/FormStore";
import { ThemeContext } from "../../../context/theme-context";
import GetEndPoint from "../../../Services/GetEndPoint";
import globals from "../../../Globals.json";
import { ListStore } from "../../../Components/Configuracao/Store/ListStore";
import { ListPerfilUsuario } from "../../../Components/Configuracao/PerfilUsuario/ListPerfilUsuario";
import { FormPerfilUsuario } from "../../../Components/Configuracao/PerfilUsuario/FormPerfilUsuario";

interface IPerfilUsuario {}

export interface IListPerfilUsuario {
  title: string;
  onEditItem: (item: IPerfilUsuario) => void;
  OnAddNewCli: (e: boolean) => void;
}

export interface IFormPerfilUsuario {
  title: string;
  OnAddNewStore: (e: boolean) => void;
  perfilUsuario?: any;
  editando: boolean;
  selectOptions?: any;
}

export interface ISelectOptions {}

export default function CadPermissions() {
  const { collapsed } = useContext(ThemeContext);
  const [cad, setCad] = useState<boolean>(false);
  const [toEditPerfil, setToEditPerfil] = useState<{}>({});
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setCad(e);
    setEditando(false);
  };

  const handleEdit = (props: IPerfilUsuario) => {
    setToEditPerfil(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      <Container
        className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
      >
        <FormPerfilUsuario
          title={"Permissões"}
          OnAddNewStore={(efinancial: boolean) => {
            handleNew(efinancial);
          }}
          perfilUsuario={toEditPerfil}
          editando={editando}
        />
      </Container>
    </>
  );
}

/*
<Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListPerfilUsuario
            title="Permissões"
            OnAddNewCli={(e: any) => {
              handleNew(e);
            }}
            onEditItem={(item: IPerfilUsuario) => handleEdit(item)}
          />
        </Container>
*/
