import styled from "styled-components";

interface ICollapse {
  collapse: boolean;
}

export const Sidebar = styled.header<ICollapse>`
  min-height: 100%;
  background-color: #110f23;
  max-width: ${({ collapse }) => (collapse ? "100px" : "270px")};
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: ${({ collapse }) => (collapse ? "1rem" : "0.2rem")};
  align-items: center;

  .btn {
    margin-top: 0.5rem;
    margin-right: 5px;
    width: 270px;
    display: flex;
    justify-content: ${({ collapse }) => (collapse ? "center" : "end")};

    .arrow {
      width: 100%;
    }
  }
`;
