import * as S from "./styles";

interface IProps {
  state?: any;
  setState?: any;
  autoComplete?: "on" | "off";
  type: "string" | "number" | "date" | "text" | "email";
  placeholder?: string;
  maxLength?: number;
  label?: string;
  step?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  size?: "sm" | "lg";
  onBlur?: (e: any) => void;
  onChenge?: (e: any) => void;
  as?: any;
  rows?: number;
  minWidth?: string | undefined;
  price?: boolean;
  percent?: boolean;
  setPrice?: any;
  setSetPercent?: any;
}

export function InputDefaultDiscount({
  state,
  setState,
  label,
  type,
  autoComplete,
  placeholder,
  maxLength,
  step,
  min,
  max,
  disabled,
  isInvalid,
  size,
  onBlur,
  onChenge,
  as,
  rows,
  minWidth,
  price,
  percent,
  setPrice,
  setSetPercent,
}: IProps) {
  const handleForPrice = () => {
    setPrice(true);
    setSetPercent(false);
  };
  const handleForPercent = () => {
    setSetPercent(true);
    setPrice(false);
  };
  return (
    <S.Content>
      <div
        style={{
          display: "flex",
          marginBottom: "3px",
          marginLeft: "3rem",
          marginRight: "3rem",
          justifyContent: "center",
          gap: "1rem",
          top: "-21px",
          position: "relative",
        }}
      >
        <div>Tipo:</div>
        <label
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="label" style={{ marginRight: "5px" }}>
            R$
          </div>
          <input
            title="Desconto por preço(R$)"
            type="checkbox"
            checked={price}
            onChange={handleForPrice}
            style={{ height: "15px" }}
          />
        </label>
        <label style={{ display: "flex", alignItems: "center" }}>
          <div className="label" style={{ marginRight: "5px" }}>
            %
          </div>
          <input
            title="Desconto por porcentagem(%)"
            type="checkbox"
            checked={percent}
            onChange={handleForPercent}
            style={{ height: "15px" }}
          />
        </label>
      </div>
      <label style={{ top: "-23px", position: "relative" }}>
        <div className="label">{label}</div>
        <S.Input minWidth={minWidth}>
          <S.FormInput
            as={as}
            type={type}
            autoComplete={autoComplete}
            value={state}
            placeholder={placeholder}
            maxLength={maxLength}
            onChange={
              setState ? (e: any) => setState(e.target.value) : onChenge
            }
            step={step}
            min={min}
            max={max}
            disabled={disabled}
            isInvalid={isInvalid}
            onBlur={onBlur}
            rows={rows}
          />
        </S.Input>
      </label>
    </S.Content>
  );
}
