import { useContext, useEffect, useState } from "react";
import { Col, Row, Tab, Tabs, Table, Form, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { IFormCategories } from "../../../../Pages/Cadastro/cadCategorias";
import { FaRegTrashAlt } from "react-icons/fa";
import Layout from "../../../data/Layout";
import { InputDefault } from "../../../data/inputDefault";
import ReactSelect from "../../../data/reactSelect";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { AiOutlinePlus } from "react-icons/ai";
import { notifyError } from "../../../notify/notify-component";
import { CreateAndEdit } from "../../../../Services/CreateAndEdit";
import { Printers } from "../Impressoras";
import { CategoryContext } from "../../../../context/category";
import globals from "../../../../Globals.json";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "../../../../redux/store";
import { InfoSales } from "../InfoVenda";
import * as S from "./styled";
import ReactSelectComplement from "../../../data/reactSelectComplement";
import InputImg from "../../../data/inputImg";
import type { ICategorie } from "../../../../redux/features/inventary/interface";

export default function FormCategories({
  OnAddNewCategorie,
  categories,
  title,
  editando,
}: IFormCategories) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const {
    setLoading,
    xdFollowItem,
    xdPrinters,
    nomeCategoria,
    setNomeCategoria,
    complemento,
    setComplemento,
    nomeSubcategoria,
    setNomeSubcategoria,
    perfilFiscal,
    setPerfilFiscal,
    complementList,
    setComplementList,
    listaPerfilFiscal,
    setListaPerfilFiscal,
    listaSubCategoria,
    setListaSubCategoria,
    dataSheetList,
    setDataSheetList,
    nomeCategoriaInValid,
    nomelistaSubCategoriaInValid,
    nomelistalistaPerfilFiscalInValid,
    clearFilds,
    handleValidete,
    handleEdit,
    selfService,
    image,
    setImage,
  } = useContext(CategoryContext);

  function handleSubmit() {
    const valid = handleValidete();

    if (valid === false) {
      notifyError("Por favor preencha todos os campos obrigatórios.");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    const oModel = {
      uuid: categories?.uuid ? categories.uuid : null,
      store_id: storeMe?.storeActive?.id,
      name: nomeCategoria,
      ...(nomeSubcategoria && { parent_id: nomeSubcategoria }),
      tax_profile_id: perfilFiscal,
      xd_printers: xdPrinters,
      xd_follow_item: xdFollowItem,
      product_complements: completmentProduct(dataSheetList),
      self_service: selfService,
      ...(image && { image: image }),
    };

    CreateAndEdit.post(globals.api.categoriesAlt, oModel).then((res: any) => {
      setLoading(true);
      if (!res) {
        setTimeout(() => {
          notifyError("Ocorreu um erro");
          setLoading(false);
        }, 100);
      } else {
        setTimeout(() => {
          handleCancel();
          setLoading(false);
        }, 100);
      }
    });
  }

  function handleCancel() {
    clearFilds();
    OnAddNewCategorie(false);
  }

  const handleAddComplement = (complemento: number) => {
    if (!complemento) {
      return;
    }

    const idInList = dataSheetList?.find(
      (item: any) => item.id === complemento
    );

    if (idInList) {
      notifyError("Completo ja cadastrado");
      return;
    }

    const newList = complementList.find((item) => item.id === complemento);
    setDataSheetList([...dataSheetList, newList]);
  };

  const handleDelectComplement = (id: number) => {
    const newList = dataSheetList?.filter((item: any) => item.code !== id);
    setDataSheetList(newList);
  };

  const completmentProduct = (list: any) => {
    const newList = [];
    for (let index = 0; index < list?.length; index++) {
      newList.push({
        product_id: list[index].id,
      });
    }
    return newList;
  };


  const listaSubCategoriaRemoveChildren: ICategorie[] = listaSubCategoria.filter(
    (item: ICategorie) => item.uuid !== categories?.uuid && item.parent_id === 0
  );

  useEffect(() => {
    GetEndPoint.categories(setListaSubCategoria, storeMe?.storeActive?.id);
    GetEndPoint.taxProfile(setListaPerfilFiscal, storeMe?.storeActive?.id);
    GetEndPoint.complements(setComplementList, storeMe?.storeActive?.id);
  }, []);

  useEffect(() => {
    editando && categories && handleEdit(categories);
  }, []);


  return (
    <>
      <Layout
        title={title}
        isEditable={editando}
        onClickSave={handleSubmit}
        onClickEdit={handleSubmit}
        onClickCancel={handleCancel}
      >
        <S.Content>
          <S.WrapperImg>
            <InputImg state={image} setState={setImage} />
          </S.WrapperImg>
          <S.Wrapper>
            <InputDefault
              label="Categoria"
              type="text"
              autoComplete="off"
              state={nomeCategoria}
              placeholder="Nome da categoria"
              maxLength={50}
              isInvalid={nomeCategoriaInValid}
              setState={setNomeCategoria}
            />

            <ReactSelect
              label="Subcategoria"
              isClearable={true}
              placeholder={"Subcategoria de"}
              options={listaSubCategoriaRemoveChildren}
              state={nomeSubcategoria}
              setState={setNomeSubcategoria}
              isLoading={listaSubCategoria ? false : true}
              //isInvalid={nomelistaSubCategoriaInValid}
            />

            <ReactSelect
              label="Perfil fiscal"
              isClearable={true}
              placeholder={"Associar Perfil fiscal"}
              options={listaPerfilFiscal}
              state={perfilFiscal}
              setState={setPerfilFiscal}
              isInvalid={nomelistalistaPerfilFiscalInValid}
              isLoading={listaPerfilFiscal ? false : true}

            />

            <ReactSelectComplement
              label="Complemento"
              isClearable={true}
              placeholder={"Selecione o Complemento"}
              options={complementList}
              state={complemento}
              setState={setComplemento}
              icon={<AiOutlinePlus />}
              setShow={() => handleAddComplement(complemento)}

            />
          </S.Wrapper>
        </S.Content>

        <Col>
          <Row style={{padding:"0 10rem"}}>
            {dataSheetList?.length > 0 && (
              <Table
                size="sm"
                striped
                hover
                bordered
                responsive
                style={{ marginTop: "30px" }}
              >
                <thead >
                  <tr className={styles.trTable}>
                    <th>Código</th>
                    <th>Descrição</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {dataSheetList?.map((item: any, index: any) => {
                    return (
                      <tr key={index} className={styles.tbTable}>
                        <td style={{ width: "20%" }}>{item.code}</td>
                        <td style={{ width: "60%" }}>
                          {item.label ?? item.sale_name}
                        </td>
                        <td style={{ width: "20%" }} className={styles.trash}>
                          <div
                            onClick={() => handleDelectComplement(item.code)}
                          >
                            <FaRegTrashAlt style={{ fontSize: "14px" }} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Row>
        </Col>

        <Tabs defaultActiveKey="sales" className="mb-3 mt-3">
          <Tab eventKey="sales" title="Vendas">
            <InfoSales />
          </Tab>
        </Tabs>
        <Tabs defaultActiveKey="printers" className="mb-3">
          <Tab eventKey="printers" title="Impressoras">
            <Printers />
          </Tab>
        </Tabs>
      </Layout>

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
