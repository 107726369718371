import { createContext, useState, useEffect, ReactNode } from "react";
import api from "../Services/api";
import { getStoreUuid } from "../Services/auth";
import globals from "../Globals.json";

type ChildrenProps = {
  children: ReactNode;
};

type StoreContextType = {
  store?: number;
  getUserData: () => any;
  storeData: any;
};

const StoreContext = createContext<StoreContextType>({} as StoreContextType);

const uuidStore = getStoreUuid();

function StoreProvider({ children }: ChildrenProps) {
  const [storeData, setStoreData] = useState<any[]>([]);
  const [store, setStore] = useState<number>(32);

  //eslint-disable-next-line no-lone-blocks
  {
    /* 
  const getUserData = async () => {
    try {
      const data = await api.get(`${globals.api.userStores}/${32}`)

      setStoreData(data.data);
      setStore(data.data.id);
    } catch (error) {
      notifyError(error)
    }
  }
  */
  }
  const getUserData = async () => {
    try {
      const { data } = await api.get(`${globals.api.userStores}`);

      setStoreData(data.data.relationships.stores[0]);
      setStore(data.data.relationships.stores[0].id);
      //setStoreData(data.data);
      //setStore(data.data.id);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    //getUserData();
  }, [store]);

  return (
    <StoreContext.Provider value={{ store, getUserData, storeData }}>
      {children}
    </StoreContext.Provider>
  );
}

export { StoreProvider, StoreContext };
