import { FaEdit, FaTrash } from "react-icons/fa";
import ActiveInactiv from "../../../../../data/activeInactive";

import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../../../redux/store";
import ModalBootstrap from "../../../../../data/Modal";
import { notify, notifyError } from "../../../../../notify/notify-component";
import { useState } from "react";
import { IChartAccount } from "../../../../../../redux/features/chartAccount/interface";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import {
  deleteChartAccountsync,
  getChartAccountAsync,
} from "../../../../../../redux/features/chartAccount/services";

interface Props {
  key?: any;
  revenue: IChartAccount;
  handleEdit: any;
  OnAddNewCategorie: (e: boolean) => void;
}

export function TreePDV({ revenue, handleEdit, OnAddNewCategorie }: Props) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();

  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>("");
  const [expand, setExpand] = useState(false);

  const handleDelete = async (uuid: string) => {
    dispatch(deleteChartAccountsync(uuid)).then((result: any) => {
      if (deleteChartAccountsync.fulfilled.match(result)) {
        notify("Plano de conta excluido.");
        dispatch(getChartAccountAsync(storeMe?.storeActive?.id));
      } else {
        notifyError("Erro ao excluir plano de conta.");
      }
    });
  };

  const handleModalUuid = (uuid: string) => {
    setUuid(uuid);
    setModalConfirmation(true);
  };

  const handleDeleteItem = () => {
    setModalConfirmation(false);
    handleDelete(uuid);
  };

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text={
          "Tem certeza de que deseja excluir? Se este item estiver vinculado a algum item do financeiro, ele será apenas desativado."
        }
        handleAction={handleDeleteItem}
      />
      <div
        className="row ItemTree"
        style={{
          paddingTop: "10px",
          paddingLeft: "2rem",
        }}
      >
        <div
          className={`col ${
            revenue?.children?.length >= 2
              ? "subrevenueBackGroup"
              : "revenueBackGroup"
          }`}
        >
          <section
            className="divBackgroundColor"
            style={{ paddingLeft: "10px" }}
          >
            <div className="px-2" style={{ fontSize: "12px" }}>
              {revenue?.description}

              {revenue?.children?.length >= 2 && (
                <button onClick={() => setExpand(!expand)}>
                  {expand ? (
                    <MdKeyboardArrowUp size={20} color="#a1a5b7" />
                  ) : (
                    <MdKeyboardArrowDown size={20} color="#a1a5b7" />
                  )}
                </button>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flex: "1",
                gap: "1rem",
                margin: "0 1rem",
              }}
            >
              <span
                style={{
                  paddingRight: "4rem",
                }}
              >
                {revenue?.is_active ? (
                  <ActiveInactiv status={revenue.is_active} text="Ativo" />
                ) : (
                  <ActiveInactiv status={revenue.is_active} text="Inativo" />
                )}
              </span>
              <button>
                <FaEdit
                  size={15}
                  color="8e8d8d"
                  onClick={() => handleEdit(revenue)}
                />
              </button>

              {revenue?.children?.length >= 2 && (
                <span style={{ width: "22px" }}></span>
              )}
              {revenue?.children?.length === 1 && (
                <button>
                  <FaTrash
                    size={14}
                    color="8e8d8d"
                    onClick={() => handleModalUuid(revenue.uuid)}
                  />
                </button>
              )}

              {!revenue?.children && (
                <button>
                  <FaTrash
                    size={14}
                    color="8e8d8d"
                    onClick={() => handleModalUuid(revenue.uuid)}
                  />
                </button>
              )}
            </div>
          </section>
        </div>

        <div style={{ display: expand ? "block" : "none" }}>
          {revenue?.children?.slice(1)?.map((explore: any, index: number) => (
            <div className="d-flex flex-row-reverse bd-highlight">
              <TreePDV
                key={explore.id}
                revenue={explore}
                OnAddNewCategorie={OnAddNewCategorie}
                handleEdit={handleEdit}
              />
            </div>
          ))}
        </div>
      </div>

      <div style={{ height: "-5px" }}></div>
    </>
  );
}
