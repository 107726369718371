import { useEffect, useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DataTable from "react-data-table-component";
import Format from "../../../../Services/Format";
import { FaEdit } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";
import DateUtils from "../../../../Services/DateUtils";
import {
  HiOutlineDocumentDuplicate,
  HiOutlineDotsVertical,
} from "react-icons/hi";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { InputDefault } from "../../../data/inputDefault";
import ReactSelect from "../../../data/reactSelect";
import LayoutFilter from "../../../data/LayoutFilter";
import LayoutTable from "../../../data/LayoutTable";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { ProdutoContext } from "../../../../context/product";
import { useAppSelector } from "../../../../redux/store";
import { paginationOptions } from "../../../data/PagineteInfo";
import ActiveInactiv from "../../../data/activeInactive";
import CustomLoader from "../../../data/customLoader";
import { Dropdown } from "react-bootstrap";
import * as S from "./styled";
import { notifyError } from "../../../notify/notify-component";
import ReactSelectAuto from "../../../data/reactSelectAuto";
import { fetchAutocomplete } from "../../../../utils/autoCompletedProducts";

export default function ListaProdutos(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { setMinSale, setCoast, setProductsComplement } =
    useContext(ProdutoContext);
  const [productsList, setProductsList] = useState<Array<any>>([]);
  const [name, setName] = useState<{value:number, label:string} | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [typedate, setTypeDate] = useState<number>(0);
  const [category, setCategory] = useState<any>("");
  const [code, setCode] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );
  const [listName, setListName] = useState<Array<any>>([]);
  const [categoriesList, setCategoriesList] = useState<any[]>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [totalRows, setTotalRows] = useState(0);
  const [sortCode, setSortCode] = useState(false);
  const [sortPrice, setSortPrice] = useState(false);
  const [sortCost, setSortCost] = useState(false);
  const [sortQty, setSortQty] = useState(false);
  const [sortSaleName, setSortSaleName] = useState(false);
  const [sortCategory, setSortCategory] = useState(false);
  const [paramSorted, setParamSorted] = useState('');
  const [filter, setFilter] = useState('');





  const listdate = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Data Cadastro" },
  ];

  const handleEdit = (row: any) => {
    if (typeof props.onEditItem === "function" && row !== undefined) {
      props.onDuplicar(false);
      props.onEditando(true);
      props.onEditItem(row);
      props.onNewProduct();
    }
  };

  const handleDuplicate = (row: any) => {
    if (typeof props.onEditItem === "function" && row !== undefined) {
      props.onDuplicar(true);
      props.onEditando(false);
      props.onEditItem(row);
      props.onNewProduct();
      props.setIfDuplicate(true);
    }
  };

  const handleNew = () => {
    setMinSale("0.01");
    setCoast("0.01");
    props.onNewProduct();
  };

  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      return;
    }
    setStartDate(date);
    let dateValid = isLater(date, endDate);
    if (dateValid) {
      return;
    }
  };

  const handleDateEnd = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      return;
    }
    setEndDate(date);
    let dateValid = isLater(startDate, date);
    if (dateValid) {
      return;
    }
  };

  const clearFields = () => {
    setName(null);
    setCode("");
    setCategory("");
    setTypeDate(0);
    setStartDate(Format.firstDate("YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    getData();
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };

  const handleSearchList = () => {
    let filterList = [];
    if (name !== undefined) filterList.push(`[product_name]=${name?.label}`);
    if (category !== "") filterList.push(`[product_category_id]=${category}`);
    if (code) filterList.push(`[code]=${code}`);
    if (typedate === 1) {
      filterList.push(`[created_from]=${startDate}`);
      filterList.push(`[created_until]=${endDate}`);
    }
    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        getData(filter);
        setFilter(filter)
      }
      return;
    }
    getData();
  };

  const handleSortCode = () => {
    const newSort = sortCode ? "sort=code" : "sort=-code";
    setSortCode(!sortCode);
    setParamSorted(newSort); 
  }

  const handleSortPrice = () => {
    const newSort = sortPrice ? "sort=sale_price" : "sort=-sale_price";
    setSortPrice(!sortPrice);
    //setParamPrice(newSort);
    setParamSorted(newSort)
  }

  const handleSortCost = () => {
    const newSort = sortCost ? "sort=cost_price" : "sort=-cost_price";
    setSortCost(!sortCost);
    //setParamCost(newSort); 
    setParamSorted(newSort)
  }

  const handleSortQty = () => {
    const newSort = sortQty ? "sort=qty" : "sort=-qty";
    setSortQty(!sortQty);
    //setParamQty(newSort); 
    setParamSorted(newSort)
  }

  const handleSortSaleName = () => {
    const newSort = sortSaleName ? "sort=sale_name" : "sort=-sale_name";
    setSortSaleName(!sortSaleName);
    //setParamSaleName(newSort); 
    setParamSorted(newSort)
  }

  const handleSortCategory = () => {
    const newSort = sortCategory ? "sort=category_name" : "sort=-category_name";
    setSortCategory(!sortCategory);
    //setParamCategory(newSort); 
    setParamSorted(newSort)
  }

  const columns: any = [
    {
      name: "Código",
      sortable: true,
      sortFunction: handleSortCode,
      width: "88px",
      center: true,
      selector: (row: any) => row.code,
    },
    {
      name: "Produto",
      minWidth: "190px",
      sortable: true,
      sortFunction: handleSortSaleName,
      selector: (row: any) => {
        return (
          <div
            title="Editar"
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleEdit(row)}
          >
            {row.sale_name?.toUpperCase()}
          </div>
        );
      },
    },

    {
      name: "Tipo",
      center: true,
      selector: (row: any) =>
        row?.relationships?.product_type?.product_type_description,
    },
    {
      name: "Operação",
      minWidth: "90px",
      center: true,
      selector: (row: any) =>
        row.relationships?.purpose?.product_purpose_description,
    },

    {
      name: "Custo",
      selector: (row: any) =>
        row.cost_price.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      width: "85px",
      center: true,
      sortable: true,
      sortFunction: handleSortCost,
    },
    {
      name: "Venda",
      selector: (row: any) =>
        row.sale_price.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      width: "85px",
      center: true,
      sortable: true,
      sortFunction: handleSortPrice,
    },
    {
      name: "Categoria",
      center: true,
      //sortable: true,
      //sortFunction: handleSortCategory,
      selector: (row: any) =>
        row.relationships?.category?.name.toLowerCase() ?? "",
    },
    {
      name: "Qtd.",
      selector: (row: any) => row.stock_qty.toFixed(2).replace(".", ","),
      width: "80px",
      center: true,
      //sortable: true,
      //sortFunction: handleSortQty,
    },
    {
      name: "Unidade",
      width: "94px",
      center: true,
      selector: (row: any) =>
        row.relationships?.sale_measure_unit?.name.toLowerCase() ?? "",
    },
    {
      name: "Status",
      width: "84px",
      center: true,
      selector: (row: any) =>
        row.is_active ? (
          <ActiveInactiv status={row.is_active} text="Ativo" />
        ) : (
          <ActiveInactiv status={row.is_active} text="Inativo" />
        ),
    },
    {
      name: "Ação",
      width: "60px",
      center: true,
      cell: (row: any) => (
        <Dropdown>
          <Dropdown.Toggle variant="white" id="dropdown-basic" size="sm">
            <HiOutlineDotsVertical />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleDuplicate(row)}>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <HiOutlineDocumentDuplicate
                  size={18}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Duplicar Produto"
                  className="action"
                  color="#0095e8"
                />
                Duplicar Produto
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleEdit(row)}>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <FaEdit
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Editar"
                  className="action"
                  onClick={() => handleEdit(row)}
                  size={16}
                  color="#50cd89"
                />
                Editar
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      button: true,
    },
  ];

  const getData = (filter?: string) => {
    const uri =
      filter !== undefined
        ? `?${filter}&page=${page}&per_page=${perPage}&append=stock_qty&store_id=${storeMe?.storeActive?.id}&${paramSorted}`
        : `?page=${page}&per_page=${perPage}&append=stock_qty&sort=created_at&store_id=${storeMe?.storeActive?.id}${paramSorted ? `&${paramSorted}` : ""}`;
    setLoading(true);
    api
      .get(Globals.api.products + `${uri}`)
      .then((response) => {
        const data = response.data;

        setProductsList(data.data);
        setProductsComplement(data.data);
        setTotalRows(response.data.meta.total);
        
        api.get(Globals.api.products + `?store_id=${storeMe?.storeActive?.id}&sort=-code`).then((response2) => {
            const data2 = response2.data;
            
            setProductsComplement(data2.data);

          })
      })
      .catch((error) => {
        notifyError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  
  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handlePageChange = (page: number) => {
    setPage(page); 
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setPage(page); 
  };

  useEffect(() => {
    getData();
    GetEndPoint.categories(setCategoriesList, storeMe?.storeActive?.id);
    GetEndPoint.productsPage(setListName, storeMe?.storeActive?.id);
  }, []);

  useEffect(() => {
    getData(filter);
  }, [page, perPage, paramSorted]);

  console.log("namename", name);
  

  return (
    <>
      <LayoutFilter
        title="Produtos"
        onClickAdd={handleNew}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelectAuto
              label="Produto"
              placeholder="Produto"
              state={name}
              setState={setName}
              fetchAutocomplete={fetchAutocomplete}
            />

              <InputDefault
                label="Código"
                placeholder="Código"
                type="text"
                autoComplete="off"
                state={code}
                maxLength={50}
                onChenge={(e: any) => setCode(e.target.value)}
              />

              <ReactSelect
                label="Categoria"
                placeholder="Categoria"
                state={category}
                options={categoriesList}
                setState={setCategory}
                isLoading={categoriesList ? false : true}
              />

              <ReactSelect
                label="Período"
                placeholder="Periodo"
                state={typedate}
                options={listdate}
                isInvalid={false}
                setState={setTypeDate}
              />

              <InputDefault
                label="De"
                type="date"
                disabled={typedate === 0}
                state={startDate}
                onChenge={(e: any) => handleDateStart(e.target.value)}
              />

              <InputDefault
                label="Até"
                type="date"
                disabled={typedate === 0}
                state={endDate}
                onChenge={(e: any) => handleDateEnd(e.target.value)}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelectAuto
              label="Produto"
              placeholder="Produto"
              state={name}
              setState={setName}
              fetchAutocomplete={fetchAutocomplete}
            />

            <InputDefault
              label="Código"
              type="text"
              autoComplete="off"
              state={code}
              maxLength={50}
              onChenge={(e: any) => setCode(e.target.value)}
            />

            <ReactSelect
              label="Categoria"
              state={category}
              options={categoriesList}
              setState={setCategory}
              isLoading={categoriesList ? false : true}
            />

            <ReactSelect
              label="Período"
              state={typedate}
              options={listdate}
              isInvalid={false}
              setState={setTypeDate}
            />

            <InputDefault
              label="De"
              type="date"
              disabled={typedate === 0}
              state={startDate}
              onChenge={(e: any) => handleDateStart(e.target.value)}
            />

            <InputDefault
              label="Até"
              type="date"
              disabled={typedate === 0}
              state={endDate}
              onChenge={(e: any) => handleDateEnd(e.target.value)}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>
      <LayoutTable>
        <DataTable
          columns={columns}
          data={productsList}
          striped
          pagination
          paginationServer
          paginationTotalRows={totalRows} 
          paginationDefaultPage={1}
          paginationPerPage={perPage}
          onChangePage={handlePageChange} 
          onChangeRowsPerPage={handlePerRowsChange} 
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
         
          />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
