import { FaCashRegister, FaChartPie, FaHome, FaUsers } from "react-icons/fa";
import globals from "../../../Globals.json";
import type { ReactNode } from "react";
import { FaBoxesPacking } from "react-icons/fa6";
import { PiBankFill } from "react-icons/pi";
import { HiBuildingStorefront } from "react-icons/hi2";

export interface Menu {
  titulo: string;
  icon: ReactNode;
  url?: string;
  subMenu?: {
    subTitle:string
    links: {
      subTitulo: string;
      url: string;
    }[];
  }[]
}

export const ItemsMenu = [
  
  {
    titulo: "DASHBOARD",
    icon: <FaHome size={24} />,
    url: globals.paths.home,
  },
  {
    titulo: "CADASTRO",
    icon: <FaUsers size={24} />,
    subMenu:[
      {
        subTitle : "Pessoas",
        links: [
          {
            subTitulo: "Clientes",
            url: globals.paths.cadClientes,
          },
          {
            subTitulo: "Fornecedores",
            url: globals.paths.cadFornecedores,
          },
          {
            subTitulo: "Funcionários",
            url: globals.paths.cadFuncionarios,
          },
          {
            subTitulo: "Transportadoras",
            url: globals.paths.cadTransportadoras,
          },
        ],
      },
      {
        subTitle : "Produtos",
        links: [
          {
            subTitulo: "Produtos",
            url: globals.paths.CadProdutos,
          },
          {
            subTitulo: "Fichas técnicas",
            url:globals.paths.CadFichaTecnica,
          },
          {
            subTitulo: "Produção",
            url: globals.paths.CadProducao,
          },
          {
            subTitulo: "Unidades de Medida",
            url: globals.paths.CadUnidMedida,
          },
          {
            subTitulo: "Categorias",
            url: globals.paths.CadCatProdutos,
          },
          {
            subTitulo: "Perfis Fiscais",
            url: globals.paths.CadPerfilFiscal,
          },
         
        ],
      },
      {
        subTitle : "Sincronização",
        links: [
          {
            subTitulo: "Tabela de Preços",
            url: globals.paths.CadTabelaPreco,
          },
        
         
        ],
      },
    ]
  },
  {
    titulo: "ESTOQUE",
    icon: <FaBoxesPacking size={24} />,
    subMenu:[
      {
        subTitle : "Movimentações",
        links: [
          {
            subTitulo: "Movimentações",
            url: globals.paths.cadMovimentacoes,
          },
          {
            subTitulo: "Transferências",
            url: globals.paths.cadTransferencias,
          },
          {
            subTitulo: "Orçamentos",
            url: globals.paths.cadOrcamentos,
          },
          {
            subTitulo: "Posição de estoque",
            url: globals.paths.cadPosicoes,
          },
          {
            subTitulo: "Ajuste de estoque",
            url: globals.paths.cadAjustes,
          },
          {
            subTitulo: " Tipos de movimentações",
            url: globals.paths.cadTiposMovimentacoes,
          },
          {
            subTitulo: " Inventário",
            url: globals.paths.cadInventario,
          },
          {
            subTitulo: " Contagem de estoque",
            url: globals.paths.cadContagem,
          },
        ],
      },
      {
        subTitle : "Arquivos",
        links: [
          {
            subTitulo: "Exportar inventário",
            url: "/dashboard",
          },
          {
            subTitulo: "Importar inventário",
            url: "/dashboard",
          },
          {
            subTitulo: "Livro de inventário",
            url: "/dashboard",
          },
          {
            subTitulo: "Markup de produtos",
            url: "/dashboard",
          },
        ],
      },
      {
        subTitle : "Configurações",
        links: [
          {
            subTitulo: "Locais de estoque",
            url: globals.paths.cadLocaisEstoque,
          },
        ],
      },
    ]
  },
  {
    titulo: "VENDAS",
    icon: <FaCashRegister size={24} />,
    subMenu:[
      {
        subTitle : "vendas",
        links: [
          {
            subTitulo: "Faturamento",
            url: '/dashboard',
          },
        ],
      },
      {
        subTitle : "Caixa",
        links: [
          {
            subTitulo: "Listagem de Vendas",
            url: globals.paths.CadVendas,
          },
          {
            subTitulo: "Fechamento de Caixa",
            url: "/dashboard",
          },
         
        ],
      },
    ]
  },
  {
    titulo: "FINANCEIRO",
    icon: <PiBankFill size={24} />,
    subMenu:[
      {
        subTitle : "Movimentações",
        links: [
          {
            subTitulo: "Receitas",
            url: globals.paths.cadTransacoesReceitas,
          },
          {
            subTitulo: "Despesas",
            url: globals.paths.cadTransacoesDespesas,
          },
         
        ],
      },
      {
        subTitle : "Relatórios",
        links: [
          {
            subTitulo: "Fluxo de caixa",
            url: "/dashboard",
          },
          {
            subTitulo: "Extrato bancário",
            url: "",
          },
          {
            subTitulo: "Movimentação Bancária",
            url: "",
          },
          {
            subTitulo: "Imprimir recibo",
            url: globals.paths.cadImprimirRecibos,
          },
        ],
      },
      {
        subTitle : "Configurações",
        links: [
          {
            subTitulo: "Categorias",
            url: globals.paths.cadCategoriaMovimentacao,
          },
          {
            subTitulo: "Formas de Recebimento",
            url: globals.paths.cadFormaRecebimento,
          },
          {
            subTitulo: "Formas de Pagamento",
            url: globals.paths.cadFormaPagamento,
          },
          {
            subTitulo: " Contas bancárias",
            url: globals.paths.cadBankAccounts,
          },
          {
            subTitulo: "Plano de contas",
            url: globals.paths.cadPlanoContas,
          },
        ],
      },
    ]
  },

  {
    titulo: "CONFIGURAÇÕES",
    icon: <HiBuildingStorefront size={24} />,
    subMenu:[
      {
        subTitle : "Fiscal",
        links: [
          {
            subTitulo: " Certificado digital",
            url: "/dashboard",
          },
          {
            subTitulo: "Grupos fiscais",
            url: "/dashboard",
          },
         
        ],
      },
      {
        subTitle : "Empresas",
        links: [
          {
            subTitulo: "Lojas",
            url: globals.paths.cadStore,
          },
          {
            subTitulo: "CFOP",
            url: globals.paths.Cfops,
          },
          {
            subTitulo: " Acesso as lojas",
            url: globals.paths.accessStores,
          },
        ],
      },
      {
        subTitle : "Sistema",
        links: [
          {
            subTitulo: "Preferências",
            url: "/dashboard",
          },
          {
            subTitulo: "Perfil de acesso",
            url: globals.paths.cadRole,
          },
          {
            subTitulo: "Permissões",
            url: globals.paths.cadPerfilUsuario,
          },
          {
            subTitulo: "Usuários",
            url: globals.paths.cadUserSystem,
          },

        ],
      },
    ]
  },

  {
    titulo: "RELATÓRIOS",
    icon: <FaChartPie size={24} />,
    subMenu:[
      {
        subTitle : "Tipos",
        links: [
          {
            subTitulo: "Vendas",
            url: "/dashboard",
          },
          {
            subTitulo: "Financeiros",
            url: "/dashboard",
          },
          {
            subTitulo: "Fiscais",
            url: "/dashboard",
          },
          {
            subTitulo: "Estoques",
            url: "/dashboard",
          },
        ],
      },
    
    ]
  },
];
