import { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import ListaMovimEstoque from "../../../Components/Estoque/Movimentacoes/ListaMovimEstoque";
import "./styles.scss";
import IEstoque from "../../../Components/Estoque/Interface/IStock";
import { FormMovimentacaoEstoque } from "../../../Components/Estoque/Movimentacoes/CadMovimentacao/movimentacao";
import { ThemeContext } from "../../../context/theme-context";
import { IStockQuote } from "../../../Components/Estoque/Orcamento/interface/IStockQuote";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../redux/store";
import {
  handleEditeRedux,
  handleNew,
  setBudgetSelected,
} from "../../../redux/features/stockMoviment/stock-moviment-slice";

export interface IListaMovimEstoque {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: IEstoque) => void;
  OnAddNewEstoqueMovimentacao: (e: boolean) => void;
  setBudgetSelected?: any;
}

export interface IFormMovimentacaoEstoque {
  title: string;
  OnAddNewEstoqueMovimentacao: (e: boolean) => void;
  estoque?: IEstoque;
  editando: boolean;
  budgetSelected?: IStockQuote;
}

export default function CadMovimEstoque() {
  const dispatch = useDispatch<AppDispatch>();
  const { setCad, setEditando, budgetSelected } = useAppSelector(
    ({ stockMovimentReducer }) => stockMovimentReducer.value
  );

  const { collapsed } = useContext(ThemeContext);

  const [toEditMovimentacao, setToEditMovimentacao] = useState<IEstoque>();

  const handleEdit = (props: IEstoque) => {
    setToEditMovimentacao(props);
    dispatch(handleEditeRedux(true));
  };

  return (
    <>
      {!setCad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaMovimEstoque
            title="Movimentações"
            onEditItem={(item: IEstoque) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => dispatch(handleNew(e))}
            OnAddNewEstoqueMovimentacao={(e: boolean) => dispatch(handleNew(e))}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormMovimentacaoEstoque
            title={
              toEditMovimentacao
                ? "Editar Movimentação de Estoque"
                : "Nova Movimentação de Estoque"
            }
            OnAddNewEstoqueMovimentacao={(e: boolean) => {
              dispatch(handleNew(e));
              setToEditMovimentacao(undefined);
              dispatch(setBudgetSelected(undefined));
            }}
            estoque={toEditMovimentacao}
            editando={setEditando}
            budgetSelected={budgetSelected}
          />
        </Container>
      )}
    </>
  );
}
