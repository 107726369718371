import { useEffect, useState } from "react";
import {  Form,  Button, ButtonGroup, Table } from "react-bootstrap/";
import { getEmployees } from "../../../../Services/people-service";
import { getStockableProducts } from "../../../../Services/product-service";
import Loading from "../../../Loading/Loading";
import "./styles.scss";
import {  notifyError } from "../../../notify/notify-component";
import { createQuote } from "../../../../Services/quotes-service";
import Layout from "../../../data/Layout";
import { InputDefault } from "../../../data/inputDefault";
import ReactSelect from "../../../data/reactSelect";
import { ToastContainer } from "react-toastify";
import { Services } from "../../../../Services/newService";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { useAppSelector } from "../../../../redux/store";
import ModalBootstrap from "../../../data/Modal";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import * as S from "../../styled";
import Format from "../../../../Services/Format";
import { InputDefaultDiscount } from "../../../data/inputDefaultDiscount";

export function Orcamento(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  let totalProductValue: any = 0;
  const [description, setDescription] = useState<string>("");
  const [uuid, setUuid] = useState<string>("");
  const [discountType, setDiscountType] = useState<any>("");
  const [amount, setAmount] = useState<number>();
  const [discount, setDiscount] = useState<number>();
  const [stockQuoteSituationId, setStockQuoteSituationId] = useState<number>(1);
  const [finalAmount, setFinalAmount] = useState<number>();
  const [aProducts, setAProducts] = useState<Array<any>>([]);
  const [productId, setProductId] = useState<number>();
  const [productName, setProductName] = useState<string>();
  const [vendorId, setVendorId] = useState<number>();
  const [costPrice, setCostPrice] = useState<number>();
  const [discountUni, setDiscontoUni] = useState<number>(0);
  const [qty, setQty] = useState<string>("");
  const [listVendor, setListVendor] = useState<Array<any>>([]);
  const [listProducts, setListProducts] = useState<Array<any>>([]);
  const [listDiscountType, setListDiscountType] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [editeProductApi, setEditeProductApi] = useState<boolean>(false);
  const [dbProducts, setDbProducts] = useState<Array<any>>([]);
  const [uuidProduct, setUuidProduct] = useState<string>("");
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [itemTable, setItemTable] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [percent, setSetPercent] = useState<boolean>(true);
  const [price, setPrice] = useState<boolean>(false);

  const [validAmount, setValidAmount] = useState<boolean>(false);
  const [validDescription, setValidDescription] = useState<boolean>(false);
  const [validDiscountType, setValidDiscountType] = useState<boolean>(false);
  const [validFinalAmount, setValidFinalAmount] = useState<boolean>(false);
  const [validStockSituation, setValidStockSituation] =
    useState<boolean>(false);

  const oDiscountType = [
    {
      id: "fixo",
      value: "Fixo",
    },
    {
      id: "porcentagem",
      value: "Porcentagem",
    },
    {
      id: "nenhum",
      value: "Nenhum",
    },
  ];

  const fillDiscountType = async () => {
    let list = oDiscountType.map((item: any) => ({
      value: item.id,
      label: item.value,
    }));

    setListDiscountType(list);
  };

  const clearData = () => {
    setLoading(true);
    setTimeout(() => {
      setProductId(0);
      setQty("");
      setCostPrice(undefined);
      fillProducts();
      setLoading(false);
      setDiscontoUni(0);
    }, 300);
  };



  const fillProducts = async () => {
    const products = await getStockableProducts(storeMe?.storeActive?.id);

    setDbProducts(products.data.data);

    let list = products.data.data.map((item: any) => ({
      ...item,
      value: item.id,
      label: item.purchase_name,
      costPrice: item.cost_price,
    }));
    setListProducts(list);
  };

  const handleSelectProduct = async (
    product_id: number,
    uuidProduct?: string
  ) => {
    dbProducts.map((item: any) => {
      if (product_id === item.id) {
        setProductName(item.purchase_name);
      }
    });
    setProductId(product_id);
    uuidProduct && setUuidProduct(uuidProduct);
  };

  function handleCancelar() {
    props.OnAddNewQuote(false);
    props.OnClear(true);
  }

  function addProductToTable() {
    if (!qty) return notifyError("Por favor informe a quantidade.");
    const cost = costPrice
      ? costPrice
      : dbProducts.find((item) => item.id === productId)?.cost_price;
    const oModel = {
      productName: productName,
      product_id: productId,
      qty: parseFloat(qty),
      cost_price: cost,
      discount: Number(discountUni),
      total:
        parseFloat(qty) && cost ? parseFloat(qty) * (cost - discountUni) : 0,
    };
    setLoading(true);
    setTimeout(() => {
      aProducts.push(oModel);
      setLoading(false);
      calculateAmount();
      clearData();
    }, 300);
  }

  async function addProductToTableApi() {
    if (!qty) return notifyError("Por favor informe a quantidade.");
    const cost = costPrice
      ? costPrice
      : dbProducts.find((item) => item.id === productId)?.cost_price;

    setLoading(true);

    const oModel = {
      store_id: storeMe?.storeActive?.id,
      product_id: productId,
      productName: productName,
      qty: parseFloat(qty),
      cost_price: cost,
      discount: Number(discountUni),

      total:
        parseFloat(qty) && cost ? parseFloat(qty) * (cost - discountUni) : 0,
    };

    await Services.stockQuotesAddProduct(
      props?.orcamento?.uuid,
      uuidProduct,
      setLoading,
      oModel
    );

    setAProducts([...aProducts, oModel]);
    setLoading(false);
    handleCancelEdite();
  }

  async function editeProductToTableApi() {
    if (!qty) return notifyError("Por favor informe a quantidade.");
    const cost = costPrice
      ? costPrice
      : dbProducts.find((item) => item.id === productId)?.cost_price;

    setLoading(true);

    const oModel = {
      productName: productName,
      product_id: productId,
      qty: parseFloat(qty),
      cost_price: cost,
      discount: Number(discountUni),

      total:
        parseFloat(qty) && cost ? parseFloat(qty) * (cost - discountUni) : 0,
    };

    const res = await Services.stockQuotesEditeProduct(
      props?.orcamento?.uuid,
      uuidProduct,
      setLoading,
      oModel
    );

    const newArrayProduct = aProducts.filter(
      (item: any) => item.uuid !== uuidProduct
    );

    const newModel = {
      ...res.data.data,
      ...oModel,
    };

    newArrayProduct.push(newModel);
    setAProducts(newArrayProduct);
    setLoading(false);
    handleCancelEdite();
  }

  const handleEditItem = () => {
    if (!qty) return notifyError("Por favor informe a quantidade.");
    const cost = costPrice
      ? costPrice
      : dbProducts.find((item) => item.id === productId)?.cost_price;

    const removeItemEditiend = aProducts.filter(
      (item: any) => item.product_id !== productId
    );

    const oModel = {
      productName: productName,
      product_id: productId,
      qty: parseFloat(qty),
      //cost_price: cost - (discountUni ? discountUni : 0),
      cost_price: cost,
      discount: Number(discountUni),
      total:
        parseFloat(qty) && cost ? parseFloat(qty) * (cost - discountUni) : 0,
    };
    setLoading(true);
    setTimeout(() => {
      setAProducts([...removeItemEditiend, oModel]);

      setLoading(false);
      calculateAmount();
      clearData();
    }, 300);
    setIsEditing(false);
  };

  function calcularTotal(produtos: any) {
    return produtos.map((produto: any) => {
      const total = produto.cost_price * produto.qty;
      return { ...produto, total };
    });
  }

  function calculateAmount() {
    let finalAmountTotal: any;
    aProducts.map((item: any) => {
      totalProductValue += item.total;
    });
    setAmount(totalProductValue?.toFixed(2));

    finalAmountTotal =
      discountType === "porcentagem" && discount && discount > 0
        ? totalProductValue - totalProductValue * (discount / 100)
        : totalProductValue - (discount ? discount : 0);
    setFinalAmount(finalAmountTotal?.toFixed(2));
    setDisabled(false);
  }

  function deleteProducts(index: number) {
    let array: any = aProducts;
    if (index >= 0) {
      setLoading(true);
      setTimeout(() => {
        array.splice(index, 1);
        setAProducts(array);
        setLoading(false);
        calculateAmount();
      }, 300);
    }
  }

  async function editeProductsAPI(data: any) {
    setEditeProductApi(true);
    setProductId(data?.product_id);
    setQty(data?.qty);
    setDiscontoUni(data?.discount);
    setCostPrice(data?.cost_price);
    setUuidProduct(data?.uuid);
  }

  function handleCancelEdite() {
    setEditeProductApi(false);
    setProductId(undefined);
    setQty("");
    setCostPrice(undefined);
    setUuidProduct("");
    setDiscontoUni(0);
  }

  async function deleteProductsAPI(data: any) {
    setLoading(true);
    const res = await Services.stockQuotesDeleteProduct(
      props?.orcamento?.uuid,
      data?.uuid,
      setLoading
    );

    if (res.status === 200) {
      const ArrayProduct = aProducts.filter(
        (item: any) => item.uuid !== data?.uuid
      );
      setAProducts(ArrayProduct);
    } else {
      notifyError("Houve um erro ao tentar excluir produto");
    }
    setLoading(false);
  }

  function calculateFinalAmount(discountValue: any) {
    setDiscount(discountValue);
    let finalAmountTotal: any;
    finalAmountTotal = amount
      ? amount -
        (discountType === "porcentagem"
          ? amount * (discountValue / 100)
          : discountValue)
      : amount;

    setFinalAmount(discountValue > 0 ? finalAmountTotal : amount);
  }

  function handleSelectDiscountType(discount_type: string) {
    setDiscountType(discount_type);
  }

  const handleValidete = () => {
    if (!amount) setValidAmount(true);
    if (amount) setValidAmount(false);
    if (!description) setValidDescription(true);
    if (description) setValidDescription(false);
    if (!discountType) setValidDiscountType(true);
    if (discountType) setValidDiscountType(false);
    if (!finalAmount) setValidFinalAmount(true);
    if (finalAmount) setValidFinalAmount(false);
    if (!stockQuoteSituationId) setValidStockSituation(true);
    if (stockQuoteSituationId) setValidStockSituation(false);

    if (
      amount &&
      description &&
      finalAmount &&
      discountType &&
      stockQuoteSituationId
    ) {
      return true;
    }

    return false;
  };

  function handleSubmit() {
    if (aProducts.length === 0) {
      notifyError("Por favor adicione pelo menos um produto na lista.");
      return;
    }
    const valid = handleValidete();

    if (valid === false) {
      notifyError("Por favor preencha todos os campos obrigatórios.");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    const oModel = {
      store_id: storeMe?.storeActive?.id,
      uuid: uuid,
      stock_quote_situation_id: stockQuoteSituationId,
      ...(vendorId  && { vendor_id: vendorId}),
      description: description,
      amount: amount,
      discount: discount ? discount : 0,
      ...(discountType !== "nenhum" && { discount_type: discountType }),
      ...(!props.editando  && {  products: aProducts }),
      final_amount: finalAmount,
    };

    createQuote(oModel).then((res: any) => {
      setLoading(true);
      if (!res) {
        setTimeout(() => {
          notifyError("Ocorreu um erro");
          setLoading(false);
        }, 100);
      } else {
        setTimeout(() => {
          handleCancelar();
          setLoading(false);
        }, 100);
      }
    });
  }

  const handleModalItemTableAPI = (item: any) => {
    setItemTable(item);
    setModalConfirmation(true);
  };

  const handleDeleteitemTable = () => {
    setModalConfirmation(false);
    if (typeof itemTable === "number") {
      deleteProducts(itemTable);
    } else {
      deleteProductsAPI(itemTable);
    }
  };

  const handleActivedEditItem = (item: any) => {
    setIsEditing(true);

    setProductId(item?.product_id);
    setQty(item?.qty);
    setDiscontoUni(item?.discount);
    setCostPrice(item?.cost_price);
    setUuidProduct(item?.uuid);
  };

  useEffect(() => {
    if (discountType === "nenhum") {
      setDiscount(0);
      setFinalAmount(amount);
    } else {
      calculateFinalAmount(discount);
    }
  }, [discountType]);

  useEffect(() => {
    fillDiscountType();
    GetEndPoint.vendor(setListVendor, storeMe?.storeActive?.id);

    fillProducts();

    if (props?.orcamento) {
     
      setUuid(props?.orcamento?.uuid);
      setDescription(props?.orcamento?.description);
      setVendorId(props?.orcamento?.vendor_id);
      setStockQuoteSituationId(props?.orcamento?.stock_quote_situation_id);
      setAProducts(calcularTotal(props?.orcamento?.relationships?.products));
      setAmount(props?.orcamento?.amount?.toFixed(2));
      setDiscountType(
        props?.orcamento?.discount_type
          ? props?.orcamento?.discount_type
          : "nenhum"
      );
      setDiscount(props?.orcamento?.discount);
      setFinalAmount(props?.orcamento?.final_amount);
    } else {
      setDiscountType("nenhum");
    }
  }, []);

  let newListVendor = listVendor.filter(
    (item) => item.store_id === storeMe?.storeActive?.id
  );

  

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir o produto?"
        handleAction={handleDeleteitemTable}
      />
      <Layout
        title={props.Title}
        isEditable={props.editando}
        onClickSave={props.onlyView ? () => {} : handleSubmit}
        onClickEdit={props.onlyView ? () => {} : handleSubmit}
        onClickCancel={handleCancelar}
        onlyView={props.onlyView}
      >
        <S.Wrapper>
          <InputDefault
            label="Descrição"
            type="text"
            autoComplete="off"
            placeholder="Digite a descrição"
            state={description}
            setState={setDescription}
            isInvalid={validDescription}
            disabled={props.onlyView}
          />

          <ReactSelect
            label="Fornecedor"
            placeholder={"Selecione um Fornecedor"}
            options={newListVendor}
            state={vendorId}
            setState={setVendorId}
            isDisabled={props.onlyView}
          />

          <label>
            <div className="label mt-1">Situação</div>

            <ButtonGroup>
              <Button
                variant={stockQuoteSituationId === 1 ? "success" : "light"}
                active={stockQuoteSituationId === 1 ? true : false}
                size="sm"
                onClick={(e) => setStockQuoteSituationId(1)}
                disabled={props.onlyView}
              >
                Em Orçamento
              </Button>
              <Button
                variant={stockQuoteSituationId === 2 ? "primary" : "light"}
                active={stockQuoteSituationId === 2 ? true : false}
                size="sm"
                onClick={(e) => setStockQuoteSituationId(2)}
                disabled={props.onlyView}
              >
                Aprovado
              </Button>
              <Button
                variant={stockQuoteSituationId === 3 ? "primary" : "light"}
                active={stockQuoteSituationId === 3 ? true : false}
                size="sm"
                onClick={(e) => setStockQuoteSituationId(3)}
                disabled={props.onlyView}
              >
                Concluído
              </Button>
            </ButtonGroup>
          </label>
        </S.Wrapper>
        <S.Title>Produto</S.Title>
        <S.Wrapper4>
          <ReactSelect
            label="Produto"
            placeholder="Produto"
            options={listProducts}
            state={productId}
            setState={setVendorId}
            onChange={(e: any) =>
              handleSelectProduct(parseInt(e?.value), e?.uuid)
            }
            isDisabled={props.onlyView}
          />

          <InputDefault
            label="Qtd."
            type="number"
            autoComplete="off"
            placeholder="Quantidade"
            state={qty ? qty : ""}
            setState={setQty}
            maxLength={10}
            disabled={props.onlyView}
          />

          <InputDefault
            label="Preço "
            placeholder="Preço de compra"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={costPrice ? costPrice : ""}
            setState={setCostPrice}
            disabled={props.onlyView}
          />

          <InputDefaultDiscount
            label={"Desconto"}
            placeholder="Desconto unitário"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={discountUni ? discountUni : 0}
            setState={setDiscontoUni}
            disabled={props.onlyView}
            price={price}
            percent={percent}
            setPrice={setPrice}
            setSetPercent={setSetPercent}
          />

          <InputDefault
            label="Últ. Preço"
            placeholder="Último preço de compra"
            type="number"
            autoComplete="off"
            step="0.01"
            min="0"
            state={
              productId
                ? dbProducts
                    .find((item) => item.id === productId)
                    ?.cost_price?.toFixed(2)
                : 0
            }
            disabled
          />
        </S.Wrapper4>
        <S.BtnAdd>
          <div>
            {!isEditing ? (
              <Form.Control
                type="button"
                size="sm"
                className="buttonAdd"
                onClick={
                  props.orcamento
                    ? editeProductApi
                      ? editeProductToTableApi
                      : addProductToTableApi
                    : addProductToTable
                }
                value={editeProductApi ? "Salvar" : "Adicionar"}
                disabled={props.onlyView}
              />
            ) : (
              <Form.Control
                type="button"
                size="sm"
                className="buttonAdd"
                onClick={handleEditItem}
                value={"Salvar"}
              />
            )}

            <label className="mb-1">&nbsp;</label>
            {editeProductApi && (
              <Form.Control
                type="button"
                size="sm"
                className="buttonAdd"
                onClick={
                  props.orcamento && editeProductApi && handleCancelEdite
                }
                value={"Cancelar"}
                disabled={props.onlyView}
              />
            )}
          </div>
        </S.BtnAdd>
        <div style={{ margin: "0 1rem 0 1rem" }}>
          {aProducts.length === 0 ? (
            <div className=""> </div>
          ) : props.orcamento ? (
            <Table striped hover bordered size="sm" className="tableMargin">
              <thead>
                <tr>
                  <th>Nome do Produto</th>
                  <th>Preço de Custo</th>
                  <th>Quantidade</th>
                  <th>Desconto</th>
                  <th>Total</th>
                  <th
                    style={{
                      display: "flex",
                      gap: "2rem",
                      justifyContent: "center",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "11px" }}>
                {aProducts.map((item: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>
                        {
                          dbProducts.find(
                            (product) => product.id === item.product_id
                          )?.sale_name
                        }
                      </td>
                      <td>
                        R${" "}
                        {Number(item.cost_price)?.toFixed(2)?.replace(".", ",")}
                      </td>
                      <td>
                        {item.qty +
                          " " +
                          dbProducts.find(
                            (product) => product.id === item.product_id
                          )?.relationships?.sale_measure_unit?.name}
                      </td>
                      <td>
                        R${" "}
                        {Number(item?.discount ? item?.discount : 0)
                          ?.toFixed(2)
                          ?.replace(".", ",")}
                      </td>
                      <td>
                        R$ {Number(item.total)?.toFixed(2)?.replace(".", ",")}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          gap: "1rem",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{ border: "none", background: "none" }}
                          type="button"
                          onClick={() => editeProductsAPI(item)}
                          disabled={props.onlyView}
                        >
                          <FaEdit size={14} color="666666" />
                        </button>
                        <button
                          style={{ border: "none", background: "none" }}
                          type="button"
                          onClick={() => handleModalItemTableAPI(item)}
                          disabled={props.onlyView}
                        >
                          <FaRegTrashAlt size={14} color="666666" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <Table striped hover bordered size="sm" className="tableMargin">
              <thead>
                <tr>
                  <th>Nome do Produto</th>
                  <th>Preço de Custo</th>
                  <th>Quantidade</th>
                  <th>Desconto</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "11px" }}>
                {aProducts.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        {
                          dbProducts.find(
                            (product) => product.id === item.product_id
                          )?.sale_name
                        }
                      </td>
                      <td>
                        R${" "}
                        {Number(item.cost_price)?.toFixed(2)?.replace(".", ",")}
                      </td>
                      <td>
                        {item.qty +
                          " " +
                          dbProducts.find(
                            (product) => product.id === item.product_id
                          )?.relationships?.sale_measure_unit?.name}
                      </td>
                      <td>
                        R${" "}
                        {Number(item?.discount ? item?.discount : 0)
                          ?.toFixed(2)
                          ?.replace(".", ",")}
                      </td>
                      <td>
                        R$ {Number(item.total)?.toFixed(2)?.replace(".", ",")}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "1rem",
                        }}
                      >
                        <button
                          style={{ border: "none", background: "none" }}
                          type="button"
                          onClick={() => handleActivedEditItem(item)}
                          disabled={props.onlyView}
                        >
                          <FaEdit size={14} color="666666" />
                        </button>
                        <button
                          style={{ border: "none", background: "none" }}
                          type="button"
                          onClick={() => handleModalItemTableAPI(index)}
                          disabled={props.onlyView}
                        >
                          <FaRegTrashAlt size={14} color="666666" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
        <S.Balance>
          <ul>
            <li>
              Quantidade de Itens:
              <span>{aProducts?.length}</span>
            </li>
            <li>
              Valor:
              <span>{Format.currency(amount ? amount : 0)}</span>
            </li>
            <li>
              Descontos:
              <span>
                {discountType === "porcentagem"
                  ? discount || 0
                  : Format.currency(discount || 0)}
                {discountType === "porcentagem" && "%"}
              </span>
            </li>
            <li className="total">
              Total:
              <span>{Format.currency(finalAmount ? finalAmount : 0)}</span>
            </li>
          </ul>
        </S.Balance>{" "}
        <S.Content>
          {aProducts.length > 0 && (
            <S.Wrapper2>
              <ReactSelect
                label="Tipo "
                placeholder={"Tipo de Desconto"}
                state={discountType}
                onChange={(e: any) => handleSelectDiscountType(e?.value)}
                options={listDiscountType}
                isInvalid={validDiscountType}
                isDisabled={props.onlyView}
              />

              {discountType === "fixo" ? (
                <InputDefault
                  label="Desconto"
                  placeholder="0,00"
                  type="number"
                  autoComplete="off"
                  step="0.01"
                  min="0"
                  state={discount}
                  onChenge={(e: any) =>
                    calculateFinalAmount(parseFloat(e?.target.value))
                  }
                  disabled={
                    discountType === "nenhum"
                      ? true
                      : discountType
                      ? false
                      : true
                  }
                />
              ) : (
                <InputDefault
                  label="Desconto"
                  placeholder="0,00"
                  type="number"
                  autoComplete="off"
                  step="0.01"
                  min="0"
                  state={discount}
                  onChenge={(e: any) =>
                    calculateFinalAmount(parseFloat(e?.target.value))
                  }
                  disabled={
                    props.onlyView
                      ? true
                      : discountType === "nenhum"
                      ? true
                      : discountType
                      ? false
                      : true
                  }
                />
              )}
            </S.Wrapper2>
          )}
        </S.Content>
      </Layout>

      {loading && <Loading />}

      <ToastContainer
        position="top-center"
        autoClose={300}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}
