import { InputHook } from "../../../../reactHookForm/InputHook";
import { SelectHook } from "../../../../reactHookForm/SelectHook";
import * as S from "./styled";

interface IProps {
  register: any;
  errors: any;
  watch: any;
  setValue: any;
  setError: any;
  setStateForm: React.Dispatch<React.SetStateAction<any>>;
  data: any;
  isEditable: boolean;
  control: any;
}

export function PermissionData({
  register,
  errors,
  setError,
  setValue,
  watch,
  setStateForm,
  data,
  isEditable,
  control,
}: IProps) {
  return (
    <>
      <S.Wrapper>
        <div className="min-screen">
          <InputHook
            register={register}
            errors={errors}
            label="Usuário"
            name="user"
            placeholder="Usuário"
            type="text"
          />
          <InputHook
            register={register}
            errors={errors}
            type="password"
            label="Senha"
            name="Senha"
            placeholder="Senha"
          />
          <InputHook
            register={register}
            errors={errors}
            type="password"
            label="Confirmar senha"
            name="confirm_passord"
            placeholder="Confirmar senha"
          />
          <SelectHook
            register={register}
            control={control}
            label="Carregar Perfil de acesso"
            name="perfil"
            placeholder="Carregar Perfil de acesso"
            options={[]}
          />
        </div>
        <div className="max-screen">
          <InputHook
            register={register}
            errors={errors}
            label="Usuário"
            name="user"
            placeholder="Usuário"
            type="text"
          />
          <InputHook
            register={register}
            errors={errors}
            type="password"
            label="Senha"
            name="Senha"
            placeholder="Senha"
          />
          <InputHook
            register={register}
            errors={errors}
            type="password"
            label="Confirmar senha"
            name="confirm_passord"
            placeholder="Confirmar senha"
          />
          <SelectHook
            register={register}
            control={control}
            label="Perfil de acesso"
            name="perfil"
            placeholder="Perfil de acesso"
            options={[]}
          />
        </div>
        <button className="reset">Enviar email de recuperação de senha</button>
      </S.Wrapper>
    </>
  );
}
