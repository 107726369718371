import { useEffect, useState } from "react";
import { Row, Form, Col, Card, Button, Table } from "react-bootstrap/";
import { ToastContainer } from "react-toastify";
import Loading from "../../../Loading/Loading";
import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { getStocks, showStocks } from "../../../../Services/stock-service";
import {
  getProductStockQty,
  getStockableProducts,
} from "../../../../Services/product-service";
import {
  createTransfer,
  showTransfer,
} from "../../../../Services/transfers-service";
import Layout from "../../../data/Layout";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import { IFormTransferenciaEstoque } from "../../../../Pages/Cadastro/CadTransfEstoque";
import { useAppSelector, type AppDispatch } from "../../../../redux/store";
import ModalBootstrap from "../../../data/Modal";

import * as S from "../../styled";
import { useDispatch } from "react-redux";
import { setStateForm } from "../../../../redux/features/stockTransfer/stock-transfer-slice";
import { useForm, type SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { stockTransferSchema } from "../../../../redux/features/stockTransfer/schema";
import LayoutHookForm from "../../../reactHookForm/LayoutForm";
import { SelectHook } from "../../../reactHookForm/SelectHook";
import { InputHook } from "../../../reactHookForm/InputHook";
import { getStockLocationAsync } from "../../../../redux/features/stockTransfer/services";

type FormData = Yup.InferType<typeof stockTransferSchema>;

export function FormTransferenciaEstoque({
  title,
  OnAddNewEstoque,
  editando,
  estoque,
}: IFormTransferenciaEstoque) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();
  const { stateForm, stocklocation } = useAppSelector(
    ({ stockTransferReducer }) => stockTransferReducer.value
  );
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(stockTransferSchema),
  });
  const inputError = [errors];

  const [isIntern, setIsIntern] = useState<boolean>(true);
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const stores = storeMe.user.relationships.stores?.filter(item => item.id !== storeMe?.storeActive?.id)?.map(item => ({
    value:item.id,
    label: item.trading_name
  }))

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {};

  function handleCancelar() {
    OnAddNewEstoque(false);
  }


  useEffect(() => {
    dispatch(getStockLocationAsync(storeMe?.storeActive?.id))
  }, [dispatch]);

  console.log("stocklocation", stocklocation);
  

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir o produto?"
        //handleAction={handleItemTable}
      />
      <LayoutHookForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={title}
        isEditable={editando}
        onClickCancel={handleCancelar}
        stateForm={stateForm}
        //isDisable={isDisable}
      >
        <Row>
          <Col className="buttonColumns mb-3 mt-3" md="3">
            <label className="mb-1 mx-2">Tipo de Transferência</label>
            <Button
              size="sm"
              type="button"
              variant={isIntern ? "success" : "primary"}
              onClick={() => setIsIntern(!isIntern)}
            >
              {!isIntern ? "EXTERNA" : "INTERNA"}
            </Button>
          </Col>
        </Row>

        <h3>Local Estoque</h3>
        <S.Wrapper>
          {isIntern ? (
            <>
              <SelectHook
                register={register}
                control={control}
                label="Origem"
                defaultValue={[]}
                name="stock_transfer_type_id"
                placeholder="Local de estoque origem"
                options={stocklocation?.map(item => ({
                  value:item.id,
                  label:item.name.toLocaleUpperCase()
                }))}
                errors={inputError}
              />
              <SelectHook
                register={register}
                control={control}
                label="Destino"
                defaultValue={[]}
                name="stock_destination_id"
                placeholder="Local de estoque destino"
                options={[]}
                errors={inputError}
              />
            </>
          ):
          <>
          <SelectHook
            register={register}
            control={control}
            label="Origem"
            defaultValue={[]}
            name="stock_transfer_type_id"
            placeholder="Local de estoque origem"
            options={[]}
            errors={inputError}
          />
          <SelectHook
            register={register}
            control={control}
            label="Loja"
            defaultValue={[]}
            name="store_destination_id"
            placeholder="Loja de destino"
            options={stores}
            errors={inputError}
          />
        </>
          }
           <InputHook
              register={register}
              errors={inputError}
              label="Observação"
              name="node"
              placeholder="Observação"
              type="text"
            />

        </S.Wrapper>
      </LayoutHookForm>

      {stateForm.state === "loading" && <Loading />}
    </>
  );
}
