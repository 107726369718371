import { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Format from "../../../../Services/Format";
import { FaEdit } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";
import DateUtils from "../../../../Services/DateUtils";
import { ObjState } from "../../../../Services/objectState";
import LayoutFilter from "../../../data/LayoutFilter";
import LayoutTable from "../../../data/LayoutTable";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import ActiveInactiv from "../../../data/activeInactive";
import CustomLoader from "../../../data/customLoader";
import { useAppSelector } from "../../../../redux/store";
import * as S from "./styled";
import { notifyError } from "../../../notify/notify-component";

export default function ListaPessoa(props: any) {
  const ref = useRef<any>(null);
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [custumers, setCustumers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [trading, setTrading] = useState<string>("");
  const [status, setStatus] = useState<number>(-1);
  const [cnpjCpf, setCnpjCpf] = useState<string>("");
  const [localization, setLocalization] = useState<string>("");
  const [typedate, setTypeDate] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")
  );
  const [listName, setListName] = useState<Array<any>>([]);

  const oStatus = [
    {
      value: -1,
      label: "Todos",
    },
    {
      value: 1,
      label: "Ativo",
    },
    {
      value: 0,
      label: "Inativo",
    },
  ];

  const oPeriodo = [
    {
      value: 0,
      label: "Todos",
    },
    {
      value: 1,
      label: "Data de cadastro",
    },
  ];

  const handleEdit = (row: any) => {
    if (typeof props.onEditItem === "function" && row !== undefined) {
      props.onEditItem(row);
    }
  };

  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      return;
    }
    setStartDate(date);
    let dateValid = isLater(date, endDate);
    if (dateValid) {
      return;
    }
  };

  const handleDateEnd = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      return;
    }
    setEndDate(date);
    let dateValid = isLater(startDate, date);
    if (dateValid) {
      return;
    }
  };

  const clearFields = () => {
    setName("");
    setTrading("");
    setStatus(-1);
    setCnpjCpf("");
    setLocalization("");
    setTypeDate(0);
    setStartDate(Format.firstDate("YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setListName([]);
    getCustumers();
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };

  const handleSearchList = () => {
    let filterList = [];
    if (name !== "") filterList.push(`[name]=${name}`);
    if (trading !== "") filterList.push(`[trading_name]=${trading}`);
    if (status >= 0) filterList.push(`[status]=${status}`);
    if (cnpjCpf) filterList.push(`[cpf_cnpj]=${cnpjCpf.replace(/\D/g, "")}`);
    if (localization !== "") {
      filterList.push(`[address_district]=${localization}`);
    }
    if (typedate === 1) {
      filterList.push(`[created_from]=${startDate}`);
      filterList.push(`[created_until]=${endDate}`);
    }
    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        getCustumers(filter);
      }
      return;
    }
    getCustumers();
  };

  const columns: any = [
    {
      name: "Nome",
      selector: (row: any) => row.name.toLowerCase(),
      sortable: true,
    },
    {
      name: "Celular",
      grow: 0.5,
      center: true,
      selector: (row: any) =>
        Format.formatCelular(
          row.relationships?.contacts[0]?.cell_phone ?? ""
        ) ?? "",
    },
    {
      name: "Telefone",
      grow: 0.5,
      center: true,
      selector: (row: any) =>
        Format.formatTelefone(row.relationships?.contacts[0]?.phone ?? "") ??
        "",
    },
    {
      name: "Localidade",
      grow: 0.2,
      center: true,
      selector: (row: any) => row?.relationships?.addresses[0]?.uf,
    },
    {
      name: "CPF/CNPJ",
      grow: 0.5,
      center: true,
      selector: (row: any) => row.cpf_cnpj,
    },
    {
      name: "Data de cadastro",
      grow: 0.4,
      center: true,
      selector: (row: any) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Status",
      grow: 0.2,
      center: true,
      selector: (row: any) =>
        row.status === 1 ? (
          <ActiveInactiv
            status={row.status === 1 ? true : false}
            text="Ativo"
          />
        ) : (
          <ActiveInactiv
            status={row.status !== 1 ? true : false}
            text="Inativo"
          />
        ),
    },
    {
      name: "Ação",
      grow: 0.2,
      cell: (row: any) => (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
        >
          <span className="d-inline-block">
            <Button
              onClick={() => handleEdit(row)}
              variant="contained"
              color="primary"
            >
              <FaEdit size={16} color="8e8d8d" />
            </Button>
          </span>
        </OverlayTrigger>
      ),
      button: true,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const hanldeAdd = () => {
    props.OnAddNewCli(true);
  };

  const getCustumers = (filter?: string) => {
    const uri =
      filter !== undefined
        ? `${filter}&paginate=false&store_id=${storeMe?.storeActive?.id}`
        : `paginate=false&store_id=${storeMe?.storeActive?.id}`;

    setLoading(true);
    api
      .get(Globals.api.people + `/type/${props.Path}?${uri}`)
      .then((response) => {
        const data = response.data;
        setCustumers(data.data);
      })
      .catch((error) => {
        notifyError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleName = (names: any) => {
    if (!names || names.length <= 0) {
      setName("");
      return;
    }
    setName(names[0].name);
  };

  const searchNames = (name: any) => {
    if (name.length >= 3) {
      api
        .get(
          Globals.api.people +
            `/autocomplete?name=name&value=${name}&is_${props.Path}=1`
        )
        .then((response) => {
          const names = response.data.data;
          if (names.length > 0) {
            setListName(names);
          }
        })
        .catch((error) => {
          notifyError(error);
        })
        .finally(() => {});
    }
  };

  const handleCNPJCpf = (e: any) => {
    let valorLimpo = e.replace(/\D/g, "");

    setCnpjCpf(valorLimpo);

    if (valorLimpo.length === 11) {
      let valor = Format.formatCpf(valorLimpo);
      setCnpjCpf(valor);
    } else if (valorLimpo.length === 12 || valorLimpo.length === 10)
      setCnpjCpf(valorLimpo);

    if (valorLimpo.length === 14) {
      let valor = Format.formatCnpj(valorLimpo);
      setCnpjCpf(valor);
    } else if (valorLimpo.length === 13) setCnpjCpf(valorLimpo);
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    getCustumers();
  }, []);

  return (
    <>
      <LayoutFilter
        title={props?.Title}
        onClickAdd={hanldeAdd}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        openFilter={openFilter}
        onClickOpenFilter={onClickOpenFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Nome"
                isClearable
                state={name}
                isInvalid={false}
                placeholder="Nome"
                options={custumers?.map((item: any) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
                setState={setName}
              />

              <InputDefault
                label="Documento"
                type="text"
                placeholder="Documento"
                autoComplete="off"
                state={cnpjCpf}
                maxLength={18}
                setState={handleCNPJCpf}
              />

              <ReactSelect
                label="Status"
                type="number"
                placeholder="Status"
                isClearable
                state={status}
                isInvalid={false}
                options={oStatus}
                setState={setStatus}
              />

              <ReactSelect
                label="Estado"
                isClearable
                placeholder="Estado"
                state={localization}
                isInvalid={false}
                options={ObjState}
                setState={setLocalization}
              />

              <ReactSelect
                label="Período"
                isClearable
                state={typedate}
                isInvalid={false}
                options={oPeriodo}
                setState={setTypeDate}
              />

              <>
                <InputDefault
                  label="De"
                  type="date"
                  autoComplete="off"
                  state={startDate}
                  disabled={typedate === 0}
                  setState={handleDateStart}
                />

                <InputDefault
                  label="Até"
                  type="date"
                  autoComplete="off"
                  state={endDate}
                  disabled={typedate === 0}
                  setState={handleDateEnd}
                />
              </>
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Nome"
              isClearable
              state={status}
              isInvalid={false}
              options={custumers?.map((item: any) => ({
                label: item?.name,
                value: item?.id,
              }))}
              setState={setStatus}
            />

            <InputDefault
              label="Documento"
              type="text"
              autoComplete="off"
              state={cnpjCpf}
              maxLength={18}
              setState={handleCNPJCpf}
            />

            <ReactSelect
              label="Status"
              type="number"
              isClearable
              state={status}
              isInvalid={false}
              options={oStatus}
              setState={setStatus}
            />

            <ReactSelect
              label="Estado"
              isClearable
              state={localization}
              isInvalid={false}
              options={ObjState}
              setState={setLocalization}
            />

            <ReactSelect
              label="Período"
              isClearable
              state={typedate}
              isInvalid={false}
              options={oPeriodo}
              setState={setTypeDate}
            />

            <>
              <InputDefault
                label="De"
                type="date"
                autoComplete="off"
                state={startDate}
                disabled={typedate === 0}
                setState={handleDateStart}
              />

              <InputDefault
                label="Até"
                type="date"
                autoComplete="off"
                state={endDate}
                disabled={typedate === 0}
                setState={handleDateEnd}
              />
            </>
          </div>
        </S.Wrapper>
      </LayoutFilter>

      <LayoutTable>
        <DataTable
          columns={columns}
          data={custumers || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
