import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";

export const getStockTransferAsync = createAsyncThunk(
  "get/stock-transfer",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.transfers + `?paginate=false&store_id=${storeActivedId}`
      );
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getStockLocationAsync = createAsyncThunk(
  "get/stock-location",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.stocks + `?paginate=false&store_id=${storeActivedId}`
      );
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createStockTransferAsync = createAsyncThunk(
  "create/stock-transfer",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.transfers, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateStockTransferAsync = createAsyncThunk(
  "update/stock-transfer",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.transfers + `/${data.uuid}`, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteStockTransferAsync = createAsyncThunk(
  "delete/stock-transfer",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.transfers + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
